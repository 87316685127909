import React, { useState, useRef, useEffect } from "react";
import Play from '../../../Assets/images/ic_right_arrow.png';
import PlayFree from '../../../Assets/images/iconCandado.png';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from "@mui/material";

const SwipeableButton = ({click, loadingPurchase, isFree = false}) => {
  const [isSwiped, setIsSwiped] = useState(false);
  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const startXRef = useRef(null);
  const sliderLeftRef = useRef(0);
  const { t } = useTranslation();

  useEffect(() => {
    const handleDragMove = (e) => {
      if (startXRef.current) {
        const currentX = e.clientX || e.touches[0].clientX;
        const diffX = currentX - startXRef.current;
        sliderLeftRef.current = Math.min(
          Math.max(0, diffX),
          containerRef.current.offsetWidth - sliderRef.current.offsetWidth
        );
        sliderRef.current.style.left = `${sliderLeftRef.current}px`;
      }
    };

    const handleDragEnd = () => {
      startXRef.current = null;
      sliderRef.current.classList.remove('active');

      if (sliderLeftRef.current === containerRef.current.offsetWidth - sliderRef.current.offsetWidth) {
        setIsSwiped(true);
        click()
        setIsSwiped(false);
        sliderLeftRef.current = 3;
      } else {
        sliderLeftRef.current = 3;
        sliderRef.current.style.left = `${sliderLeftRef.current}px`;
      }
    };

    window.addEventListener('mousemove', handleDragMove);
    window.addEventListener('touchmove', handleDragMove);
    window.addEventListener('mouseup', handleDragEnd);
    window.addEventListener('mouseleave', handleDragEnd);
    window.addEventListener('touchend', handleDragEnd);

    return () => {
      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('touchmove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('mouseleave', handleDragEnd);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [click]);

  const handleDragStart = (e) => {
    startXRef.current = e.clientX || e.touches[0].clientX;
    sliderRef.current.classList.add('active');
  };

  const handleImgDragStart = (e) => {
    e.preventDefault();
  };

  return (
    <div className={`swipe-button-container${isFree ? "-free" : ""}`} ref={containerRef}
    style={{ position: 'absolute', zIndex: '999', top: '90%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      {loadingPurchase ? (
        <button className={`swiperLoadingButton${isFree ? "-free" : ""}`} disabled><CircularProgress /></button>
      ) : (
        <>

          <button className={`buyButtonSwipper${isFree ? "-free" : ""}`}>{t("feedCard.buyButtonLabel")}</button>
          <div
            className={`swipe-button-slider${isFree ? "-free" : ""}${isSwiped ? " swiped" : ""}`}
            ref={sliderRef}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            style={{ left: `${sliderLeftRef.current}px` }}
          >
            <img
              src={isFree? PlayFree: Play}
              className={`swipe-button-img${isFree ? "-free" : ""}`}
              alt="play"
              style={{ userSelect: 'none' }}
              onDragStart={handleImgDragStart}
            />
            {/* <KeyboardArrowRightIcon /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default SwipeableButton;