import * as types from './types'

export const initialState = {
  showNetworkErrorModal: false,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_SHOW_NETWORK_ERROR_MODAL:
      return {
        ...state,
        showNetworkErrorModal: action.payload,
      }

    default:
      return state
  }
}

export default reducer
