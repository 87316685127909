import React, { useState, useEffect } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Grid, Stack, Typography, useMediaQuery, Avatar } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";

import { Box, Button, Paper, Modal } from "@mui/material";
import CustomAvatar from "ui-component/Avatar";
import Loading from "ui-component/Loading/";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LoadingButton from "@mui/lab/LoadingButton";
import SwipeableViews from "react-swipeable-views";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Image from "Assets/images/backcover.jpeg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "api";
import Followings from "Pages/Profile/Followings";
import UserLikesTab from "./VideosFeed/view";
import TimeLineVideoView from "./VideosFeed/TimeLineVideoView";
import { followUser, unfollowUser } from "redux/users/operations";

import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
  },
};

const PublicProfile = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = useSelector((state) => state.auth.profile);
  const { id } = useParams();
  const [userProfile, setUserProfile] = useState(undefined);
  const [value, setValue] = React.useState(0);
  const [isLoading, setLoading] = useState(true);
  const [followLoading, setFollowLoading] = useState(false);
  const [isFollow, setIsFollow] = useState(userProfile?.currentlyFollowing);
  const [openFollowers, setOpenFollowers] = useState(false);
  const [openFollowings, setOpenFollowings] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    _loadUserProfile();
  }, [id]);

  const getSecondIdFromUrl = (url) => {
    const parts = url.split('/');
    const cleanedParts = parts.filter(part => part.trim() !== '');

    if (cleanedParts.length === 3) {
      return cleanedParts[2];
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (getSecondIdFromUrl(window.location.pathname)) {
      setTimeout(() => {
        const scrollToElementVideo = document.getElementById(`video-${getSecondIdFromUrl(window.location.pathname)}`);
        if (scrollToElementVideo) {
          scrollToElementVideo.scrollIntoView({ behavior: "smooth" });
        }

        const getElementComments = document.getElementById(`comments-${getSecondIdFromUrl(window.location.pathname)}`);
        if (getElementComments) {
          getElementComments.click()
        }
      }, 1000)
    }
  }, []);

  const _loadUserProfile = async () => {
    const isMyProfile = !id || (currentUser && currentUser.username === id);
    if (isMyProfile) {
      setUserProfile(currentUser);
      setLoading(false);
    } else {
      try {
        const userProfile = await fetchUserProfile(id);
        setIsFollow((prev) => userProfile.currentlyFollowing);
        setUserProfile((prev) => userProfile);

        setLoading(false);
      } catch (error) {
        alert(TextFormat.uppercaseFirstLetter(t("unableuser")) + ", " + t("pleaselater"));
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleFollow = async () => {
    if (isFollow) {
      setFollowLoading(true);

      const success = await dispatch(unfollowUser(userProfile.username));
      if (success) {
        setIsFollow(false);
      }

      setFollowLoading(false);
    } else {
      setFollowLoading(true);
      const success = await dispatch(followUser(userProfile.username));
      if (success) {
        setIsFollow(true);
      }
      _loadUserProfile();
      setFollowLoading(false);
    }
    //_loadUserProfile();
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Card
          sx={{
            backgroundColor: "#ffff",
          }}
        >
          <div
            style={{
              padding: matchDownSM ? "2rem" : "1rem",
              paddingLeft: matchDownSM ? "2rem" : "2rem",
              display: "flex",
              flexDirection: matchDownSM ? "column" : "row",
              alignItems: matchDownSM ? "center" : "unset",
              justifyContent: matchDownSM ? "center" : "unset",
            }}
          >
            <Grid container spacing={0.5}>
              <Grid
              // lg={1.5} sx={1}
              >
                <CustomAvatar
                  sx={{
                    width: matchDownSM ? 90 : 150,
                    height: matchDownSM ? 90 : 150,
                  }}
                  item={{
                    username: userProfile.nickname,
                    image: userProfile.image,
                    publisher: userProfile.publisher,
                    publisherType: userProfile.publisherType,
                  }}
                />
              </Grid>
              <Grid
                // lg={7.5}
                // sx={11}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingLeft: matchDownSM ? 2 : 5,
                }}
              >
                <Typography variant="caption" fontSize="16px">
                  {matchDownSM
                    ? userProfile.nickname.length > 10
                      ? userProfile.nickname.substring(0, 15) + "..."
                      : userProfile.nickname
                    : userProfile.nickname}
                </Typography>
                <Typography fontSize="16px">{userProfile.name}</Typography>
                <Typography variant="caption" fontSize="16px">
                  {userProfile.description}
                </Typography>
                <Grid>
                  <LoadingButton
                    loading={followLoading}
                    variant={isFollow ? "outlined" : "contained"}
                    sx={{
                      borderRadius: 10,
                      marginTop: "5px",
                      width: matchDownSM ? "100px" : "120px",
                    }}
                    onClick={() => {
                      handleFollow();
                    }}
                  >
                    {isFollow ?
                      TextFormat.uppercaseFirstLetter(t("unfollow")) :
                      TextFormat.uppercaseFirstLetter(t("follow"))}
                    {/* {userProfile.currentlyFollowing ? "Unfollow" : "Follow"} */}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
            {!matchDownSM ? (
              <Grid>
                <Typography
                  onClick={() => {
                    setOpenFollowers(!openFollowers);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userProfile.followersCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    {TextFormat.uppercaseFirstLetter(t("followers"))}
                  </Typography>
                </Typography>
                <Typography
                  onClick={() => {
                    setOpenFollowings(!openFollowings);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userProfile.followingCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    {TextFormat.uppercaseFirstLetter(t("following"))}
                  </Typography>
                </Typography>
              </Grid>
            ) : undefined}


          </div>
          <Modal
            open={openFollowers || openFollowings}
            onClose={() => {
              setOpenFollowers(false);
              setOpenFollowings(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box

              style={{

                overflowX: 'scroll',
                overflowY: 'scroll',
                position: "absolute",
                top: "50%",

                left: "50%",
                transform: "translate(-50%, -50%)",
                width: matchDownSM ? 380 : 500,
                background: "#fff",
                padding: 20,

                paddingBottom: 50,
              }}
            >
              {openFollowers ? (
                <Followings
                  isfollowerList={true}
                  username={userProfile.username}
                />
              ) : (
                <Followings username={userProfile.username} />
              )}
            </Box>
          </Modal>
          {matchDownSM ? (
            <Grid
              lg={3}
              sx={12}
              style={{ display: "flex" }}
              justifyContent="center"
            >
              <Grid sx={6}>
                <Typography
                  onClick={() => {
                    setOpenFollowers(!openFollowers);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="18px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: 20,
                  }}
                >
                  {userProfile.followersCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    {TextFormat.uppercaseFirstLetter(t("followers"))}
                  </Typography>
                </Typography>
              </Grid>
              <Grid sx={6}>
                <Typography
                  onClick={() => {
                    setOpenFollowings(!openFollowings);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="18px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingLeft: 20,
                  }}
                >
                  {userProfile.followingCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    {TextFormat.uppercaseFirstLetter(t("following"))}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          ) : undefined}

          <Box
            sx={{
              paddingY: matchDownSM ? "1rem" : "2rem",
              backgroundColor: "#ffff",
              //  border: 0,
            }}
          >
            <AppBar
              sx={{
                backgroundColor: "#ffff",
              }}
              position="static"
              elevation={0}
            >
              <Tabs
                sx={{
                  backgroundColor: "#ffff",
                  border: 1,
                  borderTopColor: "#D3D3D3",
                }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label={<span>{TextFormat.uppercaseFirstLetter(t("published"))}</span>}
                  icon={<YouTubeIcon />}
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<FavoriteIcon />}
                  label={<span>{TextFormat.uppercaseFirstLetter(t("favourites"))}</span>}
                  {...a11yProps(1)}
                />
                {/* <Tab
                  icon={<PersonPinIcon />}
                  label={<span>Followings</span>}
                  {...a11yProps(2)}
                />
                <Tab
                  icon={<PersonPinIcon />}
                  label={<span>Followers</span>}
                  {...a11yProps(3)}
                /> */}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {value == 0 && (
                <TimeLineVideoView
                  fromFollowing={true}
                  options={{
                    limit: 20,
                  }}
                  feedGroup="user"
                  username={userProfile.username}
                />
              )}
              {value == 1 && (
                <UserLikesTab
                  fromFollowing={true}
                  options={{
                    limit: 20,
                    kind: "like",
                    user_id: userProfile.username,
                  }}
                  feedGroup="timeline"
                  username={userProfile.username}
                />
              )}
              {/* {value == 2 && <Followings username={userProfile.username} />}
              {value == 3 && (
                <Followings
                  isfollowerList={true}
                  username={userProfile.username}
                />
              )} */}
            </SwipeableViews>
          </Box>
        </Card>
      )}
    </>
  );
};

export default PublicProfile;
