/**
 * Password validator for login pages
 */
// import value from 'Assets/scss/_theme-vars.module.scss';
// import { TextFormat } from "utils/textFormat";
// import { useTranslation } from 'react-i18next';

// has number
const hasNumber = (number) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
const hasMixed = (number) => new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars
const hasSpecial = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

// set color based on password strength
// due to translations duplicated in AuthRegister.js and ChangePasssword.js so the rule of Hooks is not broken
/**export const StrengthColor = (count) => {
    if (count < 2) return { label: TextFormat.uppercaseFirstLetter(t("poor")), color: value.errorMain };
    if (count < 3) return { label: TextFormat.uppercaseFirstLetter(t("weak")), color: value.warningDark };
    if (count < 4) return { label: TextFormat.uppercaseFirstLetter(t("normal")), color: value.orangeMain };
    if (count < 5) return { label: TextFormat.uppercaseFirstLetter(t("good")), color: value.successMain };
    if (count < 6) return { label: TextFormat.uppercaseFirstLetter(t("strong")), color: value.successDark };
    return { label: TextFormat.uppercaseFirstLetter(t("poor")), color: value.errorMain };
};*/

// password strength indicator
export const strengthIndicator = (number) => {
    let strengths = 0;
    if (number.length > 5) strengths += 1;
    if (number.length > 7) strengths += 1;
    if (hasNumber(number)) strengths += 1;
    if (hasSpecial(number)) strengths += 1;
    if (hasMixed(number)) strengths += 1;
    return strengths;
};
