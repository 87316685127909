import * as types from './types'

export const initialState = {
  list: [],
  totalItems: 0,
  page: 1,
  perPage: 10,
  item: null,
  isFetching: false,
  isFetchingInfo: '',
  categories: [],
  stats: [],
  unmutedVideo: null,
  isFetchingSuggestedList: false,
  isFetchingInfoSuggestedList: null,
  startLoadVideo: null,
  startPurchaseVideo: null,
  videoToShow: null,
  profileToShow: null,
  discoverVideos: null,
  startDiscover: false,
  suggestedCelebritiesList: [],
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_FETCHING:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        isFetchingInfo: action.payload.isFetchingInfo,
      }

    case types.SET_ITEM:
      return {
        ...state,
        item: action.payload,
      }

    case types.SET_LIST:
      return {
        ...state,
        list: action.payload.list,
        totalItems: action.payload.totalItems,
      }

    case types.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case types.SET_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      }

    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }
    case types.SET_STATS:
      return {
        ...state,
        stats: action.payload,
      }

    case types.SET_UNMUTED_VIDEO:
      return {
        ...state,
        unmutedVideo: action.payload,
      }

    case types.SET_SUGGESTED_CELEBRITIES:
      return {
        ...state,
        suggestedCelebritiesList: action.payload,
      }
    case types.SET_FETCHING_SUGGESTED:
      return {
        ...state,
        isFetchingSuggestedList: action.payload.isFetching,
        isFetchingInfoSuggestedList: action.payload.isFetchingInfo,
      }
    case types.START_LOAD_VIDEO:
      return {
        ...state,
        startLoadVideo: action.payload,
      }

    case types.START_PURCHASE_VIDEO:
      return {
        ...state,
        startPurchaseVideo: action.payload,
      }
    case types.SET_VIDEO_TO_SHOW:
      return {
        ...state,
        videoToShow: action.payload,
      }
    case types.SET_PROFILE_TO_SHOW:
      return {
        ...state,
        profileToShow: action.payload,
      }

    case types.SET_VIDEOS_TO_DISCOVER:
      return {
        ...state,
        videosToDiscover: action.payload,
      }
    case types.START_DISCOVER:
      return {
        ...state,
        startDiscover: action.payload,
      }

    case types.LOGOUT:
      return initialState

    default:
      return state
  }
}

export default reducer
