import { useState, useEffect, useRef } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Input,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project imports
import useScriptRef from "hooks/useScriptRef";
import { Auth } from "aws-amplify";
import AuthCardWrapper from "../authentication/AuthCardWrapper";
import AnimateButton from "ui-component/extended/AnimateButton";
import { StrengthColor, strengthIndicator } from "utils/password-strength";
import SnackBar from "ui-component/SnackBar";
// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CircularProgress } from "@mui/material";

import value from 'Assets/scss/_theme-vars.module.scss';
import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';

const ChangePassword = ({ ...others }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [strength, setStrength] = useState(0);
  const [isLoading, setLoading] = useState(0);

  const [level, setLevel] = useState();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const strengthColor = (count) => {
    if (count < 2) return { label: TextFormat.uppercaseFirstLetter(t("poor")), color: value.errorMain };
    if (count < 3) return { label: TextFormat.uppercaseFirstLetter(t("weak")), color: value.warningDark };
    if (count < 4) return { label: TextFormat.uppercaseFirstLetter(t("normal")), color: value.orangeMain };
    if (count < 5) return { label: TextFormat.uppercaseFirstLetter(t("good")), color: value.successMain };
    if (count < 6) return { label: TextFormat.uppercaseFirstLetter(t("strong")), color: value.successDark };
    return { label: TextFormat.uppercaseFirstLetter(t("poor")), color: value.errorMain }; 
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };

  const snackBarRef = useRef();
  return (
    <div style={{  zIndex: -1,padding:20 }}>
      <SnackBar ref={snackBarRef} />
      <>
        <Grid item sx={{ mt: 5 }} xs={12}>
          <Grid
            container
            direction={matchDownSM ? "column-reverse" : "row"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <Typography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  variant={matchDownSM ? "h3" : "h2"}
                >
                  {TextFormat.uppercaseAllFirstsLetters(t("changepassword"))}
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="20px"
                  textAlign={matchDownSM ? "center" : "inherit"}
                >
                  {TextFormat.uppercaseFirstLetter(t("securenewpassword"))}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Formik
          initialValues={{
            currentPassword: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object().shape({
            currentPassword: Yup.string().required(
              TextFormat.uppercaseFirstLetter(t("currentpasswordrequired"))
            ),
            password: Yup.string().max(255).required(TextFormat.uppercaseFirstLetter(t("passwordrequired"))),
            confirmPassword: Yup.string()
              .required(TextFormat.uppercaseFirstLetter(t("confirmpasswordrequired")))
              .oneOf(
                [Yup.ref("password"), null],
                TextFormat.uppercaseFirstLetter(t("dontmatch"))
              ),
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            setLoading(true);
            Auth.currentAuthenticatedUser()
              .then((user) => {
                return Auth.changePassword(
                  user,
                  values.currentPassword,
                  values.confirmPassword
                );
              })
              .then((resp) => {
                if (resp === "SUCCESS") {
                  snackBarRef.current.getAlert(
                    TextFormat.uppercaseFirstLetter(t("passwordsuccessupdate")),
                    TextFormat.uppercaseFirstLetter(t("success"))
                  );
                  resetForm();
                }
              })
              .catch(() => {
                snackBarRef.current.getAlert(
                  TextFormat.uppercaseFirstLetter(t("passwordnotsuccessupdate")),
                  TextFormat.uppercaseFirstLetter(t("error"))
                );
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <FormControl
                fullWidth
                error={Boolean(
                  touched.currentPassword && errors.currentPassword
                )}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-password-register">
                  {TextFormat.uppercaseAllFirstsLetters(t("currentpassword"))}
                </InputLabel>

                <Input
                  id="outlined-adornment-password-register"
                  type={showCurrentPassword ? "text" : "password"}
                  value={values.currentPassword}
                  name="currentPassword"
                  label={TextFormat.uppercaseAllFirstsLetters(t("currentpassword"))}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.currentPassword && errors.currentPassword && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-register"
                  >
                    {errors.currentPassword}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-password-register">
                  {TextFormat.uppercaseAllFirstsLetters(t("password"))}
                </InputLabel>

                <Input
                  id="outlined-adornment-password-register"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  label={TextFormat.uppercaseAllFirstsLetters(t("password"))}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    changePassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-register"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth>
                <Box sx={{ mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Box
                        style={{ backgroundColor: level?.color }}
                        sx={{ width: 85, height: 8, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" fontSize="0.75rem">
                        {level?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-password-register">
                  {TextFormat.uppercaseFirstLetter(t("confirmpassword"))}
                </InputLabel>

                <Input
                  id="outlined-adornment-password-register"
                  type={showConfirmPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  label={TextFormat.uppercaseFirstLetter(t("confirmpassword"))}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-register"
                  >
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box
                sx={{
                  mt: 8,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <AnimateButton sx={{ mt: 2 }}>
                  <Button
                    // onClick={() =>}
                    disableElevation
                    //   disabled={isSubmitting}
                    //fullWidth
                    sx={{ width: "150px", borderRadius:'75px' }}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {isLoading ? (
                      <CircularProgress
                        sx={{ mr: 2 }}
                        color="warning"
                        size={20}
                      />
                    ) : (
                      <SaveIcon sx={{ mr: 2 }} />
                    )}
                    {TextFormat.uppercaseFirstLetter(t("save"))}
                  </Button>
                </AnimateButton>
              </Box>
            </form>
          )}
        </Formik>
      </>
    </div>
  );
};

export default ChangePassword;
