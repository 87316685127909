import React, { useState, useEffect, useRef } from "react";
import FlatList, { PlainList } from "flatlist-react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import SearchSection from "layout/MainLayout/Header/SearchSection";
import { RenderVideo } from "Pages/Home/HomeComponents/VideoView";
import Loading from "ui-component/Loading";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { useDebounce } from "utils/commonHooks";
import { fetchVideos } from "api";

import _ from "lodash";
const DEBOUNCE_DELAY = 750;

function MyVideos(props) {
  const [value, setValue] = useState("");
  const debounceFilterText = useDebounce(value, DEBOUNCE_DELAY);
  const theme = useTheme();
  //    const [category, setCategory] = React.useState(props.category)
  const videosTotalPage = useRef(1);
  const videosPage = useRef(1);

  const [videosTotal, setVideosTotal] = React.useState(1);
  const [videosList, setVideosList] = React.useState([]);
  const [videosLoading, setVideosLoading] = React.useState(false);
  const dispatch = useDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const onScroll = function () {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      onEndReached(videosList);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const onEndReached = (videosList) => {
    const loadNextVideoPage =
      videosPage.current < videosTotalPage.current &&
      !videosLoading &&
      videosList.length > 0;

    if (loadNextVideoPage) {
      const newPage = videosPage.current + 1;

      videosPage.current = newPage;

      fetchVideosList(videosList, newPage, "");
    }
  };
  const fetchVideosList = React.useCallback(
    async (currentList, currentPage, searchTextInput) => {
      try {
        setVideosLoading(true);
        const params = { page: currentPage };
        const fetchSearchVideosRes = await fetchVideos(params);

        const total = _.get(fetchSearchVideosRes, "pageCount", 1);
        const data = [
          ...currentList,
          ..._.get(fetchSearchVideosRes, "data", []),
        ];
        setVideosList(data);
        videosTotalPage.current = total;
        setVideosTotal(total);
      } catch (e) {
      } finally {
        setVideosLoading(false);
      }
    },
    [props]
  );

  React.useEffect(() => {
    fetchVideosList([], 1, debounceFilterText);
  }, [fetchVideosList, debounceFilterText]);

  return (
    <div
      style={{
        display: "flex",
        width: matchDownSM ? "100%" : "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <Grid container spacing={0} columns={{ xs: 2, sm: 8, md: 12 }}>
          <PlainList
            renderOnScroll
            list={videosList}
            renderItem={(item) => {
              if (item.url)
                return (
                  <RenderVideo disableCard={true} item={item} search={true} />
                );
            }}
            renderWhenEmpty={() => null}
          />
        </Grid>
        {videosLoading ? (
          <Loading isLoading={true} />
        ) : !videosLoading && videosList.length == 0 ? (
          <Loading isEmpty={true} />
        ) : null}
      </div>
    </div>
  );
}
export default MyVideos;
