import * as types from './types'

export const setFetching = (isFetching = false, isFetchingInfo = '') => ({
  type: types.SET_FETCHING,
  payload: { isFetching, isFetchingInfo },
})

export const setItem = payload => ({
  type: types.SET_ITEM,
  payload,
})

export const setList = (list, totalItems) => ({
  type: types.SET_LIST,
  payload: { list, totalItems },
})

export const setPage = payload => ({
  type: types.SET_PAGE,
  payload,
})

export const setPerPage = payload => ({
  type: types.SET_PER_PAGE,
  payload,
})

export const setCategories = payload => ({
  type: types.SET_CATEGORIES,
  payload,
})

export const setStats = payload => ({
  type: types.SET_STATS,
  payload,
})

export const setUnmutedVideo = payload => ({
  type: types.SET_UNMUTED_VIDEO,
  payload,
})

export const setSuggestedCelebritiesList = payload => ({
  type: types.SET_SUGGESTED_CELEBRITIES,
  payload,
})

export const setFetchingSuggested = (isFetching = false, isFetchingInfo = '') => ({
  type: types.SET_FETCHING_SUGGESTED,
  payload: { isFetching, isFetchingInfo },
})

export const setVideoLoadStartTime = value => ({
  type: types.START_LOAD_VIDEO,
  payload: value,
})

export const setStartPurchaseVideo = value => ({
  type: types.START_PURCHASE_VIDEO,
  payload: value,
})

export const setVideoToShow = sharedVideo => ({
  type: types.SET_VIDEO_TO_SHOW,
  payload: sharedVideo,
})

export const setProfileToShow = sharedProfile => ({
  type: types.SET_PROFILE_TO_SHOW,
  payload: sharedProfile,
})

export const setVideosToDiscover = videos => ({
  payload: videos,
  type: types.SET_VIDEOS_TO_DISCOVER,
})

export const startDiscover = value => ({
  payload: value,
  type: types.START_DISCOVER,
})
