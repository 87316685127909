export const TextFormat = {
  uppercaseFirstLetter: (data) => {
    const dataLowerCase = data.toLowerCase();
    const sentence = dataLowerCase.split('');
    return sentence[0] === "¿" ?
      sentence[0] + sentence[1].toUpperCase() + dataLowerCase.substr(2, sentence.length - 1) :
      sentence[0].toUpperCase() + dataLowerCase.substr(1, dataLowerCase.length - 1);
  },

  uppercaseAllFirstsLetters: (data) => {
    const sentence = data.toLowerCase().split(' ');
    for (let index = 0; index < sentence.length; index++) {
      sentence[index] = sentence[index][0].toUpperCase() + sentence[index].substr(1);
      if (index < sentence.length - 1) sentence[index] += ' ';
    }
    return sentence.join(" ");
  },
}