import * as types from './types'

export const setFeedRef = (key, ref) => ({
  type: types.SET_FEED_REF,
  payload: { key, ref },
})

export const setVisibleCards = visibleCards => ({
  type: types.SET_VISIBLE_CARDS,
  payload: { visibleCards },
})

export const setFetching = (isFetching = false, isFetchingInfo = '') => ({
  type: types.SET_FETCHING,
  payload: { isFetching, isFetchingInfo },
})

export const setScrollEnabled = scrollEnabled => ({
  type: types.SET_SCROLL_ENABLED,
  payload: { scrollEnabled },
})

export const setTabNeedRefresh = tabIndex => ({
  type: types.SET_NEED_REFRESH,
  payload: tabIndex,
})
