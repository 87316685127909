import { fetchUserProfile } from "api";
import React, { createContext, useState } from "react";

export const MyContext = createContext();
const CoinsContext = (props) => {
  const [coins, setCoins] = useState(0);
  const updateCoins = (username) => {
    console.log('fired');
    fetchUserProfile(username).then((profile) => {
      if (profile.availableVideos) {
        setCoins(profile.availableVideos);
      }
    });
  };
  return (
    <MyContext.Provider value={{ coins, updateCoins }}>
      {props.children}
    </MyContext.Provider>
  );
};
export default CoinsContext;