import { useTheme } from "@emotion/react";
import { Close } from "@material-ui/icons";
import {
  FileUploadOutlined,
  VideoCameraBackOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoRecorder from "react-video-recorder";
import { postVideo } from "redux/videos/operations";
import { useFilePicker } from "use-file-picker";
import { useTranslation } from 'react-i18next';
import { TextFormat } from "utils/textFormat";
import "./index.scss";

const VideoUploader = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const categories = useSelector((state) => state.videos.categories);
  const fetchingInfo = useSelector((state) => state.videos.isFetchingInfo);
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [showModal, setShowModal] = useState(false);
  const [videoCategory, setVideoCategory] = useState("");
  const [errorSnack, setErrorSnack] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoPublishType, setVideoPublishType] = useState("");
  const [publicationDate, setPublicationDate] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [recordedVideo, setRecordedVideo] = useState("");
  const [openFileSelector, { plainFiles, loading, clear }] = useFilePicker({
    accept: "video/*",
    multiple: false,
  });

  useEffect(() => {
    if (plainFiles.length > 0 || recordedVideo) {
      setShowModal(true);
      setShowVideoModal(false);
    }
  }, [plainFiles.length, recordedVideo]);

  const handleVideoSubmit = () => {
    if (videoCategory) {
      const fileToUpload = recordedVideo ? recordedVideo : plainFiles[0];
      dispatch(
        postVideo({
          video: {
            deviceOrientation: 1,
            isRecordingInterrupted: false,
            uri: URL.createObjectURL(fileToUpload),
            videoOrientation: 1,
          },
          title: videoTitle,
          status:
            videoPublishType === "publicationDate"
              ? "programmed"
              : videoPublishType,
          publicationDate: publicationDate ? publicationDate : undefined,
          category: videoCategory,
          videoFile: fileToUpload,
        })
      );
      setRequestSent(true);
    } else {
      setErrorSnack(TextFormat.uppercaseFirstLetter(t("selectvideo")));
    }
  };

  const resetModal = () => {
    setShowModal(false);
    clear();
    setShowModal("");
    setErrorSnack("");
    setVideoTitle("");
    setVideoPublishType("");
    setVideoCategory("");
    setPublicationDate("");
    setRequestSent("");
    setRecordedVideo("");
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => resetModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{ width: matchDownSM ? 500 : 800 }}
          className="box-container"
        >
          {fetchingInfo ? (
            <>
              <LinearProgress />
              <Typography className="progress-text">{fetchingInfo}</Typography>
            </>
          ) : requestSent ? (
            <>
              <Typography className="modal-header">{TextFormat.uppercaseFirstLetter(t("success"))}</Typography>
              <Divider />
              <Typography className="modal-desc">
                {TextFormat.uppercaseFirstLetter(t("videoprocessed"))}
              </Typography>
              <Typography className="close-button" onClick={() => resetModal()}>
                {TextFormat.uppercaseFirstLetter(t("close"))}
              </Typography>
            </>
          ) : (
            <>
              <Typography className="modal-header">
                {TextFormat.uppercaseFirstLetter(t("upload")) + " "}
                {recordedVideo
                  ? recordedVideo.name
                  : plainFiles.length > 0
                  ? plainFiles[0].name
                  : ""}
              </Typography>
              <Divider />
              {plainFiles.length > 0 ? (
                <video
                  width="100%"
                  height={matchDownSM ? "400px" : "450px"}
                  controls
                  style={{ marginTop: 20, marginBottom: 20 }}
                  id="plainfile-player"
                >
                  <source
                    src={URL.createObjectURL(plainFiles[0])}
                    type={plainFiles[0].type}
                  />
                </video>
              ) : (
                recordedVideo && (
                  <video
                    width="100%"
                    height={matchDownSM ? "400px" : "450px"}
                    controls
                    style={{ marginTop: 20, marginBottom: 20 }}
                    id="recordedvideo-player"
                  >
                    <source
                      src={URL.createObjectURL(recordedVideo)}
                      type={recordedVideo.type}
                    />
                  </video>
                )
              )}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{TextFormat.uppercaseFirstLetter(t("posttype"))}</InputLabel>
                <Select
                  value={videoPublishType}
                  label={TextFormat.uppercaseFirstLetter(t("posttype"))}
                  onChange={(value) => setVideoPublishType(value.target.value)}
                >
                  <MenuItem value="programmed">{TextFormat.uppercaseFirstLetter(t("postnow"))}</MenuItem>
                  <MenuItem value="private">{TextFormat.uppercaseFirstLetter(t("private"))}</MenuItem>
                  <MenuItem value="publicationDate">
                    {TextFormat.uppercaseFirstLetter(t("programpublishing"))}
                  </MenuItem>
                </Select>
              </FormControl>
              {videoPublishType === "publicationDate" ? (
                <FormControl fullWidth style={{ marginTop: 20 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label={TextFormat.uppercaseFirstLetter(t("videopublishdate"))}
                      value={publicationDate}
                      onChange={setPublicationDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              ) : undefined}
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="demo-simple-select-label">{TextFormat.uppercaseFirstLetter(t("category"))}</InputLabel>
                <Select
                  value={videoCategory}
                  label={TextFormat.uppercaseFirstLetter(t("category"))}
                  onChange={(value) => setVideoCategory(value.target.value)}
                >
                  {categories.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item}>
                        {item.nameEn}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <InputLabel>{TextFormat.uppercaseFirstLetter(t("videotitle"))}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-login"
                  type="text"
                  value={videoTitle}
                  name="title"
                  onChange={(value) => setVideoTitle(value.target.value)}
                  label={TextFormat.uppercaseFirstLetter(t("videotitle"))}
                />
              </FormControl>
              <Button
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => handleVideoSubmit()}
              >
                {TextFormat.uppercaseFirstLetter(t("publish"))}
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Modal open={showVideoModal} onClose={() => setShowVideoModal(false)}>
        <Box
          style={{ width: "100%", height: "100%" }}
          className="video-box-container"
        >
          <div className="top-header-contianer">
            <div />
            <Typography className="modal-header-video">{TextFormat.uppercaseFirstLetter(t("recordvideo"))}</Typography>
            <Close
              style={{ marginRight: 10, cursor: "pointer" }}
              onClick={() => setShowVideoModal(false)}
            />
          </div>
          <Divider />
          <VideoRecorder
            isOnInitially={true}
            onRecordingComplete={(videoBlob) => {
              setRecordedVideo(videoBlob);
            }}
          />
        </Box>
      </Modal>
      <Snackbar
        open={!!errorSnack}
        autoHideDuration={6000}
        onClose={() => setErrorSnack(false)}
      >
        <Alert
          onClose={() => setErrorSnack(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorSnack}
        </Alert>
      </Snackbar>
      {currentUser?.publisher && (
        <>
          <Fab
            onClick={() => setShowVideoModal(true)}
            color="primary"
            aria-label="add"
            style={{ position: "fixed", bottom: 50, right: 50 }}
          >
            {loading ? <CircularProgress /> : <VideoCameraBackOutlined />}
          </Fab>
          <Fab
            onClick={() => openFileSelector()}
            color="primary"
            aria-label="add"
            size="small"
            style={{ position: "fixed", bottom: 50, right: 120 }}
          >
            {loading ? <CircularProgress /> : <FileUploadOutlined />}
          </Fab>
        </>
      )}
    </>
  );
};

export default VideoUploader;
