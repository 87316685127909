import React from "react";
import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';

export const FeedEmptyComponent = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 70,
      }}
    >
      {/* <img src={logo} style={{ width: "200px", height: "100px" }} /> */}
      <p style={{ marginTop: 25, fontWeight: "700", fontSize: 20 }}>
        {TextFormat.uppercaseFirstLetter(t("norecordfound"))}
      </p>
    </div>
  );
};
