import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  Avatar,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserAvatar from "Assets/images/avatar.png";
import { ExpandLess, ExpandMore, Gavel, Policy } from "@material-ui/icons";
import { LocalPolice } from "@mui/icons-material";
import Transitions from "ui-component/extended/Transitions";
import MainCard from "ui-component/cards/MainCard";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PolicyIcon from "@mui/icons-material/Policy";
import { IconLogout, IconSettings, IconUser } from "@tabler/icons";
import { authOperations } from "redux/auth";

import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';
import User1 from "Assets/svgs/users/user-round.svg";
// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubMenu] = useState(false);
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    dispatch(authOperations.logout());
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={user.image ? user.image : UserAvatar}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 1.5 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">{user.name}</Typography>
                      </Stack>
                      <Typography variant="subtitle2">{user.email}</Typography>
                    </Stack>
                    <Divider />
                  </Box>
                  <Box>
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                      }}
                    >
                      {user.publisher && (
                        <ListItemButton
                          sx={{
                            borderRadius: `10px`,
                          }}
                          selected={selectedIndex === 1}
                          onClick={(event) => handleListItemClick(event, 1, "/billing")}
                        >
                          <ListItemIcon>
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid container spacing={1} justifyContent="space-between">
                                <Grid item>
                                  <Typography variant="body2">{t('billing')}</Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      )}
                      <ListItemButton
                        sx={{
                          borderRadius: `10px`,
                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) =>
                          handleListItemClick(event, 1, "/profile")
                        }
                      >
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Grid
                              container
                              spacing={1}
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Typography variant="body2">{TextFormat.uppercaseFirstLetter(t("profile"))}</Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                      </ListItemButton>
                      <>
                        <ListItemButton
                          sx={{
                            borderRadius: `10px`,
                          }}
                          selected={selectedIndex === 1}
                          onClick={(event) =>
                            handleListItemClick(event, 1, "/userInfo")
                          }
                        >
                          <ListItemIcon>
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    {TextFormat.uppercaseFirstLetter(t("editprofile"))}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `10px`,
                          }}
                          selected={selectedIndex === 1}
                          onClick={
                            (event) =>
                              handleListItemClick(event, 3, "/changePassword")
                            // handleListItemClick(event, 1, "/profile")
                          }
                        >
                          <ListItemIcon>
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    {TextFormat.uppercaseFirstLetter(t("changepassword"))}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      </>
                      <a
                        href="mailto:hello@bethey.com"
                        style={{ textDecorationLine: "none" }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `10px`,
                          }}
                          selected={selectedIndex === 3}
                        >
                          <ListItemIcon>
                            <MarkEmailReadIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    {TextFormat.uppercaseFirstLetter(t("contactsupport"))}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                      </a>

                      <ListItemButton
                        sx={{
                          borderRadius: `10px`,
                        }}
                        selected={selectedIndex === 4}
                        onClick={(event) =>
                          // handleListItemClick(event, 4, "/showlegals")
                          setOpenSubMenu(!openSubmenu)
                        }
                      >
                        <ListItemIcon>
                          <PolicyIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Grid
                              container
                              spacing={1}
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Typography variant="body2">
                                  {TextFormat.uppercaseFirstLetter(t("showlegals"))}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                        {openSubmenu ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() =>
                              window.open(
                                "https://bethey.com/legal.html",
                                "_blank"
                              )
                            }
                          >
                            <ListItemIcon>
                              <Gavel />
                            </ListItemIcon>
                            <ListItemText primary={TextFormat.uppercaseAllFirstsLetters(t("legalnotice"))} />
                          </ListItemButton>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() =>
                              window.open(
                                "https://bethey.com/privacy-consumers.html",
                                "_blank"
                              )
                            }
                          >
                            <ListItemIcon>
                              <LocalPolice />
                            </ListItemIcon>
                            <ListItemText primary={TextFormat.uppercaseAllFirstsLetters(t("privacypolicy"))} />
                          </ListItemButton>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() =>
                              window.open(
                                "https://bethey.com/terms-and-conditions-consumers.html",
                                "_blank"
                              )
                            }
                          >
                            <ListItemIcon>
                              <Policy />
                            </ListItemIcon>
                            <ListItemText primary={TextFormat.uppercaseAllFirstsLetters(t("termsconditions"))} />
                          </ListItemButton>
                        </List>
                      </Collapse>
                      <ListItemButton
                        sx={{
                          borderRadius: `10px`,
                        }}
                        selected={selectedIndex === 5}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">{TextFormat.uppercaseFirstLetter(t("logout"))}</Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
