import { useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { drawerWidth } from "./../constant";
import { fetchUserProfile } from "api";
import { useEffect, useState } from "react";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,

    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        // marginLeft: 20,
        width: `calc(90% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        // marginLeft: "20px",
        width: `calc(90% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        // marginLeft: "10px",
        width: `calc(90% - ${drawerWidth}px)`,
        // padding: "16px",
        // marginRight: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(90% - ${drawerWidth}px)`,
      // [theme.breakpoints.down("md")]: {
      //   marginLeft: "20px",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   marginLeft: "10px",
      // },
    }),
  })
);

const MainLayout = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const [coins, setCoins] = useState(localStorage.getItem("coins") || 0);

  fetchUserProfile(user.username)
  .then(profile => {
    if(profile.availableVideos) {
      localStorage.setItem("coins", profile.availableVideos);
    }
  })

  useEffect(() => {
    const coins = localStorage.getItem("coins");
    if (coins) {
      setCoins(coins);
    }
  }, [localStorage.getItem("coins")]);


  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: "none",
        }}
      >
        <Toolbar>
          <Header search={props.search} coins={coins} />
        </Toolbar>
      </AppBar>

      <Main
        theme={theme}
        open={false}
        style={{
          display: "flex",
          marginTop: location.pathname === "/search" ? "45px" : (location.pathname ==="/products" ? "40px" : "88px"),
          justifyContent: "center",
        }}
      >
        {props.children}
      </Main>
    </Box>
  );
};

export default MainLayout;
