import auth from "./auth";
import videos from "./videos";
import users from "./users";
import feeds from "./feeds";
import navigation from "./navigation";
import notifications from "./notifications";
import purchases from "./purchases";
import beneficiaries from "./beneficiaries";
import error from "./error";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth,
  videos,
  users,
  feeds,
  navigation,
  notifications,
  purchases,
  beneficiaries,
  error,
});

export default rootReducer;
