import React, { useState, useRef } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SnackBar from "ui-component/SnackBar";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, TextField, FormControl } from "@mui/material";

import { authOperations } from "redux/auth";
import AnimateButton from "ui-component/extended/AnimateButton";
import { IconLoader } from "@tabler/icons";

import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import countriesData from 'countries-list';

const UserInfo = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const user = useSelector((state) => state.auth.profile);
  const dispatch = useDispatch();
  const [description, setDescription] = useState(user.description);
  const [username, setUsername] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [birthdate, setBirthdate] = useState(user.birthdate);
  const [country, setCountry] = useState(user.location);
  const [loading, setLoading] = useState(false);
  const snackBarRef = useRef();

  // Local datos
  console.log('state.auth.profile : ',user);

  const onSubmit = async () => {

    let birthdateDate = new Date(birthdate);

    let year = birthdateDate.getUTCFullYear();
    let month = String(birthdateDate.getUTCMonth() + 1).padStart(2, "0");
    let day = String(birthdateDate.getUTCDate()).padStart(2, "0");

    let birthdateDateString = `${year}-${month}-${day}`;

    let data = { nickname: username, name: name, description: description, location:country, birthdate:birthdateDateString};
    
    dispatch(authOperations.updateUserProfile(data));
    setLoading(false);

    snackBarRef.current.getAlert(
      TextFormat.uppercaseFirstLetter(t("profileupdated")),
      TextFormat.uppercaseFirstLetter(t("success"))
    );
  };

  const countryOptions = Object.keys(countriesData.countries).map((countryCode) => {
    const country = countriesData.countries[countryCode];
    return (
      <MenuItem id={countryCode} key={countryCode} value={countryCode}>
        {country.emoji} {country.name}
      </MenuItem>
    );
  });

  return (
    <div style={{ padding: 20 }}>
      <>
        <SnackBar ref={snackBarRef} />
        <Grid
          sx={{ mt: 5 }}
          xs={12}
          container
          direction={matchDownSM ? "column-reverse" : "row"}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <Typography
                color={theme.palette.secondary.main}
                gutterBottom
                variant={matchDownSM ? "h3" : "h2"}
              >
                {TextFormat.uppercaseFirstLetter(t("userprofile"))}
              </Typography>
              <Typography
                variant="caption"
                fontSize="20px"
                textAlign={matchDownSM ? "center" : "inherit"}
              >
                {TextFormat.uppercaseFirstLetter(t("editprofiledata"))}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={matchDownSM ? 0 : 2}>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={!isEditable}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                fullWidth
                label={TextFormat.uppercaseFirstLetter(t("username"))}
                margin="normal"
                name="Username"
                variant="standard"
                type="text"
                defaultValue=""
                sx={{ ...theme.typography.customInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={!isEditable}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                variant="standard"
                label={TextFormat.uppercaseFirstLetter(t("name"))}
                margin="normal"
                name="Name"
                type="text"
                defaultValue=""
                value={name}
                sx={{ ...theme.typography.customInput }}
              />
            </Grid>

            {/* country */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth
                           sx={{ ...theme.typography.customSelect }}>
                <InputLabel id="outlined-adornment-country-profile">
                  {TextFormat.uppercaseFirstLetter(t("country"))}
                </InputLabel>
                <Select
                  labelId="outlined-adornment-country-profile"
                  id="outlined-adornment-country-profile"
                  value={country}
                  name="country"
                  type="country"
                  onChange={ (e) => setCountry(e.target.value)}
                >
                  {countryOptions}
                </Select>
              </FormControl>
            </Grid>

            {/* birthdate */}
            <Grid item xs={12} sm={6} container alignItems="center" justifyContent="center">
              <FormControl 
                fullWidth 
                sx={{ ...theme.typography.customDate }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={TextFormat.uppercaseFirstLetter(t("birthdate"))}
                    name="Birthdate"
                    type="birthdate"
                    inputFormat="DD/MM/YYYY"
                    disableFuture
                    value={birthdate}
                    onChange={(e) => setBirthdate(new Date(e))}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                // disabled={!isEditable}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                value={description}
                variant="standard"
                label={TextFormat.uppercaseFirstLetter(t("description"))}
                margin="normal"
                name="Description"
                type="text"
                defaultValue=""
                sx={{ ...theme.typography.customInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <a style={{color: theme.palette.secondary.main}} href={process.env.REACT_APP_STRIPE_URL} target="_blank" rel="noopener noreferrer">{TextFormat.uppercaseFirstLetter(t("paymentMethodsConfigure"))}</a>
            </Grid>

          </Grid>

          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <AnimateButton sx={{ mt: 8 }}>
              <Button
                onClick={() => onSubmit()}
                disableElevation
                disabled={loading}
                //fullWidth
                sx={{ width: "150px", borderRadius: "75px" }}
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                {loading ? (
                  <IconLoader />
                ) : (
                  <>
                    <SaveIcon sx={{ mr: 2 }} />
                    {TextFormat.uppercaseFirstLetter(t("save"))}
                  </>
                )}
              </Button>
            </AnimateButton>
          </Box>
        </Grid>
      </>
    </div>
  );
};

export default UserInfo;
