import * as types from './types'

export const setFetching = (isFetching = false, isFetchingInfo = '') => ({
  type: types.SET_FETCHING,
  payload: { isFetching, isFetchingInfo },
})

export const setItem = payload => ({
  type: types.SET_ITEM,
  payload,
})

export const setList = (list, totalItems) => ({
  type: types.SET_LIST,
  payload: { list, totalItems },
})

export const setCelebritiesList = (list, totalItems) => ({
  type: types.SET_CELEBRITIES_LIST,
  payload: { list, totalItems },
})

export const setPage = payload => ({
  type: types.SET_PAGE,
  payload,
})

export const setPerPage = payload => ({
  type: types.SET_PER_PAGE,
  payload,
})

export const updateItemFollow = (item, value) => ({
  type: types.UPDATE_ITEM_FOLLOW,
  payload: { item, value },
})

export const setItemSaving = payload => ({
  type: types.SET_ITEM_SAVING,
  payload,
})

export const setBlockUser = payload => ({
  type: types.SET_BLOCK_USER,
  payload,
})

export const setBlockUserList = payload => ({
  type: types.SET_BLOCK_USER_LIST,
  payload,
})
