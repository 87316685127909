import _ from "lodash";
// import messaging from "@react-native-firebase/messaging";
// import { Alert } from "react-native";
// import i18n from 'i18n-js'
// import { translate as t } from "../../assets/i18n";
// import { Actions } from "react-native-router-flux";
// import { getCreatorTermsUrl } from "../../config/api";
import { checkIsPublisher } from "../../utils";
import { authOperations } from "../auth";
import { navigateByNotification } from "./actions";

export const initNotifications = () => async (dispatch, getState) => {
  // await messaging().requestPermission();
  // messaging().onNotificationOpenedApp((remoteMessage) =>
  //   dispatch(onNotificationOpenedApp(remoteMessage))
  // );
  // messaging().onMessage((remoteMessage) => dispatch(onMessage(remoteMessage)));
};

export const onInitialNotification =
  (remoteMessage) =>
  async (dispatch, getState, { actions }) => {
    const username = _.get(remoteMessage, "data.username");
    const activityId = _.get(remoteMessage, "data.activityId");
    if (username && activityId) {
      dispatch(navigateByNotification({ username, activityId }));
    }
  };

export const onNotificationOpenedApp =
  (remoteMessage) =>
  async (dispatch, getState, { actions }) => {
    const { user, getstreamToken } = getState().auth;
    const username = _.get(remoteMessage, "data.username");
    const activityId = _.get(remoteMessage, "data.activityId");
    if ((user, getstreamToken && username && activityId)) {
      dispatch(navigateByNotification({ username, activityId }));
    } else if (user && _.has(remoteMessage, "data.event")) {
      dispatch(handleEventRemoteMessage(remoteMessage));
    }
  };

export const onMessage =
  (remoteMessage) =>
  async (dispatch, getState, { actions }) => {
    const { user, getstreamToken } = getState().auth;
    const username = _.get(remoteMessage, "data.username");
    const activityId = _.get(remoteMessage, "data.activityId");
    const authorName = _.get(remoteMessage, "data.authorName", "");
    if ((user, getstreamToken && username && authorName && activityId)) {
      // Alert.alert(
      //   translate("notifications.newVideoTitle"),
      //   translate("notifications.newVideoBody", { authorName }),
      //   [
      //     {
      //       text: translate("cancel"),
      //       style: "cancel",
      //     },
      //     {
      //       text: translate("accept"),
      //       onPress: () =>
      //         actions.push("FeedActivity", { activityId, username }),
      //     },
      //   ]
      // );
    } else if (user && _.has(remoteMessage, "data.event")) {
      dispatch(handleEventRemoteMessage(remoteMessage));
    } else if (_.has(remoteMessage, "notification")) {
      // Alert.alert(
      //   _.get(remoteMessage, "notification.title"),
      //   _.get(remoteMessage, "notification.body")
      // );
    }
  };

export const updateFCMTokenIfNeeded =
  () =>
  async (dispatch, getState, { api, setError }) => {
    try {
      const { user } = getState().auth;
      // const FCMToken = await messaging().getToken();
      // const currentFCMToken = _.get(user, "attributes[custom:device_token]");
      // if (FCMToken && (!currentFCMToken || FCMToken !== currentFCMToken)) {
      //   const data = { deviceToken: FCMToken, language: i18n.locale };
      //   await dispatch(authOperations.updateUserProfile(data));
      // }
    } catch (e) {
      dispatch(setError(e));
    }
  };

const handleEventRemoteMessage =
  (remoteMessage) => async (dispatch, getState) => {
    const { user, profile } = getState().auth;

    // const url = getCreatorTermsUrl();

    const processNotification = () => {
      const publisher = checkIsPublisher(user);
      if (publisher || profile?.termsAndConditions) {
        dispatch(authOperations.refreshUserAndSession());
      } else {
        // Actions.AcceptTerms({ url: url, isPublisher: true });
      }
    };

    if (_.has(remoteMessage, "notification")) {
      // Alert.alert(
      //   _.get(remoteMessage, "notification.title"),
      //   _.get(remoteMessage, "notification.body"),
      //   [
      //     {
      //       text: t("ok"),
      //       onPress: processNotification,
      //     },
      //   ],
      //   { cancelable: false }
      // );
    }
  };
