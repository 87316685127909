import * as types from './types'

export const initialState = {
  currentScene: null,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_CURRENT_SCENE:
      return {
        ...state,
        currentScene: action.payload.sceneKey,
      }

    default:
      return state
  }
}

export default reducer
