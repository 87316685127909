import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationsES from "./translations/es.json";
import translationsEN from "./translations/en.json";

let language;
const setLanguage = () => {
    if (navigator.language.includes('es')) {
        language = 'es';
    } else if (navigator.language.includes('en')) {
        language = 'en';
    } else {
        language = 'es';
    }
    document.cookie = navigator.language;
    localStorage.setItem("lang", language);
    return language;
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: setLanguage(),
        resources: {
            en: {
                translation: translationsEN,
            },
            es: {
                translation: translationsES,
            }
        },
        fallbackLng: "es",
        debug: true,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            useSuspense: false
        },
    })
    .then(() => null);

export default i18n;