import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PlainList } from "flatlist-react";
import { useEffect, useRef, useState } from "react";
import Loading from "ui-component/Loading";
import TimeLineVideoView from "./../PublicProfiles/VideosFeed/TimeLineVideoView";
import { DisoverVideoView } from "./HomeComponents/DisoverVideoView";
import { SuggestedCelebritiesList } from "./HomeComponents/suggestedCelebritiesList";
import VideoUploader from "./VideoUploader";

import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';

const LIMIT = 20;


export default function Home(props) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const displaySuggestedPage = useRef(0);
  const hasMoreItems = useRef(0);
  const { t } = useTranslation();

  const TimelineType = Object.freeze({
    Discover: TextFormat.uppercaseFirstLetter(t("discover")),
    Following: TextFormat.uppercaseFirstLetter(t("following"))
  });

  const [suggestedVideos, setSuggestedVideos] = useState([]);

  const [displaySuggested, setDisplaySuggested] = useState(false);
  const [scrolled, setSrolled] = useState(false);

  const [type, setType] = useState(TimelineType.Following);

  const onScroll = function () {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 20) {
      if (displaySuggestedPage.current < hasMoreItems.current) {
        _getDiscoverVideos();
      }
    }
  };

  const onScrollThrottled = throttle(onScroll, 500); // throttle for 500 milliseconds

useEffect(() => {
  window.addEventListener('scroll', onScrollThrottled);

  return () => window.removeEventListener('scroll', onScrollThrottled);
}, []);


  const handleChangeTimelineType = async (tab) => {
    if (
      (tab === 0 && type === TimelineType.Following) ||
      (tab === 1 && type === TimelineType.Discover)
    ) {
      return;
    }

    // displaySuggestedPage.current = 1;
    setSrolled(false);

    setType(tab === 1 ? TimelineType.Discover : TimelineType.Following);

    if (tab === 1) {
      await _getDiscoverVideos();
    }
  };

  const _getDiscoverVideos = async () => {
    const page = displaySuggestedPage.current + 1;

    const newItems = await props.getVideosToDiscover(page, LIMIT);

    hasMoreItems.current = newItems.pageCount;

    setSuggestedVideos(prevVideos => {
      return page === 1 ? newItems.data : [...prevVideos, ...newItems.data];
    });

    displaySuggestedPage.current = page;

    setDisplaySuggested(true);
  };

  return (
    <div
      style={{
        backgroundColor: "#ffff",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%" }}>
        <SuggestedCelebritiesList />
      </div>
      <div
        style={{ width: matchDownSM ? "100%" : "80%", alignItems: "center" }}
      >
        <Grid
          item
          xs={4}
          align="center"
          justify="center"
          alignItems="center"
          sx={{ display: "flex" }}
        >
          <div style={{ display: "flex", margin: "auto" }}>
            <Typography
              onClick={() => handleChangeTimelineType(0)}
              sx={{
                display: "inline",
                margin: "1rem",
                cursor: "pointer",
              }}
              component="span"
              variant="h3"
              color={
                type === TimelineType.Following ? "primary" : "text.primary"
              }
            >
              {TimelineType.Following}
            </Typography>

            <Divider
              sx={{
                borderRightWidth: 2,
                borderColor: "red",
                marginY: 2,
                height: "1.5rem",
              }}
              orientation="vertical"
              flexItem
            />

            <Typography
              onClick={() => handleChangeTimelineType(1)}
              sx={{
                display: "inline",
                margin: "1rem",
                cursor: "pointer",
              }}
              component="span"
              variant="h3"
              color={
                type === TimelineType.Discover ? "primary" : "text.primary"
              }
            >
              {TimelineType.Discover}
            </Typography>
          </div>
        </Grid>

        <div className="App">
          {type === TimelineType.Following ? (
            // props.myProfile && props.myProfile.followingCount === 0 ? (
            //   <div
            //     style={{
            //       display: "flex",
            //       justifyContent: "center",
            //       alignItems: "center",
            //       height: "50vh",
            //     }}
            //   >
            //     <Typography textAlign={"center"} width={"50%"} variant="h5">
            //       {form.noFollow}
            //     </Typography>
            //   </div>
            // ) : (
            <TimeLineVideoView
              fromFollowing={true}
              options={{
                limit: 20,
              }}
              feedGroup="timeline"
              username={props.myProfile.username}
            />
          ) : // )
          suggestedVideos?.length != 0 ? (
            <>
            <PlainList
              renderOnScroll
              list={suggestedVideos}
              renderItem={(item) => (
                <DisoverVideoView
                  canFollow={true}
                  discover={true}
                  item={item}
                />
              )}
            />
            </>

          ) : (
            <Loading isLoading={false} />
          )}
        </div>
      </div>
      <VideoUploader />
    </div>
  );
}
