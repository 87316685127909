import * as types from './types'
import _ from 'lodash'
import { FEED_TABS } from '../../config/constants'

export const initialState = {
  timelineRef: null,
  profileRef: null,
  userRef: null,
  purchaseRef: null,
  likeRef: null,
  activityRef: null,
  commentsRef: null,
  isFetching: false,
  isFetchingInfo: '',
  visibleCards: [],
  scrollEnabled: true,
  initialCardsLoad: false,
  needRefresh: FEED_TABS.NO_TABS_NEED_REFRESH,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_FEED_REF:
      return {
        ...state,
        [action.payload.key]: action.payload.ref,
      }

    case types.SET_FETCHING:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        isFetchingInfo: action.payload.isFetchingInfo,
      }

    case types.SET_VISIBLE_CARDS:
      if (_.size(action.payload.visibleCards) === 0) {
        return state
      }
      return {
        ...state,
        visibleCards: _.map(action.payload.visibleCards, v => v.index),
        initialCardsLoad: true,
      }

    case types.SET_SCROLL_ENABLED:
      return {
        ...state,
        scrollEnabled: action.payload.scrollEnabled,
      }
    case types.SET_NEED_REFRESH:
      return {
        ...state,
        needRefresh: action.payload,
      }

    case types.LOGOUT:
      return initialState

    default:
      return state
  }
}

export default reducer
