import React from 'react';

import { useNavigate } from "react-router-dom";
import { URL } from "Constants/routes";

import { Box, Button, Typography, } from '@mui/material';

const commonStyles = {
  mt: -10,
  bgcolor: 'background.paper',
  //borderColor: 'text.primary',
  //m: 1,
  boxShadow: 4,
  //border: 1,
  width: '30rem',
  height: '30rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

};
const NoFound = ({ props }) => {

  const navigate = useNavigate();

  return (
    <>
      <div style={{ display: 'flex', height: 230,  background: '#F01D22',  }}>
      </div>

      <Box sx={{ display: 'flex', justifyContent: 'center', }}>
        <Box sx={{ ...commonStyles, borderRadius: '50%' }}>
          <Typography sx={{ fontSize: '8rem', color: 'primary.main' }} variant="h1">404</Typography>
          <Typography
            sx={{ color: 'primary.main', m: 2, mb: 4 }}
            variant="h2">Oops, Page Not Found :(</Typography>

          <Button
            variant="contained"
            color="primary"
            // onClick={() =>{return <Navigate to={URL.AUTH.LOGIN} replace />}}
            onClick={() => navigate(`/${URL.APP.HOME}`)}
          >
            GO TO DASHBOARD
          </Button>
        </Box>
      </Box>
    </>
  );

}


export default NoFound;
