import {
  Avatar,
  Box,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  IconButton
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import UserAvatar from "Assets/images/avatar.png";
import commentIcon from "Assets/images/ic_comments.png";
import filledHeartIcon from "Assets/images/ic_fav_filled.png";
import outlineHeartIcon from "Assets/images/ic_fav_outline.png";
import playIcon from "Assets/images/ic_play_small.png";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, useContext, useRef } from "react";
import { CommentField, CommentList } from "react-activity-feed";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { videosOperations } from "redux/videos";
import DiscoverFollowedCard from "ui-component/cards/DiscoverFollowedCard";
import FollowedCard from "ui-component/cards/FollowedCard";
import "./styles.css";
import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';
import { purchaseVideo, getVideoUrl, fetchUserProfile, fetchAddReaction, fetchVideoRecommendations } from "api";
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import { getAvailableVideos } from "utils";
import SwipeableButton from "./SwipeableButton";
import { MyContext } from "context/CoinsContext";
import { STREAM_CONSTANTS } from "config/getstream";
import CustomCommentField from "./CustomComentField";


const useStyles = makeStyles(theme => ({
  mainContainer: {
    [theme.breakpoints.down('md')]: {
      padding: '0'
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '2em'
    },
    padding: '1em 0em 3em 0em',
  },
  box: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    border: '1px solid #D3D3D3',
    padding: '1em',
    borderRadius: '16px',
    cursor: 'pointer',
  },

  coinsIcon: {
    width: 40,
    height: 40,
    filter: 'grayscale(100%)',
  },

  coinsContainer: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    padding: '2em 0em'
  },
  inputContainer: {
    borderRadius: '25px !important',
    textTransform: 'uppercase'
  },

  termsContainer: {
    justifyContent: 'center',
    display: 'flex',
    gap: '10px'
  },

  buyButton: {
    textAlign: 'center !important',
    textTransform: 'uppercase !important',
    padding: '1em 2em !important',
    borderRadius: '50px !important',
    fontWeight: 'bold !important'
  },
  defaultValueInput: {
    textTransform: 'uppercase'
  },

  myDialog: {
    position: 'inherit !important'
  },
  cancelButton: {
    textAlign: 'center !important',
    textTransform: 'uppercase !important',
    padding: '1em 3em !important',
    borderRadius: '50px !important',
    fontWeight: 'bold !important',
    backgroundColor: 'transparent',
    color: 'black !important',
    border: '0 !important',
  },

  rootB: {
    position: 'relative',
    height: 60,
    borderRadius: 30,
    backgroundColor: 'grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  button: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '50%',
    backgroundColor: 'green',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    color: 'white',
  },
}));

export const RenderVideo = React.memo(
  ({
    item,
    isUrl,
    idx,
    search,
    discover,
    fromFollowing,
    disableCard,
    showComments,
    videoId,
  }) => {
    const theme = useTheme();
    const [commentModal, setOpenCommentModal] = useState(false);
    const [purchaseUrl, setPurchaseUrl] = useState("");
    const [show, setShow] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const [likes, setLikes] = useState(false);
    const [userToResponse, setUserToResponse] = useState(null);
    const [openedComments, setOpenedComments] = useState([]);
    const me = useSelector((state) => state.auth.profile);
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
    const actor = _.get(item, "actor");
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user);
    const { coins, updateCoins } = useContext(MyContext);
    const [isPurchased, setIsPurchased] = React.useState(!_.isNil(_.get(item, "own_reactions.purchase")) ||
      _.get(me, "username") === _.get(actor, "id"));
    const [loadingPurchase, setLoadingPurchase] = React.useState(false);
    const classes = useStyles();
    const navigate = useNavigate();
    const isFree = item.free;
    const streamToken = useSelector((state) => state.auth.streamToken);
    const playerRef = useRef(null);
    const [lastPurchasedItemId, setLastPurchasedItemId] = useState(null);

    const [comments, setComments] = useState([]);

    const fetchComments = async () => {
      if (!idx) return;
      const results = await fetch(`https://api.stream-io-api.com/api/v1.0/reaction/activity_id/${idx}/comment/?api_key=${STREAM_CONSTANTS.API_KEY}&location=unspecified&kind=comment&limit=10`, {
        headers: {
          Authorization: streamToken,
        },
      });
      const data = await results.json();
      setComments(data.results);
      setUserToResponse(null)
    };

    useEffect(() => {
      fetchComments();
    }, [idx]);

    useEffect(() => {
      setOpenedComments([])
    }, [commentModal])

    var publisherData;
    if (isUrl) {
      publisherData = item.actor.data;
      publisherData["username"] = item.actor.id;
    }

    const getVideoUrls = async () => {
      try {
        if (isPurchased || isFree) {
          const video = await getVideoUrl(videoId);
          setPurchaseUrl(video?.url);
        }
      } catch (e) { }
    };

    useEffect(() => {
      setLikes(!_.isEmpty(_.get(item, "own_reactions.like", [])));
      getVideoUrls();
    }, []);

    const likeCount = _.get(item, "reaction_counts.like", 0);

    const commentCount = _.get(item, "reaction_counts.comment", 0);

    const purchaseCount = _.get(item, "reaction_counts.purchase", 0);
    const dispatch = useDispatch();

    const handleLikePress = async () => {
      setLikes(!likes);
      const videoId = _.get(item, "object");
      const like = !_.isEmpty(_.get(item, "own_reactions.like", []));
      if (!like) {
        await dispatch(videosOperations.postLike(videoId));
      }
      await dispatch(videosOperations.fetchToggleReaction("like", idx));
    };
    const buy = async () => {
      setLoadingPurchase(true);
      try {
        if (!isFree) {
          const purchase = await purchaseVideo(item.object);
          setStatus(purchase.success ? "sucess" : status);
        }
        try {
          const fetch = await fetchAddReaction(item.id, 'purchase');
          if (fetch) {
            const video = await getVideoUrl(item.object);
            updateCoins(user.username)
            setPurchaseUrl(video?.url);
            setIsPurchased(true)
            setLastPurchasedItemId(item.id);
            fetchVideoRecommendations(item.object)
          }
        } catch (err) {
          console.error(err);
        }
      } catch (e) {
        console.log(e);
        setShow(true);
        setStatus(e.response.data.message)
      } finally {
        setLoadingPurchase(false);
      }
    }

    const handlePlayVideo = () => {
      if (playerRef.current) {
        playerRef.current.getInternalPlayer().play();
      }
    };

    const handleVideoReady = () => {
      if (isPurchased && item.id === lastPurchasedItemId) {
        handlePlayVideo();
      }
    };

    const formatCommentText = (text) => {
      if (!text) {
        return;
      }

      const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
      const replacedText = text.replace(mentionRegex, (match, username, userId) => {
        return (
          `<a href="/public-profile/${userId}" style="text-decoration: none; color: #f01d22; cursor: pointer;">${username}</a>`
        );
      });
      // Renderizar el HTML resultante
      return <span dangerouslySetInnerHTML={{ __html: replacedText }} />;
    };

    return (

      <Grid
        item
        id={`video-${item.id}`}
        xs={search ? 2 : 0}
        sm={search ? 4 : 0}
        md={search ? 4 : 0}
        style={{ marginTop: 40 }}
      >
        <div
          style={{
            width: matchDownSM ? "100%" : search ? "96%" : "80%",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
          }}
        >


          {discover ? (
            <DiscoverFollowedCard
              discover={discover}
              search={search}
              sx={{ width: 50, height: 50 }}
              index={idx}
              item={isUrl ? publisherData : item?.owner}
            />
          ) : (
            <>
              {!disableCard && (
                <FollowedCard
                  allData={item}
                  fromFollowing={fromFollowing}
                  search={search}
                  sx={{ width: 50, height: 50 }}
                  index={idx}
                  item={isUrl ? publisherData : item?.owner}
                />
              )}
            </>
          )}
          <div
            style={{
              background: "black",
              borderRadius: matchDownSM || search ? 0 : 30,
              //  padding: matchDownSM ? 0 : 20,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              position: "relative"
            }}
          >
            <ReactPlayer
              ref={playerRef}
              url={
                isUrl
                  ? isPurchased
                    ? purchaseUrl
                    : item?.trailerUrl
                  : isPurchased
                    ? purchaseUrl
                    : item?.trailer
              }
              // playing
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
                facebook: {
                  appId: "12345",
                },
              }}
              height={
                matchDownSM && !search ? "400px" : search ? "300px" : "600px"
              }
              width="100%"
              controls
              onReady={handleVideoReady}
            />
            {isPurchased ? null : (
              <>
                <SwipeableButton click={buy} loadingPurchase={loadingPurchase} isFree={isFree} />
              </>
            )
            }
          </div>
          <Typography
            color={"black"}
            gutterBottom
            sx={{ marginTop: 2 }}
            variant={"h4"}
          >
            {item?.title}
          </Typography>

          {(showComments || isPurchased) && (
            <>
              <div
                style={{ display: "flex", marginTop: 10, alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    marginRight: 20,
                    alignItems: "center",
                  }}
                  onClick={() => handleLikePress()}
                >
                  <img
                    style={{ width: 25, height: 25, marginRight: 5 }}
                    src={likes ? filledHeartIcon : outlineHeartIcon}
                    alt="Logo"
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {likeCount}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginRight: 20,
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: 25, height: 25, marginRight: 5 }}
                    src={playIcon}
                    alt="Logo"
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {purchaseCount}
                  </Typography>
                </div>
                <div
                  id={`comments-${item.id}`}
                  style={{
                    display: "flex",
                    marginRight: 20,
                    alignItems: "center",
                  }}
                  onClick={() => setOpenCommentModal(true)}
                >
                  <img
                    style={{ width: 25, height: 25, marginRight: 5 }}
                    src={commentIcon}
                    alt="Logo"
                  />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {commentCount}
                  </Typography>
                </div>
              </div>
            </>
          )}

          <Modal
            open={commentModal}
            onClose={() => {
              setOpenCommentModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: matchDownSM ? 320 : 550,
                background: "#fff",
                padding: 20,
                height: "70%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {TextFormat.uppercaseFirstLetter(t("comments"))}
              </Typography>
              <Divider />
              <Box style={{ flex: 1, overflowY: "auto" }}>
                {comments.length ? (
                  comments.map((comment) => (
                    <Paper style={{ marginTop: 10 }}>
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                          <Avatar alt="Remy Sharp" src={comment?.user?.data?.image || UserAvatar} />
                        </Grid>
                        <Grid justifyContent="left" item xs zeroMinWidth>
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: "bold",
                              margin: 0,
                              textAlign: "left",
                              color: "black",
                            }}
                          >
                            <a href={`/public-profile/${comment.user.id}`} style={{ textDecoration: "none", color: "black", cursor: "pointer" }}>
                              <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", margin: 0, paddingRight: "5px" }}>{comment.user.data.nickname}</p>
                            </a>
                          </Typography>
                          <p style={{ textAlign: "left" }}>
                            {formatCommentText(comment.data?.data?.text || comment.data?.text)}
                          </p>
                        </Grid>
                        <Grid>
                          <p style={{ textAlign: "left", color: "gray", position: "relative", top: "3px", paddingRight: 8 }}>
                            {moment(comment.created_at).fromNow()}
                          </p>
                        </Grid>

                      </Grid>
                      <Grid>
                        <p onClick={() => {
                          setUserToResponse({ name: comment.user.data.nickname, id: comment.user.id, comment_id: comment.id, comment: comment });
                        }} style={{ cursor: "pointer", margin: "0", marginLeft: "55px", position: "relative", top: "-5px", textAlign: "left", color: "gray", fontSize: "12px" }}>
                          {TextFormat.uppercaseFirstLetter(t("reply"))}
                        </p>
                      </Grid>
                      {comment.latest_children?.comment ?
                        <>
                          {!openedComments.includes(comment.id) ? <div onClick={() => {
                            let cAux = [...openedComments]
                            cAux.push(comment.id)
                            setOpenedComments(cAux)
                          }} style={{ cursor: "pointer", margin: "0", marginLeft: "100px", marginTop: "5px", position: "relative", top: "-5px", textAlign: "left", color: "gray", fontSize: "12px", display: "flex", alignItems: "center" }}>
                            <p>{TextFormat.uppercaseFirstLetter(t("show"))} {comment.latest_children?.comment.length} {(t("answers"))}</p>
                          </div> :
                            <div onClick={() => {
                              let cAux = [...openedComments]
                              setOpenedComments(cAux.filter(cadena => cadena !== comment.id))
                            }} style={{ cursor: "pointer", margin: "0", marginLeft: "100px", marginTop: "5px", position: "relative", top: "-5px", textAlign: "left", color: "gray", fontSize: "12px", display: "flex", alignItems: "center" }}>
                              <p>{TextFormat.uppercaseFirstLetter(t("hide"))} {comment.latest_children?.comment.length} {(t("answers"))}</p>
                            </div>}

                          <Grid style={{ paddingLeft: "60px", paddingTop: "10px" }}>
                            {openedComments.includes(comment.id) && comment.latest_children?.comment.map((subComment) => (
                              <Grid container wrap="nowrap" spacing={2}>
                                <Grid item>
                                  <Avatar alt="Remy Sharp" src={subComment?.user?.data?.image || UserAvatar} />
                                </Grid>
                                <Grid justifyContent="left" item xs zeroMinWidth>
                                  <Typography
                                    style={{
                                      fontSize: 15,
                                      fontWeight: "bold",
                                      margin: 0,
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    <a href={`/public-profile/${subComment.user.id}`} style={{ textDecoration: "none", color: "black", cursor: "pointer" }}>
                                      <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", margin: 0, paddingRight: "2px" }}>{subComment.user.data.nickname}</p>
                                    </a>
                                  </Typography>
                                  <p style={{ textAlign: "left" }}>
                                    {formatCommentText(subComment.data?.data?.text || subComment.data?.text)}
                                  </p>
                                </Grid>
                                <Grid>
                                  <p style={{ textAlign: "left", color: "gray", position: "relative", top: "3px" }}>
                                    {moment(subComment.created_at).fromNow()}
                                  </p>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid> </> : null}
                      <Divider style={{ color: "black" }} />
                    </Paper>
                  ))
                ) : (
                  <p>{TextFormat.uppercaseFirstLetter(t("noComments"))}</p>
                )}
                {/* <CommentList
                activityId={idx}
                CommentItem={({ comment }) => {
                  return (
                    <>
                      <Paper style={{ marginTop: 10, backgroundColor: 'blue' }}>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item>
                            <Avatar alt="Remy Sharp" src={UserAvatar} />
                          </Grid>
                          <Grid justifyContent="left" item xs zeroMinWidth>
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: "bold",
                                margin: 0,
                                textAlign: "left",
                                color: "black",
                              }}
                            >
                              {comment.user.data.nickname}
                            </Typography>

                            <p style={{ textAlign: "left" }}>
                              {comment.data?.data?.text
                                ? comment.data?.data?.text
                                : comment.data?.text}
                            </p>
                          </Grid>
                          <Grid>
                            <p style={{ textAlign: "left", color: "gray" }}>
                              {moment(comment.created_at).fromNow()}
                            </p>
                          </Grid>
                        </Grid>
                        <Divider style={{ color: "black" }} />
                      </Paper>
                    </>
                  );
                }}
                limit={1000}
                hasMore={false}
                infiniteScroll={false}
              /> */}
                {/* <CommentField onSuccess={fetchComments} activity={item} placeholder={TextFormat.uppercaseFirstLetter(t("addcomment"))} /> */}
              </Box>
              {userToResponse ? (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <p>{TextFormat.uppercaseFirstLetter(t("replying"))} {userToResponse.name}</p>
                  <div style={{ cursor: "pointer" }} onClick={() => setUserToResponse(null)}>
                    <p style={{ fontSize: 16 }}>x</p>
                  </div>
                </div>
              ) : null}
              <CustomCommentField defaultMention={userToResponse} commentModal={commentModal} onSuccess={fetchComments} activity={item} placeholder={TextFormat.uppercaseFirstLetter(t("addcomment"))} />
            </Box>
          </Modal>

          <Dialog open={show} onClose={() => setShow(false)} classes={{ paper: classes.myDialog }}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "#fff",
                p: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: '60%',
                [theme.breakpoints.down("md")]: {
                  width: '100%',
                },
              }}
            >
              {status === "Already purchased" ? null : (
                <>
                  <Typography variant="h3" mb={3} align="center" color="black">
                    {t("purchaseModal.availableVideosLabel")}
                  </Typography>
                  <Avatar
                    variant="rounded"

                    sx={{
                      alignItems: "center",
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumsAvatar,
                      transition: "all .2s ease-in-out",
                      background: "transparent",
                      color: theme.palette.secondary.dark,
                      marginTop: '1em',
                      marginBottom: '1em',
                      width: '100%',
                    }}
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <img
                      style={{ width: 30, height: 30, marginRight: 5 }}
                      src={require("./../../../Assets/images/ic_play_coins.png")}
                      alt="coins"
                    />
                    <Typography
                      sx={{
                        color: theme.palette.secondary.dark,

                        marginX: "2px",
                        mt: "2px",
                      }}
                      variant="h4"
                    >
                      {coins}
                    </Typography>
                  </Avatar>
                </>
              )}

              <Typography variant="h2" align="center" mt={2} fontWeight="bold">
                {status === "Already purchased" ? "Video already purchased" : t("purchaseModal.purchaseLabel")}
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="center" mt={4} >
                {status === "Already purchased" ? null : (
                  <Button className={classes.buyButton} variant="contained" color="primary" mb={1} onClick={() => navigate('/products')}>
                    {t("purchaseModal.purchaseInAppButtonLabel")}
                  </Button>
                )}

                <Button className={classes.cancelButton} onClick={() => setShow(false)} variant="outlined" color="primary">
                  {t("purchaseModal.back")}
                </Button>
              </Box>
            </Box>
          </Dialog>
        </div>
      </Grid>
    );
  }
);
