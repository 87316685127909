export const SET_FETCHING = '@videos/SET_FETCHING'
export const SET_ITEM = '@videos/SET_ITEM'
export const SET_LIST = '@videos/SET_LIST'
export const SET_PAGE = '@videos/SET_PAGE'
export const SET_PER_PAGE = '@videos/SET_PER_PAGE'
export const SET_CATEGORIES = '@videos/SET_CATEGORIES'
export const SET_STATS = '@videos/SET_STATS'
export const SET_UNMUTED_VIDEO = '@videos/SET_UNMUTED_VIDEO'
export const SET_FETCHING_SUGGESTED = '@videos/SET_FETCHING_SUGGESTED'
export const START_LOAD_VIDEO = '@videos/START_LOAD_VIDEO'
export const START_PURCHASE_VIDEO = '@videos/START_PURCHASE_VIDEO'
export const SET_VIDEO_TO_SHOW = '@videos/SET_VIDEO_TO_SHOW'
export const SET_VIDEOS_TO_DISCOVER = '@videos/SET_VIDEOS_TO_DISCOVER'
export const START_DISCOVER = '@videos/START_DISCOVER'
export const SET_SUGGESTED_CELEBRITIES = '@videos/SET_SUGGESTED_CELEBRITIES'
export const SET_PROFILE_TO_SHOW = '@videos/SET_PROFILE_TO_SHOW'

export const LOGOUT = 'LOGOUT'
