import * as videosActions from "./actions";
import { authOperations } from "../auth";
// import { Platform } from "react-native";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { AWS_CONFIG } from "../../config/aws";
import { filterValidVideos } from "../../utils";
import {
  trackUploadVideo,
  trackPublishVideo,
  trackAddVideoInfo,
  trackUploadErrorVideo,
} from "../../utils/tracks";
import {
  fetchDiscoverVideos,
  fetchSuggestedList,
  fetchVideoCategoriesApi,
  generateUploadVideoUrl,
  postVideoApi,
  uploadVideo,
} from "api";
import { postLikes, fetchToggleReactions } from "api";

export const initList = () => async (dispatch) => {
  dispatch(videosActions.setList([], 0));
  dispatch(videosActions.setPage(1));
  return dispatch(fetchList());
};

export const updatePage = (page) => async (dispatch, getState) => {
  dispatch(videosActions.setPage(page));
  return dispatch(fetchList());
};

export const fetchList =
  () =>
  async (dispatch, getState, { api, setError }) => {
    try {
      dispatch(videosActions.setFetching(true));

      const { list: currentList, page } = getState().videos;

      // API REQUEST PARAMS
      const params = { page };

      // CONCATENATED LIST
      const fetchVideosRes = await api.fetchVideos(params);
      const newList = filterValidVideos(_.get(fetchVideosRes, "data", []));
      const list = [...currentList, ...newList];
      const total = _.get(fetchVideosRes, "pageCount", 1);

      dispatch(videosActions.setList(list, total));
      return list;
    } catch (e) {
      dispatch(setError(e));
      return [];
    } finally {
      dispatch(videosActions.setFetching(false));
    }
  };

export const fetchItem =
  (videoId) =>
  async (dispatch, getState, { api, setError }) => {
    if (!videoId) {
      return;
    }

    try {
      dispatch(videosActions.setItem(null));
      dispatch(videosActions.setFetching(true));
      const item = await api.fetchVideo(videoId);
      dispatch(videosActions.setItem(item));
      return item;
    } catch (e) {
      dispatch(setError(e));
      return null;
    } finally {
      dispatch(videosActions.setFetching(false));
    }
  };

export const postVideo =
  ({ video, title, status, publicationDate, category, videoFile }) =>
  async (dispatch, getState) => {
    const videoUri = _.get(video, "uri");
    if (!videoUri) {
      return null;
    }

    const { user } = getState().auth;

    try {
      dispatch(videosActions.setFetching(true, "Starting upload"));
      const data = {
        title,
        categoryId: category ? category.id : null,
        status,
        publicationDate,
      };
      const postVideoRes = await postVideoApi(data);
      trackAddVideoInfo(video, data, postVideoRes);
      trackPublishVideo(video, data, postVideoRes);
      const originalName = _.get(postVideoRes, "originalName");
      const videoId = _.get(postVideoRes, "id");

      const type = "mp4";
      const extension = "mp4";
      const name = `${originalName}.${extension}`;

      const iss = `cognito-idp.${AWS_CONFIG.region}.amazonaws.com/${AWS_CONFIG.userPoolId}`;
      const idJwtToken = _.get(user, "signInUserSession.idToken.jwtToken");
      const getVideoUrlData = {
        identityPoolId: AWS_CONFIG.identityPoolId,
        logins: { [iss]: idJwtToken },
      };

      const generatePostVideoUrlRes = await generateUploadVideoUrl(
        videoId,
        getVideoUrlData
      ).catch(() => {
        trackUploadErrorVideo("generate");
      });
      const postVideoUrl = _.get(generatePostVideoUrlRes, "data.url");
      const postVideoFields = _.get(generatePostVideoUrlRes, "data.fields");

      const formData = new FormData();
      formData.append("key", postVideoFields.key.replace("${filename}", name));
      _.map(postVideoFields, (v, k) => {
        if (k !== "key") {
          formData.append(k, v);
        }
      });

      formData.append("file", videoFile);

      const onProgress = (percentCompleted) =>
        dispatch(
          videosActions.setFetching(
            true,
            percentCompleted + "% of video uploaded."
          )
        );
      const uploadVideoRes = await uploadVideo(
        postVideoUrl,
        formData,
        onProgress
      ).catch(() => {
        trackUploadErrorVideo("upload");
      });
      dispatch(videosActions.setFetching(true, "Video successfully uploaded"));
      trackUploadVideo(video, postVideoUrl, postVideoRes);
      return uploadVideoRes;
    } catch (e) {
      return null;
    } finally {
      dispatch(videosActions.setFetching(false));
    }
  };

export const fetchVideoCategories = () => async (dispatch, getState) => {
  const { categories: categoriesList } = getState().videos;
  if (_.size(categoriesList)) {
    return;
  }

  try {
    dispatch(videosActions.setFetching(true));
    const fetchVideoCategoriesRes = await fetchVideoCategoriesApi();
    const categories = _.get(fetchVideoCategoriesRes, "data", []);
    dispatch(videosActions.setCategories(categories));
    return categories;
  } catch (e) {
    // dispatch(setError(e));
    return [];
  } finally {
    dispatch(videosActions.setFetching(false));
  }
};

export const purchaseVideo =
  (videoId) =>
  async (dispatch, getState, { api, setError }) => {
    if (_.isNil(videoId)) {
      return false;
    }

    try {
      await api.purchaseVideo(videoId);
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      await dispatch(authOperations.setUser(user));
      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    }
  };

export const deleteVideo =
  (videoId) =>
  async (dispatch, getState, { api, setError }) => {
    if (_.isNil(videoId)) {
      return false;
    }

    try {
      dispatch(videosActions.setFetching(true));
      await api.deleteVideo(videoId);
      dispatch(removeFromListIfNeeded(videoId));
      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    } finally {
      dispatch(videosActions.setFetching(false));
    }
  };

export const updateVideo =
  (videoId, data) =>
  async (dispatch, getState, { api, setError }) => {
    if (_.isNil(videoId)) {
      return false;
    }

    try {
      dispatch(videosActions.setFetching(true));
      const videoRes = await api.updateVideo(videoId, data);
      dispatch(updateOnListIfNeeded(videoRes));
      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    } finally {
      dispatch(videosActions.setFetching(false));
    }
  };

const removeFromListIfNeeded = (videoId) => (dispatch, getState) => {
  const { list } = getState().videos;
  const filteredList = _.filter(list, (v) => v.id !== videoId);
  if (_.size(filteredList) !== _.size(list)) {
    dispatch(videosActions.setList(filteredList));
  }
};

const updateOnListIfNeeded = (video) => (dispatch, getState) => {
  const { list, totalItems } = getState().videos;
  const videoIndex = _.findIndex(list, { id: video.id });
  if (videoIndex > -1) {
    const newList = [...list];
    _.update(newList, `[${videoIndex}]`, () => video);
    dispatch(videosActions.setList(newList, totalItems));
  }
};

export const fetchStats =
  () =>
  async (dispatch, getState, { api, setError }) => {
    try {
      dispatch(videosActions.setFetching(true));
      const data = await api.fetchStats();
      dispatch(videosActions.setStats(data.stats));
      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    } finally {
      dispatch(videosActions.setFetching(false));
    }
  };

export const postLike = (videoId) => async (dispatch) => {
  try {
    const response = await postLikes(videoId);
    console.log("responseps", response);
  } catch (e) {}
};

export const fetchSuggestedLists =
  () =>
  async (
    dispatch
    // getState, { api, setError }
  ) => {
    try {
      dispatch(videosActions.setFetchingSuggested(true));
      const response = await fetchSuggestedList();

      dispatch(videosActions.setSuggestedCelebritiesList(response.data));
      return response;
    } catch (e) {
      // dispatch(setError(e));
      return [];
    } finally {
      dispatch(videosActions.setFetchingSuggested(false));
    }
  };

//TODO:limit to blocked
export const fetchVideosToDiscovered =
  (page, limit) =>
  async (
    dispatch
    //, getState, { api, setError }
  ) => {
    try {
      console.log("🚀 ~ file: operations.js ~ line 319 ~ page", page);
      const response = await fetchDiscoverVideos(page, limit);

      // console.log(response)
      dispatch(videosActions.setVideosToDiscover(response.data));

      return response;
    } catch (e) {
      //    dispatch(setError(e));

      return [];
    }
  };

export const fetchAddReaction =
  (activityId, event) =>
  async (dispatch, getState, { api, setError }) => {
    try {
      const response = await api.fetchAddReaction(activityId, event);

      return response;
    } catch (e) {
      dispatch(setError(e));

      return {};
    }
  };

export const fetchToggleReaction =
  (activityId, event = "like") =>
  async (dispatch) => {
    try {
      const response = await fetchToggleReactions(activityId, "like");
      console.log("response", response);
      return response;
    } catch (e) {
      return {};
    }
  };
