import axios from "axios";
import _ from "lodash";
import * as ApiConfig from "../config/api";
import * as WSLogger from "./logger";
import { Auth } from "aws-amplify";
import { authOperations } from "../redux/auth";
import { authActions } from "../redux/auth";
import { TOKEN_EXPIRED } from "../config/constants";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { store } from "../store";

export const instance = axios.create({
  baseURL: ApiConfig.BASE_URL,
  headers: { "Content-type": "application/json" },
});

const refreshAuthLogic = async (failedRequest) => {
  if (failedRequest?.response?.data?.message !== TOKEN_EXPIRED) {
    return Promise.reject();
  }

  try {
    const signInUserSession = await Auth.currentSession();
    const token = _.get(signInUserSession, "accessToken.jwtToken", null);

    if (token) {
      store.dispatch(authActions.setUserSession(signInUserSession));
      instance.defaults.headers.common.Authorization = "Bearer " + token;
      failedRequest.response.config.headers.Authorization = "Bearer " + token;
      return Promise.resolve();
    } else {
      store.dispatch(authOperations.doLogout());
    }
  } catch (e) {
    store.dispatch(authOperations.doLogout());
  }
  return Promise.reject();
};

instance.interceptors.request.use(
  async (config) => {
    WSLogger.logCall(config.method, config.url, config);
    return config;
  },
  (err) => {
    WSLogger.logError(err);
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => {
    WSLogger.logResponse(
      response?.config?.method,
      response?.config?.url,
      response
    );
    return _.get(response, "data", response);
  },
  async (err) => {
    WSLogger.logError(err);
    return Promise.reject(err);
  }
);

createAuthRefreshInterceptor(instance, refreshAuthLogic, {
  statusCodes: [401, 403],
});

export function configureAxiosDefaults(header, value) {
  instance.defaults.headers.common[header] = value;
}
