import PropTypes from "prop-types";
import { useState } from "react";
import { URL } from "Constants/routes";
// material-ui
import IconButton from "@mui/material/IconButton";
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "./../../../../ui-component/extended/Transitions";
import { useNavigate } from "react-router-dom";
// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";

import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: "340px",

    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "340px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 12,
      background: "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: "transparent",
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

// ==============================|| SEARCH INPUT ||============================== //
//
const SearchSection = (props) => {
  const theme = useTheme();
  // const [value, setValue] = useState('');
  const { value, setValue } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      {props.disable ? (
        <>
          <Box sx={{ display: { xs: props.search && "none", md: "none" } }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <HeaderAvatarStyle
                onClick={() => navigate(`/${URL.APP.SEARCH}`)}
                variant="rounded"
              >
                <IconSearch stroke={1.5} size="1.2rem" />
              </HeaderAvatarStyle>
            </ButtonBase>
          </Box>
          <Box
            sx={{
              display: {
                xs: !props.search && "none",
                md: "block",
              },
            }}
          >
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <HeaderAvatarStyle
                onClick={() => navigate(`/${URL.APP.SEARCH}`)}
                variant="rounded"
              >
                <IconSearch stroke={1.5} size="1.2rem" />
              </HeaderAvatarStyle>
            </ButtonBase>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: { xs: props.search && "none", md: "none" } }}>
            <ButtonBase sx={{ borderRadius: "12px", mt: 1 }}>
              <HeaderAvatarStyle
                onClick={() => navigate(`/${URL.APP.SEARCH}`)}
                variant="rounded"
              >
                <IconSearch stroke={1.5} size="1.2rem" />
              </HeaderAvatarStyle>
            </ButtonBase>
          </Box>
          <Box
            sx={{
              display: {
                xs: !props.search && "none",
                md: "block",
              },
            }}
          >
            <OutlineInputStyle
              onClick={props.onClick}
              disabled={props.disable}
              id="input-search-header"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={TextFormat.uppercaseFirstLetter(t("search"))}
              startAdornment={
                <InputAdornment position="start">
                  <IconSearch
                    stroke={1.5}
                    size="1rem"
                    color={theme.palette.grey[500]}
                  />
                </InputAdornment>
              }
              //  endAdornment={
              //     <InputAdornment position="end">
              //         <ButtonBase sx={{ borderRadius: '12px' }}>
              //             <HeaderAvatarStyle variant="rounded">
              //                 <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
              //             </HeaderAvatarStyle>
              //         </ButtonBase>
              //     </InputAdornment>
              // }
              aria-describedby="search-helper-text"
              inputProps={{ "aria-label": "weight" }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default SearchSection;
