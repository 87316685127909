import { LANDING_URL } from "../config/api";

export const VALIDATIONS = {
  EMAIL_REGEX: new RegExp(
    "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$",
    "i"
  ),
  PASSWORD_REGEX: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
};

export const Z_INDEX = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const STATS_TABS = {
  YESTERDAY_TAB: 0,
  LAST_7_DAYS: 1,
  LAST_30_DAYS: 2,
};

export const BILLING_TABS = {
  BILLING: 0,
  SHARE_CODE: 1,
};

export const ERROR_MESSAGES = {
  ALIAS_IN_USE: "Alias is already in use",
  EMAIL_IN_USE: "An account with the given email already exists",
  REFERRAL_CODE: "ReferralCode is not valid",
  NO_MORE_INVITATIONS: "PreSignUp failed with error No remaining invitations",
};

export const FLAT_FEED_LIST_PROPS = {
  //initialNumToRender: 3,
  //maxToRenderPerBatch: 9,
  //windowSize: 3,
  //removeClippedSubviews: true,
  viewabilityConfig: {
    waitForInteraction: true,
    minimumViewTime: 500,
    viewAreaCoveragePercentThreshold: 50,
  },
};

export const FLAT_FEED_LIST_ROWS_PREVIEW = 6;

export const NAME_KEY_LOCALES = {
  ES: "nameEs",
  EN: "nameEn",
};

export const VALIDATE_CODE_TYPE = {
  REFERRAL: "referral",
  INVITED: "invitation",
};

export const DEEP_LINK = {
  DYNAMIC_LINK_PREFIX: "https://bethey.page.link",
  INVITATION: "https://bethey.com/invitation-code",
  ANDROID: {
    packageName: "com.bethey_app",
    fallbackUrl: LANDING_URL,
  },
  IOS: {
    bundleId: "com.bythey.bethey",
    appStoreId: "1527392193",
  },
  INVITATION_CODE: "invitation-code",
  INVITATION_LINK:
    "https://bethey.page.link/?link=https://bethey.com/invitation-code/",
  INVITATION_APPS: "&apn=com.bethey_app&isi=1527392193&ibi=com.bythey.bethey",
  URL_VIDEO: "https://bethey.com/sharedvideo?",
  URL_VIDEO_ALTERNATIVE: LANDING_URL,
  URL_PROFILE: "https://bethey.com/sharedprofile?",
};

export const TOKEN_EXPIRED = "token_expired";

export const TRACKS = {
  FIELDS: {
    USER_TYPE: "userType",
    USER_NAME: "username",
    OS: "os",
  },
  USER_TYPE_VALUES: {
    CREATOR: "creator",
    USER: "not-creator",
  },
  EVENTS: {
    REGISTRATION_COMPLETE: "sign-up-complete",
    REGISTRATION_START: "sign-up-start",
    SHOW_VIDEO: "show-video",
    PURCHASE: "purchase-success",
    PURCHASE_STARTED: "purchase-started",
    PURCHASE_FAILURE: "purchase-failure",
    TRY_PURCHASE: "video-unlock-0-credit",
    FOLLOW: "follow",
    FOLLOWED: "followed",
    ADD_FAVORITE: "add-favourite",

    PUBLISH_VIDEO: "publish-video",
    REGISTRATION_SUCCESS_CODE: "registration-complete-with-code",
    CREATE_VIDEO: "create-video",
    UPLOAD_VIDEO: "upload-video",
    UPLOAD_ERROR_VIDEO: "upload-error",
    ADD_VIDEO_INFO: "add-video-info",
    PREVIEW_VIDEO: "preview-video-with-sound",
    SHARE_VIDEO: "share-video",
    LIKE_VIDEO: "video_like",
    PURCHASE_VIDEO: "purchase-video",
    COMMENT_VIDEO: "comment-video",
    PLAY_VIDEO: "play-video",
    SEND_INVITATION: "send-invitation",
    USE_INVITATION: "use-invitation",
    LOAD_TIME: "app-load-time",
    VIDEO_LOAD_TIME: "video-load-time",
    FROM_START_PURCHASE_TO_SEE_VIDEO: "purchase-and-view-load-time",
    IAPP: {
      REQUEST_PURCHASE: "inapp-request-purchase",
      REQUEST_PURCHASE_SUCCESS: "inapp-request-purchase-success",
      REQUEST_PURCHASE_ERROR: "inapp-request-purchase-error",
      PURCHASE_UPDATED: "inapp-purchase-msg-received-success",
      PURCHASE_ERROR: "inapp-purchase-msg-received-error",

      NOT_RECEIPT: "inapp-not-receipt",
      PROCESS_STARTED: "inapp-process-has-receipt",
      INFO_BACKEND: "inapp-call-verify-api",
      INFO_BACKEND_SUCCESS: "inapp-call-verify-api-success",
      ERROR_API: "inap-call-verify-api-error",
      FINISH_TRANSACTION: "inapp-finish-transaction-success",
      ERROR_FINISH_TRANSACTION: "inapp-finish-transaction-error",
      ERROR_FINISH: "inapp-error-finish",
      ATTEMPT_BUNDLE_PURCHASE: "purchase_attempt",

      WILL_SUBSCRIBE: "inapp-will-subscribe",
      WILL_UNSUBSCRIBE_UPDATE: "inapp-will-unsubscribe-updates",
      WILL_UNSUBSCRIBE_ERROR: "inapp-will-unsubscribe-error",
    },
    APPSFLYER: {
      REGISTRATION_COMPLETE: "af_sign_up_complete",
      REGISTRATION_START: "af_sign_up_start",
      PURCHASE: "af_purchase_success",
      PURCHASE_STARTED: "af_purchase_attempt",
    },
  },
  PROPERTIES: {
    OS: "os",
  },
};

export const COGNITO_ERROR_CODES = {
  CODE_MISMATCH: "CodeMismatchException",
  EXPIRED_CODE: "ExpiredCodeException",
  PASSWORD_INVALID: "InvalidPasswordException",
};

export const TYPEFORMS = {
  ES: "https://quiz.typeform.com/to/lI5gDyiz",
  EN: "https://quiz.typeform.com/to/XRQfu2Ze",
};

export const BENEFICIARIES_CONTEXTS = {
  PURCHASE: "purchase",
  BILLING: "billing",
};

export const EMAIL_CONTACT_SUPPORT = "vip@bethey.com";
export const MIX_PANEL_CONF = {
  DEV_KEY: "wfWNkuSPvcqJhD8RmvSi78",
  APP_ID: "1527392193",
  TIME_TO_WAIT_FOR_ATT_USER_AUTHORIZATION: 10,
};

export const EMAIL_CONTACT_SUPPORT_USER = "hello@bethey.com";
export const WHATS_APP = "whatsapp";
export const SMS = "sms";
export const FACEBOOK = "facebook";
export const LINK = "link";
export const OTHER = "other";

export const NETWORK_SOCIAL = [
  // {
  //   name: WHATS_APP,
  //   icon: require("../assets/images/ic_profile.png"),
  //   text: WHATS_APP,
  // },
  // { name: SMS, icon: require("../assets/images/ic_profile.png"), text: SMS },
  // {
  //   name: FACEBOOK,
  //   icon: require("../assets/images/ic_profile.png"),
  //   text: FACEBOOK,
  // },
  // { name: LINK, icon: require("../assets/images/ic_profile.png"), text: LINK },
  // {
  //   name: OTHER,
  //   icon: require("../assets/images/ic_profile.png"),
  //   text: OTHER,
  // },
];

export const FEED_TABS = {
  NO_TABS_NEED_REFRESH: -1,
  PUBLISHED: 0,
  MY_VIDEOS: 1,
  PURCHASED: 2,
  FAVORITES: 3,
};
