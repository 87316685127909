import * as types from "./types";
// import { configureScope, setUser } from '@sentry/minimal'

const initialState = {
  isFetching: false,
  isFetchingInfo: "",
  user: null,
  profile: null,
  getstreamToken: null,
  streamToken: null,
  init: false,
  invitationCode: null,
  startApp: null,
  hasOpenAppBefore: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_AUTH_INIT:
      return { ...state, init: true };
    case types.SET_AUTH_USER:
      // if (action.payload.username) {
      //   setUser({ username: action.payload.username })
      // } else {
      //   configureScope(scope => scope.setUser(null))
      // }
      return { ...state, user: action.payload };
    case types.SET_AUTH_PROFILE:
      return { ...state, profile: action.payload };
    case types.SET_CURRENT_USER_PROFILE:
      return { ...state, currentUser: action.payload };
    case types.SET_AUTH_GETSTREAM_TOKEN:
      return { ...state, getstreamToken: action.payload };
    case types.SET_STREAM_TOKEN:
      console.log({ ...state, streamToken: action.payload });
      return { ...state, streamToken: action.payload };
    case types.SET_AUTH_FETCHING:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        isFetchingInfo: action.payload.isFetchingInfo,
      };
    case types.SET_INVITATION_CODE:
      return { ...state, invitationCode: action.payload };
    case types.SET_USER_SESSION:
      return {
        ...state,
        user: { ...state.user, signInUserSession: action.payload },
      };
    case types.START_APP:
      return { ...state, startApp: action.payload };
    case types.SET_HAS_OPEN_APP_BEFORE:
      return { ...state, hasOpenAppBefore: action.payload };
    case types.LOGOUT:
      return { ...initialState, init: null };
    default:
      return state;
  }
};

export default reducer;
