import * as types from './types'

export const initialState = {
  initialNotificationInfo: null,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.INITIAL_NOTIFICATION_INFO:
      return {
        ...state,
        initialNotificationInfo: action.payload,
      }

    default:
      return state
  }
}

export default reducer
