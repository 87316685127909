import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomAvatar from "ui-component/Avatar";

export const SuggestedCelebritiesList = () => {
  const suggestedCelebritiesList = useSelector(
    (state) => state.videos.suggestedCelebritiesList
  );
  return (
    <div
      id="celeb-list"
      style={{
        padding: 10,
        display: "flex",
        maxWidth: "100%",
        overflow: "scroll",

        justifyContent: "center",
      }}
    >
      {suggestedCelebritiesList.map((value, key) => (
        <div key={key}>
          <Link
            to={"/public-profile/" + value.username}
            className="username-link"
          >
            <CustomAvatar
              sx={{
                height: 60,
                width: 60,
              }}
              item={{
                username: value.nickname,
                image: value.image,
                publisher: value.publisher,
                publisherType: value.publisherType,
              }}
            />
          </Link>
          <Typography
            gutterBottom
            sx={{ textAlign: "center", margin: 1 }}
            variant={"h4"}
          >
            {value.name.length > 8
              ? value.name.substring(0, 8) + ""
              : value.name}
          </Typography>
        </div>
      ))}
    </div>
  );
};
