import * as types from "./types";

export const initialState = {
  list: [],
  totalItems: 0,
  page: 1,
  perPage: 10,
  item: null,
  isFetching: false,
  isFetchingInfo: "",
  itemSaving: null,
  celebritiesList: [],
  totalCelebrities: 0,
  blockedUsersList: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_FETCHING:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        isFetchingInfo: action.payload.isFetchingInfo,
      };
    case types.SET_ITEM_SAVING:
      return {
        ...state,
        itemSaving: action.payload,
      };

    case types.SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };

    case types.SET_LIST:
      return {
        ...state,
        list: action.payload.list,
        totalItems: action.payload.totalItems,
      };

    case types.SET_CELEBRITIES_LIST:
      return {
        ...state,
        celebritiesList: action.payload.list,
        totalCelebrities: action.payload.totalItems,
      };

    case types.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case types.SET_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };

    case types.UPDATE_ITEM_FOLLOW:
      return {
        ...state,
        celebritiesList: state.celebritiesList.map((element) => {
          if (element.id !== action.payload.item.id) {
            return element;
          } else {
            const el = { ...element, currentlyFollowing: action.payload.value };
            return el;
          }
        }),
      };

    // case types.BLOCK_USER:
    //   return {
    //     ...state,
    //     blockedUsersList: state.blockedUsersList.push(element),
    //   };

    // case types.SET_BLOCK_USER_LIST:
    //   return {
    //     ...state,
    //     blockedUsersList: state.blockedUsersList.push(element),
    //   };

    case types.REQUEST_BLOCK_USER_LIST:
      return {
        ...state,
        blockedUsersList: state.blockedUsersList,
      };

    case types.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
