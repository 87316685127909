import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/core";
import countriesData from 'countries-list';
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Stack,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { URL } from "Constants/routes";
import _ from "lodash";
import { CircularProgress } from "@mui/material";
import { Auth } from "aws-amplify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import instance, {
  fetchGetStreamToken,
  fetchUserProfile,
  getCodeType,
  getConsumerPrivacityUrl,
  getConsumerTermsUrl,
  getLegalAdviceUrl,
  getIsPrivateBeta,
} from "api";
// third party
import * as Yup from "yup";
import { formatRegisterErrorMessage } from "./utils";
//import { trackCompletedRegister, trackRegistrationStart, trackUseInvitation } from '../../../utils/tracks'
import { Formik } from "formik";

// project imports
import useScriptRef from "hooks/useScriptRef";
//import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from "ui-component/extended/AnimateButton";
import { strengthIndicator } from "utils/password-strength";

// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
// ===========================|| FIREBASE - REGISTER ||=========================== //
import { useLocation } from "react-router-dom";
import { VALIDATE_CODE_TYPE } from "config/constants";

import value from 'Assets/scss/_theme-vars.module.scss';
import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';

const FirebaseRegister = (state) => {
  const location = useLocation();
  const { t } = useTranslation();
  // console.log(location.state.fromLogin);

  const theme = useTheme();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const customization = useSelector((state) => state.customization);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [ageChecked, setAgeChecked] = useState(false);
  const [isLoginError, setLoginError] = useState("");
  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();
  const [codeResent, setCodeResent] = useState(false);
  const [open, setOpen] = useState(false);

  const countryOptions = Object.keys(countriesData.countries).map((countryCode) => {
    const country = countriesData.countries[countryCode];
    return (
      <MenuItem id={countryCode} key={countryCode} value={countryCode}>
        {country.emoji} {country.name}
      </MenuItem>
    );
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const googleHandler = async () => {
    console.error("Register");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const strengthColor = (count) => {
    if (count < 2) return { label: TextFormat.uppercaseFirstLetter(t("poor")), color: value.errorMain };
    if (count < 3) return { label: TextFormat.uppercaseFirstLetter(t("weak")), color: value.warningDark };
    if (count < 4) return { label: TextFormat.uppercaseFirstLetter(t("normal")), color: value.orangeMain };
    if (count < 5) return { label: TextFormat.uppercaseFirstLetter(t("good")), color: value.successMain };
    if (count < 6) return { label: TextFormat.uppercaseFirstLetter(t("strong")), color: value.successDark };
    return { label: TextFormat.uppercaseFirstLetter(t("poor")), color: value.errorMain }; 
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword("123456");
  }, []);

  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState("");

  const [codeSent, setCodeSent] = useState(false);

  const onCheckCodeType = async (data) => {
    const { referralCode } = data;

    if (referralCode) {
      setLoading(true);
      try {
        const referralResponse = await getCodeType(referralCode);
        // trackUseInvitation(code);
        onSubmit(data, referralResponse.type);
      } catch (error) {
        alert(TextFormat.uppercaseAllFirstsLetters(t("invalidcode")));
        setLoading(false);
      } finally {
      }
    } else {
      onSubmit(data);
    }
  };

  const onSubmit = async (data, cType) => {
    const urlParams = new URLSearchParams(window.location.search);
    const isPromise = urlParams.get("isPromise");
    const stripeId = urlParams.get("stripeId");
  
    const {
      username,
      password,
      name,
      //  nickname,
      birthdate,
      country,
      email,
      code,
    } = data;

    try {
      setLoading(true);

      let birthdateDate = new Date(birthdate);

      let year = birthdateDate.getUTCFullYear();
      let month = String(birthdateDate.getUTCMonth() + 1).padStart(2, "0");
      let day = String(birthdateDate.getUTCDate()).padStart(2, "0");

      let birthdateDateString = `${year}-${month}-${day}`;

      let registerData = {
        username: email,
        password: password,
        attributes: {
          'name': name,
          'nickname': username,
          'birthdate': birthdateDateString,
          'custom:location': country
        },
      };

      let hasCode = code ? true : false;
      let referralCodeType = "";
      if (code) {
        if (cType === VALIDATE_CODE_TYPE.REFERRAL) {
          referralCodeType = "referred_by_code";
          registerData.attributes["custom:referred_by_code"] = code;
        } else if (cType === VALIDATE_CODE_TYPE.INVITED) {
          referralCodeType = "invited_by_code";
          registerData.attributes["custom:invited_by_code"] = code;
        } else {
          referralCodeType = "referred_by_code";
          registerData.attributes["custom:referred_by_code"] = code;
        }
      }
  
      if (isPromise && stripeId) {
        registerData.attributes["custom:is_promise"] = isPromise;
        registerData.attributes["custom:stripe_trans_id"] = stripeId;
      }
  
      registerData = {
        ...registerData,
      };
  
      const userRes = await Auth.signUp(registerData);
      console.log("userRes", userRes);
      setLoginError("");
      setCodeSent(true);
      setEmails(email);
      // const userId = _.get(userRes, 'userSub')
      // const email = _.get(userRes, 'user.username')
      // Mixpanel.createAlias(userId)
      // Mixpanel.setOnce({ $email: email, Created: new Date().toISOString() })
      // trackCompletedRegister(hasCode, referralCodeType, referralCode || '')

      // Alert.alert(translate('alerts.great'), translate('alerts.registerSucceed'))
      // onStateChange('confirmSignUp', { ...authData, username, password })
    } catch (error) {
      const errorMessage = _.get(error, "message", "");
      const key = formatRegisterErrorMessage(errorMessage);
      setLoginError(TextFormat.uppercaseFirstLetter(t(key)));
    } finally {
      setLoading(false);
    }
  };

  const _onVerify = async (code) => {
    try {
      setLoading(true);
      await Auth.confirmSignUp(emails, code);
      navigate("/");
    } catch (error) {
      setLoginError(TextFormat.uppercaseFirstLetter(t("expiredcode")));
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerificationCode = async () => {
    try {
      const response = await Auth.resendSignUp(emails);
      setCodeResent(true);
      setTimeout(() => {
        setCodeResent(false);
      }, 3000);
    } catch (error) {
      console.log("on Resend Token", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEmails();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{TextFormat.uppercaseFirstLetter(t("warning"))}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {TextFormat.uppercaseFirstLetter(t("accepttearmconditions"))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {TextFormat.uppercaseFirstLetter(t("ok"))}
          </Button>
        </DialogActions>
      </Dialog>

      {!codeSent && !location?.state?.fromLogin ? (
        <>
          <Grid item xs={12}>
            <Grid
              container
              direction={matchDownSM ? "column-reverse" : "row"}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                  <Typography
                    color={theme.palette.secondary.main}
                    gutterBottom
                    variant={matchDownSM ? "h3" : "h2"}
                  >
                    {TextFormat.uppercaseFirstLetter(t("signup"))}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontSize="16px"
                    textAlign={matchDownSM ? "center" : "inherit"}
                  >
                    {TextFormat.uppercaseFirstLetter(t("entercredentials"))}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {isLoginError ? (
            <Alert severity="error">
              <AlertTitle>{TextFormat.uppercaseFirstLetter(t("error"))}</AlertTitle>
              {isLoginError}
            </Alert>
          ) : undefined}
          <Formik
            initialValues={{
              username: "",
              name: "",
              email: "",
              birthdate: null,
              country: "",
              password: "",
              code: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(TextFormat.uppercaseFirstLetter(t("usernamerequired"))),
              name: Yup.string().required(TextFormat.uppercaseFirstLetter(t("namerequired"))),
              birthdate: Yup.date()
              .nullable()
              .required(TextFormat.uppercaseFirstLetter(t("birthdaterequired")))
              .test('birthdate', TextFormat.uppercaseFirstLetter(t("birthdateAge")), value => {
                if (!value) {
                  return false;
                }

                let selectedDate = new Date(value);
                let currentDate = new Date();
                let age = currentDate.getFullYear() - selectedDate.getFullYear();
                let monthDiff = currentDate.getMonth() - selectedDate.getMonth();

                if ( monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < selectedDate.getDate()) ) {
                  age--;
                }

                return age >16;
              }),
              country: Yup.string().required(TextFormat.uppercaseFirstLetter(t("countryrequired"))),
              email: Yup.string()
                .email(TextFormat.uppercaseFirstLetter(t("validemail")))
                .max(255)
                .required(TextFormat.uppercaseFirstLetter(t("emailrequired"))),
              password: Yup.string()
                .max(255)
                .required(TextFormat.uppercaseFirstLetter(t("passwordrequired")))
                .min(8, TextFormat.uppercaseFirstLetter(t("passwordshort")))
                .matches(
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@=$%^&+*-]).{8,}$/,
                  TextFormat.uppercaseFirstLetter(t("passwordmust"))
                ),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (!ageChecked || !checked) {
                  setOpen(!open);
                  return true;
                }
                onCheckCodeType(values);
              } catch (err) {
                //   if (scriptedRef.current) {
                //   console.log("values",values);
                //     setStatus({ success: true });
                //     setSubmitting(false);
                //   }
                // }
                //   console.error(err);
                //   if (scriptedRef.current) {
                //     setStatus({ success: false });
                //     setErrors({ submit: err.message });
                //     setSubmitting(false);
              }

              //   }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={matchDownSM ? 0 : 2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.username && errors.username)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-username-register">
                        {TextFormat.uppercaseFirstLetter(t("username"))}
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-username-register"
                        type="username"
                        value={values.username}
                        name="username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.username && errors.username && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.username}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-name-register">
                        {TextFormat.uppercaseFirstLetter(t("name"))}
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-name-register"
                        type="name"
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.name && errors.name && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text--register"
                        >
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-register">
                    {TextFormat.uppercaseFirstLetter(t("emailaddress"))}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-register"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text--register"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth
                             sx={{ ...theme.typography.customDate }}
                             error={Boolean(touched.birthdate && errors.birthdate)}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="outlined-adornment-birthdate-register"
                      label={TextFormat.uppercaseFirstLetter(t("birthdate"))}
                      name="birthdate"
                      type="birthdate"
                      inputFormat="DD/MM/YYYY"
                      disableFuture
                      value={values.birthdate}
                      onChange={(e) => {
                        setFieldValue("birthdate", e);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {touched.birthdate && errors.birthdate && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                      >
                        {errors.birthdate}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                </FormControl>

                <FormControl fullWidth
                              sx={{ ...theme.typography.customSelect }}
                              error={Boolean(touched.country && errors.country)}>
                  <InputLabel id="outlined-adornment-country-register">
                    {TextFormat.uppercaseFirstLetter(t("country"))}
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-country-register"
                    id="outlined-adornment-country-register"
                    value={values.country}
                    name="country"
                    type="country"
                    onChange={handleChange}
                  >
                    {countryOptions}
                  </Select>
                </FormControl>
                {touched.country && errors.country && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {errors.country}
                  </FormHelperText>
                )}

                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-password-register">
                    {TextFormat.uppercaseFirstLetter(t("password"))}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-register"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    label={TextFormat.uppercaseFirstLetter(t("password"))}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      changePassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-register"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>

                {strength !== 0 && (
                  <FormControl fullWidth>
                    <Box sx={{ mb: 2 }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Box
                            style={{ backgroundColor: level?.color }}
                            sx={{ width: 85, height: 8, borderRadius: "7px" }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" fontSize="0.75rem">
                            {level?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </FormControl>
                )}

                <FormControl
                  fullWidth
                  // error={Boolean(touched.password && errors.password)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-code-register">
                    {TextFormat.uppercaseFirstLetter(t("referralcode")) + " (" + t("optional") + ")" }
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-code-register"
                    // type={showPassword ? "text" : "password"}
                    value={values.referralCode}
                    name="referralCode"
                    label={TextFormat.uppercaseFirstLetter(t("referralcode"))}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.code && errors.code && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-code-register"
                    >
                      {errors.code}
                    </FormHelperText>
                  )}
                </FormControl>

                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(event) => setChecked(event.target.checked)}
                          name="checked"
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="subtitle1">
                          {TextFormat.uppercaseFirstLetter(t("terms1"))} &nbsp;
                          <Typography
                            variant="subtitle1"
                            component={Link}
                            onClick={() =>
                              window.open(
                                "https://bethey.com/privacy-consumers",
                                "_blank"
                              )
                            }
                          >
                            {t("privacypolicy")}
                          </Typography>
                          {t("terms2")} &nbsp;
                          <Typography
                            variant="subtitle1"
                            component={Link}
                            onClick={() =>
                              window.open(
                                "https://bethey.com/terms-and-conditions-consumers",
                                "_blank"
                              )
                            }
                          >
                            {t("termsconditions")}
                          </Typography>
                          {t("terms3")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ageChecked}
                          onChange={(event) =>
                            setAgeChecked(event.target.checked)
                          }
                          name="ageChecked"
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="subtitle1">{TextFormat.uppercaseFirstLetter(t("im16"))}</Typography>
                      }
                    />
                  </Grid>
                </Grid>

                <Typography
                  variant="subtitle1"
                  textAlign={"center"}
                  ml="40%"
                  component={Link}
                  onClick={() =>
                    window.open("https://bethey.com/legal", "_blank")
                  }
                >
                  {TextFormat.uppercaseFirstLetter(t("legalnotice"))}
                </Typography>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      {loading ? (
                        <CircularProgress color="warning" size={20} />
                      ) : (
                        TextFormat.uppercaseAllFirstsLetters(t("signin"))
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid
              container
              direction={matchDownSM ? "column-reverse" : "row"}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                  <Typography
                    color={theme.palette.secondary.main}
                    gutterBottom
                    variant={matchDownSM ? "h3" : "h2"}
                  >
                    {TextFormat.uppercaseFirstLetter(t("code"))}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontSize="16px"
                    textAlign={matchDownSM ? "center" : "inherit"}
                  >
                    {TextFormat.uppercaseFirstLetter(t("emailsent"))}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {isLoginError ? (
            <Alert severity="error">
              <AlertTitle>{TextFormat.uppercaseFirstLetter(t("error"))}</AlertTitle>
              {isLoginError}
            </Alert>
          ) : undefined}
          {/* {codeResent ? (
            <Alert severity="error">
              <AlertTitle>Success</AlertTitle>
              Code resent to your email!
            </Alert>
          ) : undefined} */}

          <Formik
            initialValues={{
              code: "",
              email: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              code: Yup.string(TextFormat.uppercaseFirstLetter(t("validstring")))
                .max(8)
                .required(TextFormat.uppercaseFirstLetter(t("coderequired"))),
              password: Yup.string().max(255).required(TextFormat.uppercaseFirstLetter(t("passwordrequired"))),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              _onVerify(values.code);
              // onSubmitPassword(values.code, values.password);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-password-login">
                    {TextFormat.uppercaseFirstLetter(t("emailaddress"))}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-login"
                    value={emails}
                    name="password"
                    onBlur={handleBlur}
                    disabled
                    label={TextFormat.uppercaseFirstLetter(t("emailaddress"))}
                    inputProps={{}}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(touched.code && errors.code)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-login">
                    {TextFormat.uppercaseFirstLetter(t("code"))}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="code"
                    value={values.code}
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={TextFormat.uppercaseFirstLetter(t("code"))}
                    inputProps={{}}
                  />
                  {touched.code && errors.code && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.code}
                    </FormHelperText>
                  )}
                </FormControl>

                <div
                  style={{
                    marginTop: 10,
                    cursor: "pointer",
                    textDecorationLine: "underline",
                  }}
                  onClick={() => handleResendVerificationCode()}
                >
                  <Typography variant="subtitle1">{TextFormat.uppercaseFirstLetter(t("resendcode"))}</Typography>
                </div>
                <Box sx={{ mt: 2 }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      {loading ? (
                        <CircularProgress color="warning" size={20} />
                      ) : (
                        TextFormat.uppercaseFirstLetter(t("accept"))
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default FirebaseRegister;
