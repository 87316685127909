import { connect } from 'react-redux'
import { feedsActions } from 'redux/feeds'
import { notificationsActions } from 'redux/notifications'
import { errorActions } from 'redux/error'
import { videosActions, videosOperations } from 'redux/videos'
import { feedsOperations } from 'redux/feeds'

import Home from './Home'

const mapStateToProps = state => {
  return {
    myProfile: state.auth.profile,
    me: state.auth.user,
    isFetching: state.auth.isFetching,
    unmutedVideo: state.videos.unmutedVideo,
    scrollEnabled: state.feeds.scrollEnabled,
    initialCardsLoad: state.feeds.initialCardsLoad,
    initialNotificationInfo: state.notifications.initialNotificationInfo,
    videoToShow: state.videos.videoToShow,
    profileToShow: state.videos.profileToShow,
    haveNetwork: state.notifications.haveNetwork,
    videosToDiscover: state.videos.videosToDiscover,
    visibleCards: state.feeds.visibleCards,
    showNetworkErrorModal: state.error.showNetworkErrorModal,
    acceptedTerms: state.auth?.profile?.termsAndConditions || false,
    startDiscover: state.videos.startDiscover,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFeedRef: ref => dispatch(feedsActions.setFeedRef('timelineRef', ref)),
    setVisibleCards: cards => dispatch(feedsActions.setVisibleCards(cards)),
    resetNotification: () => dispatch(notificationsActions.resetNotification()),
    setStartDiscover: value => dispatch(videosActions.startDiscover(value)),
    setVideoLoadStartTime: value => dispatch(videosActions.setVideoLoadStartTime(value)),
    setVideoToShow: value => dispatch(videosActions.setVideoToShow(value)),

    setProfileToShow: value => dispatch(videosActions.setProfileToShow(value)),

    fetchSuggestedList: () => dispatch(videosOperations.fetchSuggestedLists()),
    refreshFeeds: () => dispatch(feedsOperations.refreshFeeds()),
    getVideosToDiscover: (page, limit) => dispatch(videosOperations.fetchVideosToDiscovered(page, limit)),
    setShowNetworkErrorModal: bool => dispatch(errorActions.setShowNetworkErrorModal(bool)),
    onAddReaction: (event, activityId) => dispatch(videosOperations.fetchAddReaction(activityId, event)),
    onToggleReaction: (event, activityId) => dispatch(videosOperations.fetchToggleReaction(activityId, event)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
