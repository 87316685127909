import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useFeedContext } from 'react-activity-feed';
import { MentionsInput, Mention } from 'react-mentions';
import { fetchUsers } from "api";
import defaultStyle from './defaultStyle'

const CustomCommentField = ({ onSuccess, activity, placeholder, defaultMention }) => {
  const feed = useFeedContext();
  const [text, setText] = useState('');
  const [usersData, setUsersData] = useState([]);
  const [menciones, setMenciones] = useState([]);
  const [activityToResponse, setActivityToResponse] = useState(null);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (defaultMention) {
      setActivityToResponse(defaultMention.comment)
      setText(`@[${defaultMention.name}](${defaultMention.id})`)
    } else {
      setText('')
    }
  }, [defaultMention])

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!text) return;
    try {
      // Guardará los usuarios a notificar
      let targetFeeds = [];

      // Agregamos la notificacion del video comentado si no somos el autor
      if (user.attributes.sub !== activity.actor.data.username) {
        targetFeeds = [`notification:${activity.actor.id}`];
      }

      // Buscamos los usuarios en el texto del comentario
      let usuariosMencionados = getUsersFromText(text);

      // Agregamos los usuairos del texto si existen
      if (usuariosMencionados) {
        usuariosMencionados.forEach(mentionUser => {
          if (mentionUser.id === user.attributes.sub) {
            return;
          }
          targetFeeds.push(`notification:${mentionUser.id}`);
        });
      }

      // En el caso de ser una respuesta a un comentario
      if (defaultMention) {
        // Agregamos la notificación en el caso de no ser nosotros mismos. 
        if (user.attributes.nickname !== activityToResponse.user.data.nickname) {
          targetFeeds.push(`notification:${activityToResponse.user.id}`);
        }

        await feed.onAddChildReaction('comment', activityToResponse, { text }, { targetFeeds });
      } else {
        await feed.onAddReaction('comment', activity, { text }, { targetFeeds });
      }
    } catch (error) {
      console.error(error);
    }
    setText('');
    onSuccess?.();
  };

  const getUsersFromText = (text) => {
    if (!text) {
      return;
    }

    const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const usuarios = [];

    let match;
    while ((match = regex.exec(text)) !== null) {
      const usuario = {
        name: match[1],
        id: match[2],
      };
      usuarios.push(usuario);
    }

    return usuarios;
  };

  // Detectar cambios en el imput y busca usuarios con el @
  const handleChange = (event) => {
    setText(event.target.value);

    const regex = /@(\w+)/g;
    const newMenciones = [];
    let match;

    while ((match = regex.exec(event.target.value)) !== null) {
      newMenciones.push(match[1]);
    }

    let compare = mentionsComparation(newMenciones, menciones);
    if (compare) {
      fetchUsers(compare).then((response) => {

        const mapRes = response.map(item => ({
          id: item.uuid,
          display: item.nickname
        }));

        setUsersData(mapRes);
        setMenciones(newMenciones);
      }).catch((error) => {
        console.log("error", error);
      });
    } else {
      setUsersData([]);
      setMenciones(newMenciones);
    }
  };

  function mentionsComparation(array1, array2) {
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    const diferencia = [...set1].filter(x => !set2.has(x));
    return diferencia.length > 0 ? diferencia[0] : null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px', backgroundColor: 'white' }}>
      <MentionsInput
        value={text}
        onChange={handleChange}
        placeholder={placeholder}
        style={{ ...defaultStyle, flex: 1 }}
        multiLine={true}
      >
        <Mention trigger="@" data={usersData} />
      </MentionsInput>
      <button
        disabled={!text}
        onClick={handleFormSubmit}
        style={{ cursor: 'pointer', backgroundColor: 'white', color: 'black', border: 'none', borderRadius: '5px', padding: '10px' }}
      >
        Enviar
      </button>
    </div>
  );
};

export default CustomCommentField;
