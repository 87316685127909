import { useState, useEffect, useRef } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Stack,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import SnackBar from "ui-component/SnackBar";
// assets
import { useTranslation } from 'react-i18next';
import { fetchMgmEarnings, fetchStats } from "api";
import _ from "lodash";
import { NumericFormat } from "react-number-format";

const Billing = ({ ...others }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const [freeVisualizations, setFreeVisualizations] = useState(null);
  const [totalVideoIncome, setTotalVideoIncome] = useState(null);
  const [stats, setStats] = useState([]);

  const snackBarRef = useRef();

  useEffect(() => {
    fetchMgmEarningsCall();
    fetchStatsCall();
  }, [])

  const fetchMgmEarningsCall = () => {
    fetchMgmEarnings().then((response) => {
      const newAmount = _.toNumber(_.get(response, 'totalEarnings', 0)) / 100
      const newFreeVisualizationsAmount = _.toNumber(_.get(response, 'freeVisualizationsEarnings', 0))
      setFreeVisualizations(newFreeVisualizationsAmount)
      setTotalVideoIncome(newFreeVisualizationsAmount + newAmount)
    }).catch((error) => {
      console.log("error", error)
    })
  };
  const fetchStatsCall = () => {
    fetchStats().then((response) => {
      setStats(response.stats)
    }).catch((error) => {
      console.log("error", error)
    })
  };

  return (
    <div style={{ zIndex: -1, padding: 20 }}>
      <SnackBar ref={snackBarRef} />
      <>
        <Grid item sx={{ mt: 5 }} xs={12}>
          <Grid
            container
            direction={matchDownSM ? "column-reverse" : "row"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <Typography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  variant={matchDownSM ? "h3" : "h2"}
                >
                  {t('billingTab')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          {/* Ingresos acumulados start */}
          <Grid
            sx={{ mt: 5 }}
            container
            direction={matchDownSM ? "column-reverse" : "row"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <Typography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  fontSize="18px"
                >
                  {t('totalIncome')}
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="18px"
                  textAlign={matchDownSM ? "center" : "inherit"}
                >
                  {t('startBethey')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            sx={{ mt: 5 }}
            container
            direction={matchDownSM ? "column-reverse" : "row"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                {
                  totalVideoIncome !== null ? <NumericFormat
                    value={totalVideoIncome}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    prefix={'+'}
                    suffix={'€'}
                    displayType={'text'}
                    renderText={value => <Typography fontSize="18px">{value}</Typography>}
                  /> : '-'
                }

                <Typography
                  variant="caption"
                  fontSize="18px"
                  textAlign={matchDownSM ? "center" : "inherit"}
                >
                  Total
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            sx={{ mt: 5 }}
            container
            direction={matchDownSM ? "column-reverse" : "row"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                {
                  freeVisualizations !== null ? <NumericFormat
                    value={freeVisualizations}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    prefix={'+'}
                    suffix={'€'}
                    displayType={'text'}
                    renderText={value => <Typography fontSize="18px">{value}</Typography>}
                  /> : '-'
                }
                <Typography
                  variant="caption"
                  fontSize="18px"
                  textAlign={matchDownSM ? "center" : "inherit"}
                >
                  {t('freeVideos')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          {/* Ingresos acumulados end */}
          {/* Evolucion ingresos start */}
          <Grid
            sx={{ mt: 5 }}
            container
            direction={matchDownSM ? "column-reverse" : "row"}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <Typography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  fontSize="18px"
                >
                  {t('historicTitle')}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          {stats.length ? stats.map((stat, index) => {
            const earning = _.toNumber(_.get(stat, 'earnings', 0)) / 100
            const days = stat.days === 1 ? t('yesterdayTab') : `${t('lastXdays1')} ${stat.days} ${t('lastXdays2')}`
            return (
              <Grid
                item
                key={index}
                sx={{ mt: 5 }}
                container
                direction={matchDownSM ? "column-reverse" : "row"}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Stack alignItems="center" justifyContent="center" spacing={1}>
                    <NumericFormat
                      value={earning}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={2}
                      prefix={'+'}
                      suffix={'€'}
                      displayType={'text'}
                      renderText={value => <Typography fontSize="20px">{value}</Typography>}
                    />
                    <Typography
                      variant="caption"
                      fontSize="18px"
                      textAlign={matchDownSM ? "center" : "inherit"}
                    >
                      {days}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )
          }) : null}
        </Grid>
        {/* Evolucion ingresos end */}

      </>
    </div>
  );
};

export default Billing;
