// import { TRACKS } from "../config/constants";
// import { checkIsPublisher } from "../utils";
// import Mixpanel from "react-native-mixpanel";
// import { Platform } from "react-native";
// import _ from "lodash";
// import appsFlyer from "react-native-appsflyer";
import { store } from "../store";

// const Platform = "web";

export const configAppsFlyer = (username) => {
  // appsFlyer.initSdk(
  //   {
  //     devKey: MIX_PANEL_CONF.DEV_KEY,
  //     isDebug: true,
  //     appId: MIX_PANEL_CONF.APP_ID,
  //     onInstallConversionDataListener: true, //Optional
  //     onDeepLinkListener: true, //Optional
  //     timeToWaitForATTUserAuthorization:
  //       MIX_PANEL_CONF.TIME_TO_WAIT_FOR_ATT_USER_AUTHORIZATION, //for iOS 14.5
  //   },
  //   (result) => {
  //     if (username) {
  //       appsFlyer.setCustomerUserId(username, (response) => {});
  //     }
  //   },
  //   (error) => {}
  // );
};

export const setSuperProperties = () => {
  const { user } = store.getState().auth;
  // let propsToSend = { os: Platform.OS };
  if (user) {
    // const isPublisher = checkIsPublisher(user);
    // propsToSend = {
    //   ...propsToSend,
    //   username: user.username,
    //   userType: isPublisher
    //     ? TRACKS.USER_TYPE_VALUES.CREATOR
    //     : TRACKS.USER_TYPE_VALUES.USER,
    //   Created: new Date().toISOString(),
    // };
  }
  // Mixpanel.registerSuperProperties(propsToSend);
};

export const trackUserType = () => {
  const { user } = store.getState().auth;
  if (user) {
    // const isPublisher = checkIsPublisher(user);
    // const userId = _.get(user, "username");
    // Mixpanel.identify(userId);
    // Mixpanel.set({
    //   userType: isPublisher
    //     ? TRACKS.USER_TYPE_VALUES.CREATOR
    //     : TRACKS.USER_TYPE_VALUES.USER,
    // });
  }
};

export const trackCompletedRegister = (hasCode, codeType, referralCode) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.REGISTRATION_COMPLETE, {
  //   hasCode: hasCode,
  //   codeType: codeType,
  //   referralCode: referralCode,
  // });
};

export const trackSuccessCode = (code) => {
  // const { user } = store.getState().auth;
  // // Mixpanel.trackWithProperties(TRACKS.EVENTS.REGISTRATION_SUCCESS_CODE, {
  // //   code: code,
  // // });
  // const eventName = TRACKS.EVENTS.APPSFLYER.REGISTRATION_COMPLETE;
  // const eventValues = { af_userneme: user?.username };
  // appsFlyer.logEvent(eventName, eventValues);
};

export const trackRegistrationStart = () => {
  // Mixpanel.track(TRACKS.EVENTS.REGISTRATION_START);
  // const eventName = TRACKS.EVENTS.APPSFLYER.REGISTRATION_START;
  // const eventValues = {};
  // appsFlyer.logEvent(eventName, eventValues);
};

export const trackShowVideo = (videoId) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.SHOW_VIDEO, { videoId: videoId });
};

export const trackPurchase = (purchaseInfo) => {
  // const { user } = store.getState().auth;
  // const aux = purchaseInfo.title.match(/(\d+)/);
  // let totalVideos = 0;
  // if (aux && Array.isArray(aux) && aux.length > 0) {
  //   totalVideos = aux[0];
  // }
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.PURCHASE, {
  //   description: purchaseInfo.title,
  //   amount: purchaseInfo.localizedPrice,
  //   totalVideos: totalVideos,
  // });
  // const eventName = TRACKS.EVENTS.APPSFLYER.PURCHASE;
  // const eventValues = {
  //   af_userneme: user?.username,
  //   af_bundle: purchaseInfo.title,
  //   af_amount: purchaseInfo.localizedPrice,
  //   af_total_videos: totalVideos,
  // };
  // appsFlyer.logEvent(eventName, eventValues);
};

export const trackPurchaseProcessStarted = () => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.PURCHASE_STARTED, {
  //   os: Platform.OS,
  // });
};

export const trackPurchaseProcessFailure = () => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.PURCHASE_FAILURE, {
  //   os: Platform.OS,
  // });
};

export const trackTryPurchase = () => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.TRY_PURCHASE, { os: Platform.OS });
};

export const trackFollow = (who) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.FOLLOW, { followed: who });
};

export const trackCreateVideo = (video, fromGallery) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.CREATE_VIDEO, {
  //   uri: video.uri,
  //   source: fromGallery ? "gallery" : "record",
  // });
};

export const trackPublishVideo = (video, data, videoInfo) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.PUBLISH_VIDEO, {
  //   uri: video.uri,
  //   categoryId: data.categoryId,
  //   publicationDate: data.publicationDate,
  //   status: data.status,
  //   title: data.title,
  //   videoId: videoInfo?.id?.toString() ?? "",
  //   originalName: videoInfo?.originalName ?? "",
  //   videoOwner: videoInfo?.owner?.nickname ?? "",
  //   ownerId: videoInfo?.owner?.id?.toString() ?? "",
  // });
};

export const trackAddVideoInfo = (video, data, videoInfo) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.ADD_VIDEO_INFO, {
  //   uri: video.uri,
  //   categoryId: data.categoryId,
  //   publicationDate: data.publicationDate,
  //   status: data.status,
  //   title: videoInfo.title,
  //   videoId: videoInfo?.id?.toString() ?? "",
  // });
};

export const trackUploadVideo = (video, videoUrl, videoInfo) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.UPLOAD_VIDEO, {
  //   uri: video.uri,
  //   videoUrl: videoUrl,
  //   videoId: videoInfo?.id?.toString() ?? "",
  //   title: videoInfo?.title,
  // });
};

export const trackUploadErrorVideo = (type) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.UPLOAD_ERROR_VIDEO, {
  //   type,
  //   origin: "app",
  // });
};

export const trackPreviewVideo = (video) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.PREVIEW_VIDEO, {
  //   videoId: video?.object || "",
  //   id: video?.id || "",
  //   ownerId: video?.actor?.id || "",
  //   videoOwner: video?.actor?.data?.nickname || "",
  //   title: video?.title || "",
  // });
};

export const trackShare = (video) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.SHARE_VIDEO, {
  //   videoId: video?.object || "",
  //   id: video?.id || "",
  //   ownerId: video?.actor?.id || "",
  //   videoOwner: video?.actor?.data?.nickname || "",
  //   title: video?.title || "",
  // });
};

export const trackVideoLike = (video) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.LIKE_VIDEO, {
  //   videoId: video?.object || "",
  //   id: video?.id || "",
  //   ownerId: video?.actor?.id || "",
  //   videoOwner: video?.actor?.data?.nickname || "",
  //   title: video?.title || "",
  // });
};

export const trackVideoPurchase = (video, fromDiscover) => {
  // const videoId = fromDiscover
  //   ? _.get(video, "id").toString()
  //   : _.get(video, "object");
  // const activityId = fromDiscover
  //   ? _.get(video, "streamActivityId")
  //   : _.get(video, "id");
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.PURCHASE_VIDEO, {
  //   videoId: videoId || "",
  //   id: activityId || "",
  //   ownerId: video?.actor?.id || "",
  //   videoOwner: video?.actor?.data?.nickname || "",
  //   title: video?.title || "",
  //   Created: new Date().toISOString(),
  // });
};

export const trackVideoComment = (activityId, videoId) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.COMMENT_VIDEO, {
  //   id: activityId,
  //   videoId: videoId,
  //   Created: new Date().toISOString(),
  // });
};

export const trackPlayVideo = (videoId) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.PLAY_VIDEO, {
  //   videoId: videoId,
  //   Created: new Date().toISOString(),
  // });
};

export const trackInvitations = () => {
  // Mixpanel.track(TRACKS.EVENTS.SEND_INVITATION);
};

export const trackUseInvitation = (code) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.USE_INVITATION, { code: code });
};

export const trackLoadTime = (value) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.LOAD_TIME, { loadTime: value });
};

export const trackLoadVideoTime = (value) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.VIDEO_LOAD_TIME, {
  //   loadTime: value,
  // });
};

export const trackPurchaseAndSeeVideo = (value) => {
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.FROM_START_PURCHASE_TO_SEE_VIDEO, {
  //   loadTime: value,
  // });
};

export const trackInAppRequestPurchase = (productId, beneficiary) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.REQUEST_PURCHASE, {
  //   productId: productId,
  //   beneficiary: beneficiary,
  //   buyer: username,
  //   date: new Date().toISOString(),
  //   buyerNickname: nickname,
  //   step: "1",
  //   os: Platform.OS,
  // });
};

export const trackInAppRequestPurchaseSuccess = (productId, beneficiary) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.REQUEST_PURCHASE_SUCCESS, {
  //   productId: productId,
  //   beneficiary: beneficiary,
  //   buyer: username,
  //   date: new Date().toISOString(),
  //   buyerNickname: nickname,
  //   step: "1-success",
  //   os: Platform.OS,
  // });
};

export const trackInAppRequestPurchaseError = (productId, beneficiary) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.REQUEST_PURCHASE_ERROR, {
  //   productId: productId,
  //   beneficiary: beneficiary,
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "1-Error",
  //   os: Platform.OS,
  // });
};

export const trackInAppPurchaseUpdated = (purchase) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.PURCHASE_UPDATED, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "2",
  //   os: Platform.OS,
  // });
};

export const trackInAppPurchaseError = (error) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.PURCHASE_ERROR, {
  //   errorCode: error?.code || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "2-Error",
  //   os: Platform.OS,
  // });
};

export const trackInappAttemptPurchaseBundle = (purchase, beneficiary) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.ATTEMPT_BUNDLE_PURCHASE, {
  //   productId: purchase.productId,
  //   beneficiary: beneficiary,
  //   buyer: username,
  //   date: new Date().toISOString(),
  //   buyerNickname: nickname,
  //   step: "1",
  //   os: Platform.OS,
  // });
  // const eventName = TRACKS.EVENTS.APPSFLYER.PURCHASE_STARTED;
  // const eventValues = { af_userneme: user?.username };
  // appsFlyer.logEvent(eventName, eventValues);
};

export const trackInAppWillSubscribe = (productId, beneficiary) => {
  //app subscribes to purchases updates and errors
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.WILL_SUBSCRIBE, {
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "start",
  //   os: Platform.OS,
  // });
};

export const trackInAppWillUnsubscribeUpdates = (productId, beneficiary) => {
  //app subscribes to purchases updates and errors
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.WILL_UNSUBSCRIBE_UPDATE, {
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "last",
  //   os: Platform.OS,
  // });
};

export const trackInAppWillUnsubscribeError = (productId, beneficiary) => {
  //app subscribes to purchases updates and errors
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.WILL_UNSUBSCRIBE_ERROR, {
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "last-error",
  //   os: Platform.OS,
  // });
};

export const trackInAppNotReceipt = (purchase) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.NOT_RECEIPT, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "3-error",
  //   os: Platform.OS,
  // });
};

export const trackIappProcessStarted = (purchase) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.PROCESS_STARTED, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "4",
  //   os: Platform.OS,
  // });
};

export const trackIappInfoBackend = (purchase) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.INFO_BACKEND, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "5",
  //   os: Platform.OS,
  // });
};

export const trackIappInfoBackendSuccess = (purchase) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.INFO_BACKEND_SUCCESS, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "5",
  //   os: Platform.OS,
  // });
};

export const trackIappErrorApi = (purchase, error, strDetail = "") => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.ERROR_API, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "5-error",
  //   os: Platform.OS,
  //   error:
  //     error?.message + ". " + strDetail || error?.code + strDetail || "unknown",
  // });
};

export const trackIappFinishTransaction = (purchase) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.ERROR_FINISH_TRANSACTION, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "6",
  //   os: Platform.OS,
  // });
};

export const trackIappErrorOnFinishTransaction = (
  purchase,
  error,
  strDetail = ""
) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.ERROR_FINISH_TRANSACTION, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "6-error",
  //   os: Platform.OS,
  //   error:
  //     error?.message + ". " + strDetail || error?.code + strDetail || "unknown",
  // });
};

export const trackIappErrorFinish = (purchase, error) => {
  // const { user } = store.getState().auth;
  // const username = user?.username || "unknown";
  // const nickname = user?.attributes?.nickname || "unknown";
  // Mixpanel.trackWithProperties(TRACKS.EVENTS.IAPP.ERROR_FINISH, {
  //   productId: purchase?.productId || "unknown",
  //   transactionId: purchase?.transactionId || "unknown",
  //   transactionReceipt: purchase?.transactionReceipt?.orderId || "unknown",
  //   buyer: username,
  //   buyerNickname: nickname,
  //   date: new Date().toISOString(),
  //   step: "5-6-error",
  //   os: Platform.OS,
  //   error: error?.message || error?.code || "unknown",
  // });
};
