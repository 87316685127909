import { useTheme } from "@mui/material/styles";
import { PlainList } from "flatlist-react";
import SearchSection from "layout/MainLayout/Header/SearchSection";
import { RenderVideo } from "Pages/Home/HomeComponents/VideoView";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FollowedCard from "ui-component/cards/FollowedCard";
import Loading from "ui-component/Loading";
import { useTranslation } from 'react-i18next';
import { TextFormat } from "utils/textFormat";

import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { fetchSearchUsers, fetchSearchVideos } from "api";
import MainLayout from "layout/MainLayout";
import _ from "lodash";
import { usersActions } from "redux/users";
import { useDebounce } from "utils/commonHooks";
const DEBOUNCE_DELAY = 750;
function Search(props) {
  const { t } = useTranslation();
  const TimelineType = Object.freeze({
    Celebrities: TextFormat.uppercaseFirstLetter(t("creators")),
    Videos: TextFormat.uppercaseFirstLetter(t("videos")),
  });

  const [value, setValue] = useState("");
  const debounceFilterText = useDebounce(value, DEBOUNCE_DELAY);
  const theme = useTheme();

  //const [usersPage, setUsersPage] = React.useState(1);
  const [usersTotal, setUsersTotal] = React.useState(1);
  const [usersList, setUsersList] = React.useState([]);
  const [usersLoading, setUsersLoading] = React.useState(false);

  //    const [category, setCategory] = React.useState(props.category)
  const videosTotalPage = useRef(1);
  const videosPage = useRef(1);

  //
  const usersTotalPage = useRef(1);
  const usersPage = useRef(1);
  const celebritiesList = useSelector((state) => state.users.celebritiesList);
  console.log("celebritiesList", celebritiesList);
  const [videosTotal, setVideosTotal] = React.useState(1);
  const [videosList, setVideosList] = React.useState([]);
  const [videosLoading, setVideosLoading] = React.useState(false);
  const dispatch = useDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const listInnerRef = useRef();
  const [type, setType] = useState(TimelineType.Celebrities);

  const onScroll = function () {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (type === TimelineType.Videos) {
        onEndReached(videosList);
      } else {
        onEndReachedUser(usersList);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);
  const handleChangeTimelineType = async (tab) => {
    if (
      (tab === 0 && type === TimelineType.Celebrities) ||
      (tab === 1 && type === TimelineType.Videos)
    ) {
      return;
    }

    setType(tab === 1 ? TimelineType.Videos : TimelineType.Celebrities);

    // if (tab == 1) {
    //   await _getCelebritiesVideos();
    // }
  };

  const initVideosList = React.useCallback(() => {
    const newList = [];
    const newPage = 1;
    setVideosList(newList);
    videosPage.current = newPage;

    fetchVideosList(newList, newPage);
    setValue((prev) => "");
  }, []);

  const initUsersList = React.useCallback(() => {
    const newList = [];
    const newPage = 1;
    setUsersList(newList);
    usersPage.current = newPage;
    fetchUsersList(newList, newPage);
    setValue((prev) => "");
  }, []);

  //n end REach

  const onEndReached = (videosList) => {
    const loadNextVideoPage =
      videosPage.current < videosTotalPage.current &&
      !videosLoading &&
      videosList.length > 0;

    if (loadNextVideoPage) {
      const newPage = videosPage.current + 1;

      videosPage.current = newPage;

      fetchVideosList(videosList, newPage, "");
    }
  };

  const onEndReachedUser = (usersList) => {
    const loadNextVideoPage =
      usersPage.current < usersTotalPage.current &&
      !usersLoading &&
      usersList?.length > 0;

    if (loadNextVideoPage) {
      const newPage = usersPage.current + 1;

      usersPage.current = newPage;

      fetchUsersList(usersList, newPage, "");
    }
  };
  const fetchVideosList = React.useCallback(
    async (currentList, currentPage, searchTextInput) => {
      try {
        setVideosLoading(true);
        const params = { terms: searchTextInput, page: currentPage };
        const fetchSearchVideosRes = await fetchSearchVideos(params);

        const total = _.get(fetchSearchVideosRes, "pageCount", 1);
        const data = [
          ...currentList,
          ..._.get(fetchSearchVideosRes, "data", []),
        ];
        setVideosList(data);
        videosTotalPage.current = total;
        setVideosTotal(total);
      } catch (e) {
      } finally {
        setVideosLoading(false);
      }
    },
    [props]
  );

  const fetchUsersList = React.useCallback(
    async (currentList, currentPage, searchTextInput) => {
      try {
        setUsersLoading(true);
        const params = { terms: searchTextInput, page: currentPage };
        const fetchSearchUsersRes = await fetchSearchUsers(params);
        // console.log("fetchSearchUsersRes", fetchSearchUsersRes);
        // const fetchBlockedUsers = await getBlockedUsers()
        const total = _.get(fetchSearchUsersRes, "pageCount", 1);
        // let usersToShow = _.get(fetchSearchUsersRes, 'data', []);

        // fetchBlockedUsers.forEach( blocked => {
        //   usersToShow.forEach( user => {
        //     if(user.id == blocked.userBlockedId){
        //       user["blockedByUser"] = true
        //     }
        //   });
        // });

        // const data = [...currentList, ...usersToShow]
        const data = [
          ...currentList,
          ..._.get(fetchSearchUsersRes, "data", []),
        ];
        setUsersList(data);
        console.log("data", data);
        usersTotalPage.current = total;
        setUsersTotal(total);
        dispatch(usersActions.setCelebritiesList(data));
      } catch (e) {
        // props.customAlert(e, translate('errors.error'), _.get(e, 'message', translate('errors.request')))
      } finally {
        setUsersLoading(false);
      }
    },
    [props]
  );

  React.useEffect(() => {
    fetchVideosList([], 1, debounceFilterText);
  }, [fetchVideosList, debounceFilterText]);

  React.useEffect(() => {
    fetchUsersList([], 1, debounceFilterText);
  }, [debounceFilterText, fetchUsersList]);

  return (
    <MainLayout search={true}>
      <div
        style={{
          display: "flex",
          width: matchDownSM ? "100%" : "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <SearchSection search={true} value={value} setValue={setValue} />
            <Button
              variant="contained"
              sx={{
                marginLeft: 2,
                borderRadius: 10,
                padding: 1,
                width: matchDownSM ? "100px" : "120px",
              }}
              onClick={() => {
                initVideosList();
                initUsersList();
              }}
            >
              {t("reset")}
            </Button>
          </div>
          <Grid
            item
            xs={4}
            align="center"
            justify="center"
            alignItems="center"
            sx={{ display: "flex" }}
          >
            <div style={{ display: "flex", margin: "auto" }}>
              <Typography
                onClick={() => handleChangeTimelineType(0)}
                sx={{
                  display: "inline",
                  margin: "1rem",
                  cursor: "pointer",
                }}
                component="span"
                variant="h3"
                color={
                  type === TimelineType.Celebrities ? "primary" : "text.primary"
                }
              >
                {TimelineType.Celebrities}
              </Typography>

              <Divider
                sx={{
                  borderRightWidth: 2,
                  borderColor: "red",
                  marginY: 2,
                  height: "1.5rem",
                }}
                orientation="vertical"
                flexItem
              />

              <Typography
                onClick={() => handleChangeTimelineType(1)}
                sx={{
                  display: "inline",
                  margin: "1rem",
                  cursor: "pointer",
                }}
                component="span"
                variant="h3"
                color={
                  type === TimelineType.Videos ? "primary" : "text.primary"
                }
              >
                {TimelineType.Videos}
              </Typography>
            </div>
          </Grid>

          {type === TimelineType.Videos ? (
            <>
              <Grid container spacing={0} columns={{ xs: 2, sm: 8, md: 12 }}>
                <PlainList
                  renderOnScroll
                  list={videosList}
                  renderItem={(item) => (
                    <RenderVideo item={item} search={true} />
                  )}
                  renderWhenEmpty={() => null}
                />
              </Grid>
              {videosLoading ? (
                <Loading isLoading={true} />
              ) : !videosLoading && videosList.length === 0 ? (
                <Loading isEmpty={true} />
              ) : null}
            </>
          ) : (
            <>
              <Grid container spacing={0} columns={{ xs: 2, sm: 8, md: 12 }}>
                <PlainList
                  renderOnScroll
                  list={celebritiesList}
                  renderItem={(item, index) => (
                    <FollowedCard
                      showCurrentFollowing={true}
                      index={index}
                      item={item}
                      //isfollowerList={isfollowerList}
                    />
                  )}
                  renderWhenEmpty={() => null}
                />
              </Grid>
              {usersLoading ? (
                <Loading isLoading={true} />
              ) : !usersLoading && celebritiesList.length === 0 ? (
                <Loading isEmpty={true} />
              ) : null}
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
export default Search;
