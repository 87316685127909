import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { URL } from "./Constants/routes";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound/NotFound";
import Search from "./Pages/Search";


import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import instance, { configureToken, fetchUserProfile } from "api";
import { Amplify, Auth } from "aws-amplify";
import { _checkInitialUser } from "components/organisms/auth-checker/view";
import { STREAM_CONSTANTS } from "config/getstream";
import _ from "lodash";
import UserInfo from "Pages/Profile/UserInfo";
import { StreamApp } from "react-activity-feed";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { authActions, authOperations } from "redux/auth";
import { logout, setCurrentUserProfile } from "redux/auth/actions";
import { videosOperations } from "redux/videos";
import { store } from "store";
import Loading from "ui-component/Loading";
import { AWS_CONFIG } from "./config/aws";
import MainLayout from "./layout/MainLayout";
import Login from "./Pages/authentication/authentication3/Login";
import SiginUp from "./Pages/authentication/SiginUp";
import ForgotPassword from "./Pages/ForgotPassword";
import Profile from "./Pages/Profile";
import ChangePassword from "./Pages/Profile/ChangePassword";
import Billing from "./Pages/Profile/Billing";
import PublicProfile from "./Pages/PublicProfiles";
import ShowLegals from "./Pages/ShowLegals";
import Products from "Pages/Products";
import { getAvailableVideos } from "utils";


Amplify.configure(AWS_CONFIG);
Auth.configure({ oauth: AWS_CONFIG.Auth });

const App = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const isLoggedIn = useSelector(
    (state) => !!(state.auth.user && state.auth.profile)
  );
  const streamToken = useSelector((state) => state.auth.streamToken);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  const refreshMyToken = async () => {
    try {
      const signInUserSession = await Auth.currentSession();
      const token = _.get(signInUserSession, 'accessToken.jwtToken', null);
  
      if (token) {
        store.dispatch(authActions.setUserSession(signInUserSession));
        instance.defaults.headers.common.Authorization = `Bearer ${token}`;
        return Promise.resolve();
      } else {
        store.dispatch(authOperations.doLogout());
      }
    } catch (err) {
      console.error('Error refreshing token:', err);
    }
  };

  useEffect(() => {
    refreshMyToken();
    setToken();
  }, [isLoggedIn]);


  const _loadUserProfile = async (username) => {
    try {
      const userProfile = await fetchUserProfile(username);
      dispatch(setCurrentUserProfile(userProfile));
    } catch (error) {}
  };

  const setToken = async () => {
    try {
      if (isLoggedIn) {
        const signInUserSession = await Auth.currentSession();
        await dispatch(videosOperations.fetchSuggestedLists());
        _loadUserProfile(signInUserSession.getAccessToken().payload.username);
        await dispatch(videosOperations.fetchVideoCategories());
        const tokenUser = _.get(
          signInUserSession,
          "accessToken.jwtToken",
          null
        );
        await configureToken(tokenUser);
        await _checkInitialUser();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      dispatch(logout());
      setLoading(false);
    }
  };

  if (isLoading) return <Loading />;

  const ProtectedRoute = () => {
    if (!isLoggedIn) {
      return <Navigate to={URL.AUTH.LOGIN} replace />;
    }
    return (
      <>
        <StreamApp
          apiKey={STREAM_CONSTANTS.API_KEY}
          appId={STREAM_CONSTANTS.APP_ID}
          token={streamToken}
        >
          <MainLayout>
            <div
              style={{
                width:
                  matchDownSM || location.pathname === "/home" ? "100%" : "80%",
              }}
            >
              <Outlet />
            </div>
          </MainLayout>
        </StreamApp>
      </>
    );
  };
  return (
    <Routes>
      <Route path={URL.AUTH.LOGIN} element={<Login />} />
      <Route path={URL.AUTH.REGISTER} element={<SiginUp />} />
      <Route path={URL.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />

      <Route element={<ProtectedRoute />}>
        <Route path={URL.APP.SEARCH} element={<Search />} />
        <Route path={URL.APP.PRODUCTS} element={<Products />}/>
        <Route path={URL.APP.HOME} element={<Home />} />
        <Route path={URL.APP.PROFILE} element={<Profile />} />
        <Route path={URL.APP.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={URL.APP.BILLING} element={<Billing />} />
        <Route path={URL.APP.USER_INFO} element={<UserInfo />} />
        <Route path={URL.APP.SHOW_LEGAL} element={<ShowLegals />} />
        <Route path={URL.APP.PUBLIC_PROFILE} element={<PublicProfile />} />
        <Route
          path={URL.APP.PUBLIC_PROFILE + "/:id"}
          element={<PublicProfile />}
        />
        <Route
          path={URL.APP.PUBLIC_PROFILE + "/:id" + "/:activityId"}
          element={<PublicProfile />}
        />
        <Route path={URL.NOT_FOUND} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
