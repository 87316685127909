import React from 'react'
import { NewActivitiesNotification as NewActivitiesNotificationBase } from 'react-activity-feed'

//import styles from './styles'

export const NewActivitiesNotification = props => {
  const labelFunction = ({ addCount }) => {
    if (addCount === 0) {
      return null
    }

    const singleNotificationText = 'newActivitiesNotification.singleNotificationText'
    const pluralNotificationText = 'newActivitiesNotification.pluralNotificationText'

    return addCount > 1 ? pluralNotificationText : singleNotificationText
  }

  return <NewActivitiesNotificationBase {...props} labelFunction={labelFunction} 
  //styles={{ container: styles.container, text: styles.text }} 
  />
}


