import React, { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project imports
import useScriptRef from "hooks/useScriptRef";
import AnimateButton from "ui-component/extended/AnimateButton";
// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Auth } from "aws-amplify";
import _ from "lodash";
import { authActions } from "redux/auth";
import { Alert, AlertTitle } from "@material-ui/core";
import instance, { fetchGetStreamToken, fetchUserProfile } from "api";
import { setUser } from "redux/auth/operations";
import { CircularProgress } from "@mui/material";
import { setGetStreamToken, setStreamToken } from "redux/auth/actions";
import { useNavigate } from "react-router-dom";
import { URL } from "Constants/routes";

import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';

const FirebaseLogin = ({ ...others }) => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();

  const [checked, setChecked] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //
  const [isLoginError, setLoginError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state) => !!(state.auth.user && state.auth.profile)
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (email, password) => {
    setLoading(true);
    try {
      await Auth.signIn({
        username: email,
        password: password,
      });
      const signInUserSession = await Auth.currentSession();
      const token = _.get(signInUserSession, "accessToken.jwtToken", null);
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      if (token) {
        dispatch(setUser(user));
        instance.defaults.headers.common.Authorization = "Bearer " + token;
        const username = _.get(user, "username", null);
        const userProfile = await fetchUserProfile(username);
        const streamToken = await fetchGetStreamToken();
        dispatch(setStreamToken(streamToken.token));
        dispatch(authActions.setProfile(userProfile));
        dispatch(authActions.setUserSession(signInUserSession));
      }
    } catch (error) {
      setLoading(false);
      if (_.get(error, "code", "") === "UserNotConfirmedException") {
        navigate(`/${URL.AUTH.REGISTER}`, { state: { fromLogin: true } });
        setLoginError(
          "The user is not confirmed yet, please verify your email address!"
        );
      } else {
        setLoginError(
          "Invalid email or password, please enter the correct credentials"
        );
      }
    }
  };
  if (isLoggedIn) return <Navigate to="/home" replace />;

  return (
    <>
      {isLoginError ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {isLoginError}
        </Alert>
      ) : undefined}
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              {TextFormat.uppercaseFirstLetter(t("signinemail"))}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(TextFormat.uppercaseFirstLetter(t("validemail")))
            .max(255)
            .required(TextFormat.uppercaseFirstLetter(t("emailrequired"))),
          password: Yup.string().max(255).required(TextFormat.uppercaseFirstLetter(t("passwordrequired"))),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleSubmit(values.email, values.password);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-email-login">
                {TextFormat.uppercaseFirstLetter(t("emailaddress"))}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label={TextFormat.uppercaseFirstLetter(t("emailaddress")) + " / " + TextFormat.uppercaseFirstLetter(t("username"))}
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-password-login">
                {TextFormat.uppercaseFirstLetter(t("password"))}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label={TextFormat.uppercaseFirstLetter(t("password"))}
                inputProps={{}}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-login"
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                    name="checked"
                    color="primary"
                  />
                }
                label={TextFormat.uppercaseFirstLetter(t("rememberme"))}
              />
              <Link href="/forgot-password">
                <Typography variant="subtitle1">{TextFormat.uppercaseFirstLetter(t("forgotpassword"))}</Typography>
              </Link>
            </Stack>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  {isLoading ? (
                    <CircularProgress color="warning" size={20} />
                  ) : (
                    TextFormat.uppercaseAllFirstsLetters(t("signin"))
                  )}
                </Button>
              </AnimateButton>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <div />
              <Link style={{ marginTop: 15 }} href="/register">
                <Typography variant="subtitle1">
                  {TextFormat.uppercaseFirstLetter(t("dontaccount")) + 
                   " " + TextFormat.uppercaseAllFirstsLetters(t("signup"))}
                </Typography>
              </Link>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FirebaseLogin;
