import React from "react";

import { FlatFeed, InfiniteScrollPaginator } from "react-activity-feed";
import _ from "lodash";
import { NewActivitiesNotification } from "components/atoms/new-activities-notification/view";
import { FeedEmptyComponent } from "ui-component/FeedEmptyComponent";
import { RenderVideo } from "../../Home/HomeComponents/VideoView";
import { Typography } from "@mui/material";
import { TextFormat } from "utils/textFormat";
import { useTranslation } from "react-i18next";

function TimeLineVideoView(mainProps) {
  const fromFollowing = mainProps?.fromFollowing
    ? mainProps?.fromFollowing
    : false;
  // const renderVideoView = (props) => {
  //   return (
  //     <RenderVideo
  //     showComments={props.options.kind === "purchase"}
  //     videoId={_.get(props, "activity.object")}
  //       fromFollowing={fromFollowing}
  //       item={props.activity}
  //       isUrl={true}
  //       idx={props?.activity?.id}
  //     />
  //   );
  // };
  const { t } = useTranslation();

  return (
    <div>
      <FlatFeed
        Placeholder={() => (
          window.location.pathname !== '/profile' ?
          (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Typography textAlign={"center"} width={"50%"} variant="h5">
              {TextFormat.uppercaseFirstLetter(t("nofollow"))}
            </Typography>
          </div>
          ): <FeedEmptyComponent />
        )}
        notify
        feedGroup={mainProps.feedGroup}
        options={mainProps.options}
        Paginator={InfiniteScrollPaginator}
        userId={mainProps.username}
        Notifier={(props) => <NewActivitiesNotification {...props} />}
        // Activity={(props) => renderVideoView(props)}
        Activity={(props) => {


          return (
            <RenderVideo
              showComments={mainProps.showComments}
              userId={mainProps.username}
              videoId={_.get(props, "activity.object")}
              activityId ={ _.get(props.activity, 'id')}
              fromFollowing={fromFollowing}
              item={props.activity}
              isUrl={true}
              idx={props?.activity?.id}
            />
          );
        }}
      />
    </div>
  );
}
export default TimeLineVideoView;
