import React from "react";

import {
  LikeButton,
  CommentField,
  CommentItem,
  CommentList,
  FlatFeed,
  InfiniteScrollPaginator,
} from "react-activity-feed";

import _ from "lodash";
import { NewActivitiesNotification } from "components/atoms/new-activities-notification/view";
import { RenderVideo } from "../../Home/HomeComponents/VideoView";
import "./index.css";

import { FeedEmptyComponent } from "ui-component/FeedEmptyComponent";
import { collapseClasses } from "@mui/material";
function UserLikesTab(mainProps) {
  const fromFollowing = mainProps?.fromFollowing;

  return (
    <div>
      <FlatFeed
        notify
        feedGroup={mainProps.feedGroup}
        options={mainProps.options}
        doFeedRequest={async (client, feedGroup, userId, options) => {
          const res = await client.reactions.filter(options);
          const results = _.get(res, "results", []);
          if (!_.size(results)) {
            return res;
          }

          const activitiesIds = _.map(results, (v) => _.get(v, "activity_id"));
          const activities = await client.getActivities({
            ...options,
            ids: activitiesIds,
          });

          return activities;
        }}
        Placeholder={() => <FeedEmptyComponent />}
        Paginator={InfiniteScrollPaginator}
        Notifier={(props) => <NewActivitiesNotification {...props} />}
        Activity={(props) => {
          return (
            <RenderVideo


              videoId={_.get(props, "activity.object")}
              item={props.activity}
              isUrl={true}
              showComments={mainProps.options.kind === "purchase"}
              fromFollowing={fromFollowing}
              idx={props?.activity?.id}
            />
          );
        }}
      />
    </div>
  );
}
export default UserLikesTab;
