import React, {
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";
import Button from "@mui/material/Button";

import { useSnackbar } from "notistack";

const SnackBar = forwardRef((props, ref) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    getAlert(message, variant) {
      enqueueSnackbar(message, { variant: variant });
    },
  }));

  const handleClickWithAction = useCallback(() => {
    enqueueSnackbar("nackbars", {
      variant: "default",
      action: (key) => (
        <Fragment>
          <Button
            size="small"
            onClick={() =>
              alert(`Clicked on action of snackbar with id: ${key}`)
            }
          >
            Detail
          </Button>
          <Button size="small" onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        </Fragment>
      ),
    });
  }, [enqueueSnackbar, closeSnackbar]);

  return <div />;
});

export default SnackBar;
