import React, { useCallback, useState, useEffect } from "react";
import List from "@mui/material/List";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { fetchFollowedUsers } from "api";
import { fetchFollowingUsers } from "api";

import "./index.scss";
import FollowedCard from "ui-component/cards/FollowedCard";
import { FeedEmptyComponent } from "ui-component/FeedEmptyComponent";

export default function Followings({ username, isfollowerList }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const me = useSelector((state) => state.auth.profile);
  const isMyProfile = _.get(me, "username") === username;

  const [usersPage, setUsersPage] = useState(1);
  const [usersTotal, setUsersTotal] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchUsersList = useCallback(
    async (currentList, currentPage) => {
      try {
        setUsersLoading(true);
        const params = { page: currentPage };
        const fetchFollowingUsersRes = await fetchFollowedUsers(
          username,
          params
        );
        const total = _.get(fetchFollowingUsersRes, "pageCount", 1);
        const data = [
          ...currentList,
          ..._.get(fetchFollowingUsersRes, "data", []),
        ];
        setUsersList(data);
        setUsersTotal(total);
      } catch (e) {
      } finally {
        setUsersLoading(false);
      }
    },
    [username, dispatch]
  );

  const initUsersList = useCallback(() => {
    const newList = [];
    const newPage = 1;
    setUsersList(newList);
    setUsersPage(newPage);
    fetchUsersList(newList, newPage);
  }, [fetchUsersList]);

  useEffect(() => {
    initUsersList();
  }, [initUsersList]);

  //for followers
  const fetchFollowerList = useCallback(
    async (currentList, currentPage) => {
      try {
        setUsersLoading(true);
        const params = { page: currentPage };
        const fetchFollowingUsersRes = await fetchFollowingUsers(
          username,
          params
        );
        const total = _.get(fetchFollowingUsersRes, "pageCount", 1);
        const data = [
          ...currentList,
          ..._.get(fetchFollowingUsersRes, "data", []),
        ];
        setFollowersList(data);
        setUsersTotal(total);
      } catch (e) {
      } finally {
        setUsersLoading(false);
      }
    },
    [username, dispatch]
  );

  const initFollowersList = useCallback(() => {
    const newList = [];
    const newPage = 1;
    setFollowersList(newList);
    setUsersPage(newPage);
    fetchFollowerList(newList, newPage);
  }, [fetchFollowerList]);

  useEffect(() => {
    initFollowersList();
  }, [initFollowersList]);

  return (
    <List sx={{}}>
      {isfollowerList ? (
        <>
          {followersList && followersList.length > 0 ? (
            followersList.map((item, index) => {
              return (
                <FollowedCard
                  index={index}
                  item={item}
                  isfollowerList={isfollowerList}
                />
              );
            })
          ) : (
            <FeedEmptyComponent />
          )}
        </>
      ) : (
        <>
          {usersList && usersList.length > 0 ? (
            usersList.map((item, index) => {
              return <FollowedCard index={index} item={item} />;
            })
          ) : (
            <FeedEmptyComponent />
          )}
        </>
      )}
    </List>
  );
}
