import * as usersActions from "./actions";
import { trackFollow } from "../../utils/tracks";
import { authOperations } from "../../redux/auth";
import { feedsOperations } from "../../redux/feeds";
import _ from "lodash";
import { followUserApi, unfollowUserApi } from "api";

export const initList = () => async (dispatch) => {
  dispatch(usersActions.setList([], 0));
  dispatch(usersActions.setPage(1));
  return dispatch(fetchList());
};

export const updatePage = (page) => async (dispatch, getState) => {
  dispatch(usersActions.setPage(page));
  return dispatch(fetchList());
};

export const fetchList =
  () =>
  async (dispatch, getState, { api, setError }) => {
    try {
      dispatch(usersActions.setFetching(true));

      const { list: currentList /*, page, perPage*/ } = getState().users;

      // API REQUEST PARAMS
      // const params = { page, perPage }

      // CONCATENATED LIST
      const newList = [];
      const list = [...currentList, ...newList];

      // TOTAL ITEMS
      const totalItems = 0;

      dispatch(usersActions.setList(list, totalItems));
      return list;
    } catch (e) {
      dispatch(setError(e));
      return [];
    } finally {
      dispatch(usersActions.setFetching(false));
    }
  };

export const fetchItem =
  () =>
  async (dispatch, getState, { api, setError }) => {
    try {
      dispatch(usersActions.setFetching(true));

      // API REQUEST
      const item = {};

      dispatch(usersActions.setItem(item));
      return item;
    } catch (e) {
      dispatch(setError(e));
      return null;
    } finally {
      dispatch(usersActions.setFetching(false));
    }
  };

export const followUser = (username, item) => async (dispatch) => {
  if (!username) {
    return false;
  }
  try {
    dispatch(usersActions.setFetching(true));
    if (item) {
      dispatch(usersActions.setItemSaving(item.id));
    }
    await followUserApi(username);
    trackFollow(username);
    // dispatch(authOperations.fetchProfile());
    if (item) {
      dispatch(usersActions.updateItemFollow(item, true));
    }
    dispatch(feedsOperations.refreshFeeds());
    return true;
  } catch (e) {
    return false;
  } finally {
    dispatch(usersActions.setFetching(false));
    dispatch(usersActions.setItemSaving(null));
  }
};

export const unfollowUser = (username, item) => async (dispatch) => {
  if (!username) {
    return false;
  }
  try {
    dispatch(usersActions.setFetching(true));
    if (item) {
      dispatch(usersActions.setItemSaving(item.id));
    }
    await unfollowUserApi(username);
    if (item) {
      dispatch(authOperations.fetchProfile());
      dispatch(usersActions.updateItemFollow(item, false));
    }
    dispatch(feedsOperations.refreshFeeds());
    return true;
  } catch (e) {
    // dispatch(setError(e));
    return false;
  } finally {
    dispatch(usersActions.setFetching(false));
    dispatch(usersActions.setItemSaving(null));
  }
};

export const fetchBlockedUsers =
  () =>
  async (dispatch, getState, { api, setError }) => {
    try {
      let fetchBlockedUsers = await api.getBlockedUsers();
      const blockedUsers = _.map(fetchBlockedUsers, "userBlocked");
      dispatch(usersActions.setBlockUserList(blockedUsers));
      return blockedUsers;
    } catch (e) {
      dispatch(setError(e));
      return [];
    } finally {
      dispatch(usersActions.setFetching(false));
    }
  };

export const blockUser =
  (payload) =>
  async (dispatch, getState, { api, setError }) => {
    const { userBlocked } = payload;
    if (!userBlocked) {
      return false;
    }
    try {
      const fetchBlockedUsers = await api.blockUser(payload);
      if (fetchBlockedUsers) {
        dispatch(usersActions.setBlockUser(payload));
      }
      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    } finally {
    }
  };

export const unblockUser =
  (payload) =>
  async (dispatch, getState, { api, setError }) => {
    const { userBlocked } = payload;
    if (!userBlocked) {
      return false;
    }
    try {
      await api.unblockUser(payload);
      dispatch(feedsOperations.refreshFeeds());
      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    } finally {
    }
  };
