import { configureAxiosDefaults, instance } from "./utils";
import axios from "axios";
import qs from "qs";
import { BASE_URL } from "../config/api";

import { WEB_BASE_URL, WEB_BASE_URL_TERMS } from "../config/api";
// import { Platform } from "react-native";
// import RNIap from 'react-native-iap'

export const URL_CONSUMER_PRIVACITY = "/privacy-consumers.html";
export const URL_CREATOR_PRIVACITY = "/privacy-creators.html";
export const URL_CONSUMER_TERMS = "/terms-and-conditions-consumers.html";
export const URL_LEGAL_ADVICE = "/legal.html";
export const URL_CREATOR_TERMS = "/terms-and-conditions-creators.html";

export default instance;

export const configureToken = async (token) => {
  await configureAxiosDefaults(
    "Authorization",
    token ? `Bearer ${token}` : null
  );
};

export const configureLanguage = (language) => {
  configureAxiosDefaults("Accept-Language", language);
};

export const fetchGetStreamToken = () => {
  const url = "/api/users/streamtoken";
  return instance.get(url);
};

export const fetchMe = () => {
  const url = "/api/users/me";
  return instance.get(url);
};

export const patchUserProfile = (data) => {
  const url = "/api/users/me";
  return instance.patch(url, data);
};

export const fetchUserProfile = (username) => {
  const url = `/api/users/${username}/profile`;
  return instance.get(url);
};

export const followUserApi = (username) => {
  const url = `/api/users/${username}/follow`;
  return instance.post(url);
};

export const unfollowUserApi = (username) => {
  const url = `/api/users/${username}/unfollow`;
  return instance.post(url);
};

export const fetchFollowedUsers = (username, params) => {
  const url = `/api/users/${username}/following?${qs.stringify(params, {
    skipNulls: true,
  })}`;
  return instance.get(url);
};

export const fetchSearchVideos = (params) => {
  const url = `/api/videos/search?${qs.stringify(params, {
    skipNulls: true,
  })}&filterBlocked=true`;
  return instance.get(url);
};

export const fetchSearchUsers = (params) => {
  const url = `/api/users/search?${qs.stringify(params, { skipNulls: true })}`;
  return instance.get(url);
};

export const fetchVideos = (params) => {
  const url = `/api/videos?${qs.stringify(params, { skipNulls: true })}`;
  return instance.get(url);
};

export const fetchVideo = (videoId) => {
  const url = `/api/videos/${videoId}?join=category`;
  return instance.get(url);
};

export const fetchVideoItem = (videoId) => {
  // `https://api.bethey.deploy-cd.com/api/videos/${activity.object}?join=category`
  const url = `${BASE_URL}/api/videos/${videoId}`;
  return axios.get(url);
};

export const postVideoApi = (data) => {
  const url = "/api/videos";
  return instance.post(url, data);
};

export const generateUploadVideoUrl = (videoId, data) => {
  const url = `/api/videos/${videoId}/upload-url`;
  return instance.post(url, data);
};

export const uploadVideo = (url, formData, onProgress) => {
  return axios.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress && onProgress(percentCompleted);
    },
  });
};

export const updateVideo = (videoId, data) => {
  const url = `/api/videos/${videoId}`;
  return instance.patch(url, data);
};

export const deleteVideo = (videoId) => {
  const url = `/api/videos/${videoId}`;
  return instance.delete(url);
};

export const purchaseVideo = (videoId) => {
  const url = `/api/videos/purchase/${videoId}`;
  return instance.post(url);
};

export const getVideoUrl = (videoId) => {
  const url = `/api/videos/video_url/${videoId}`;
  return instance.get(url);
};

export const fetchVideoCategoriesApi = () => {
  const url = "/api/categories";
  return instance.get(url);
};

export const createPayment = () => {
  const url = "/api/payments/create";
  return instance.post(url);
};

export const reportComment = (videoId, data) => {
  const url = `/api/videos/report-comment/${videoId}`;
  return instance.post(url, data);
};

export const reportVideo = (videoId, data) => {
  const url = `/api/videos/report/${videoId}`;
  return instance.post(url, data);
};

export const fetchStats = () => {
  const url = "/api/users/me/stats";
  return instance.get(url);
};

export const getFollowed = () => {
  const url = "/api/users/me/followed";
  return instance.get(url);
};

export const fetchMgmEarnings = () => {
  const url = "/api/users/me/earnings";
  return instance.get(url);
};

export const fetchBeneficiaries = (context) => {
  const url = `/api/beneficiaries?context=${context}`;
  return instance.get(url);
};

export const fetchContributions = () => {
  const url = "/api/users/me/contributions";
  return instance.get(url);
};

export const fetchUsers = (userName) => {
  const url = `api/users/resolve?nickname=${userName}`;
  return instance.get(url);
};

export const blockUser = (data) => {
  const url = "/api/users/me/block-user";
  return instance.post(url, data);
};

export const unblockUser = (data) => {
  const url = "/api/users/me/un-block-user";
  return instance.post(url, data);
};

export const getBlockedUsers = () => {
  const url = "/api/users/me/blocked-users";
  return instance.get(url);
};

export const updateContributions = (data) => {
  const url = "/api/users/me/contributions";
  return instance.put(url, data);
};

export const postLikes = (videoId) => {
  const url = `/api/videos/like/${videoId}`;
  return instance.post(url);
};

export const deleteComment = (videoId, reactionId) => {
  const url = `/api/videos/${videoId}/comments/${reactionId}`;
  return instance.delete(url);
};

export const fetchVideoRecommendations = (videoId) => {
  const url = `/api/videos/${videoId}/recommendations?limit=4`;
  return instance.get(url);
};

export const getPlaceHolderImage = () => {
  const img = `${WEB_BASE_URL}/images/placeholder.png`;
  return img;
};

export const getConsumerPrivacityUrl = () => {
  const url = `${WEB_BASE_URL_TERMS}${URL_CONSUMER_PRIVACITY}`;
  return url;
};

export const getCreatorPrivacityUrl = () => {
  const url = `${WEB_BASE_URL_TERMS}${URL_CREATOR_PRIVACITY}`;
  return url;
};

export const getConsumerTermsUrl = () => {
  const url = `${WEB_BASE_URL_TERMS}${URL_CONSUMER_TERMS}`;
  return url;
};

export const getCreatorTermsUrl = () => {
  const url = `${WEB_BASE_URL_TERMS}${URL_CREATOR_TERMS}`;
  return url;
};

export const getLegalAdviceUrl = () => {
  const url = `${WEB_BASE_URL_TERMS}${URL_LEGAL_ADVICE}`;
  return url;
};

export const fetchSuggestedList = () => {
  const url = "/api/users/featured";
  return instance.get(url);
};

export const getInvitationCode = () => {
  const url = "/api/users/me/invitations";
  return instance.get(url);
};

export const getIsPrivateBeta = () => {
  const url = "/api/invitations/config";
  return instance.get(url);
};

export const getCodeType = (code) => {
  const url = `/api/invitations/type/${code}`;
  return instance.get(url);
};

export const verifyPurchase = (
  {
    purchaseToken,
    productId,
    packageNameAndroid,
    transactionReceipt,
    transactionId,
  },
  beneficiary,
  currency,
  country
) => {
  let data = beneficiary ? { beneficiaryId: beneficiary.id } : {};
  // // if (Platform.OS === "android") {
  // //   const url = "/api/payments/verify-purchase";
  // //   data = {
  // //     ...data,
  // //     productId,
  // //     packageName: packageNameAndroid,
  // //     token: purchaseToken,
  // //     currencyCode: currency,
  // //   };
  // //   return instance.post(url, data);
  // // } else if (Platform.OS === "ios") {
  // //   const url = "/api/payments/verify-receipt";
  // //   data = {
  // //     ...data,
  // //     receipt: transactionReceipt,
  // //     transactionId,
  // //     currencyCode: currency,
  // //     countryCode: country,
  // //   };
  //   return instance.post(url, data);
  // } else {
  //   return Promise.reject(new Error("Unknown platform"));
  // }
};

export const getPurchaseProductsIdsForIos = (countryCode) => {
  const url = `/api/pricings/product-ids/ios/${countryCode}`;
  return instance.get(url);
};

export const getPurchaseProductsIdsForAndroid = () => {
  const url = "/api/pricings/product-ids/android";
  return instance.get(url);
};

export const getBeneficiaries = (context) => {
  const url = `/api/beneficiaries?sort=nameEn,ASC&context=${context}`;
  return instance.get(url);
};

export const getBeneficiariesByCountry = (context, countryCode) => {
  let url;
  if (countryCode && countryCode.length >= 2) {
    const cc = countryCode.substring(0, 2);
    url = `/api/beneficiaries?sort=nameEn,ASC&context=${context}&filter=country||$eq||${cc}&filter=scope||$eq||national&or=scope||$eq||international`;
  } else {
    url = `/api/beneficiaries?sort=nameEn,ASC&context=${context}&filter=scope||$eq||international`;
  }
  return instance.get(url);
};

export const fetchFollowingUsers = (username, params) => {
  const url = `/api/users/${username}/followers?${qs.stringify(params, {
    skipNulls: true,
  })}`;
  return instance.get(url);
};
//TODO:limit to blocked
export const fetchDiscoverVideos = (page, limit) => {
  const url = `/api/videos/most-recent?page=${page}&limit=${limit}&filterBlocked=true`;
  return instance.get(url);
};

export const fetchAddReaction = (activityId, event) => {
  const url = `/api/videos/${activityId}/add/${event}`;

  return instance.post(url);
};

export const fetchToggleReactions = (activityId, event) => {
  const url = `/api/videos/${activityId}/toggle/${event}`;

  return instance.post(url);
};

export const sendCountryCode = (countryCode) => {
  //TODO
  // const url = ''
  // return instance.post(url)
};

export const getCountryFromStore = async () => {
  // try {
  //   if (Platform.OS === 'android') {
  //     return ''
  //   }
  //   if (Platform.OS === 'ios') {
  //     const product = await RNIap.getProducts(['tier1'])
  //     const countryCode = product[0].countryCode
  //     return countryCode
  //   }
  // } catch (error) {
  //   return ''
  // }
};

export const getPaymentUrl = () => {
  const url = "/api/billing-info/payment-url";
  return instance.get(url);
};

export const getPricings = () => {
  const url = "/api/pricings/product-ids/web";
  return instance.get(url);
};

export const getBeneficiariesPurchase = () => {
  const url = "/api/beneficiaries?sort=nameEn,ASC&context=purchase";
  return instance.get(url);
}

export const postBeneficiariPurchase = (productId, beneficiaryId) => {
  const url=`/api/payments/${productId}/buy`;
  if(beneficiaryId !== 0){
    return instance.post(url, {beneficiaryId: beneficiaryId})
  } else {
    return instance.post(url, {})
  }

}
