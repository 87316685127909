import * as types from './types'

export const navigateByNotification = notificationInfo => ({
  type: types.INITIAL_NOTIFICATION_INFO,
  payload: notificationInfo,
})

export const resetNotification = () => ({
  type: types.INITIAL_NOTIFICATION_INFO,
  payload: null,
})
