import React, { useEffect, useContext } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, Select, MenuItem, Typography, Box, Button, Dialog } from '@mui/material';
import { getPricings, getBeneficiariesPurchase, postBeneficiariPurchase, fetchUserProfile } from "api";
import Loading from "ui-component/Loading";
import { useTranslation } from 'react-i18next';
import Icon from './../../Assets/images/ic_play_coins.png';
import Logo from "../../ui-component/Logo";
import { getAvailableVideos } from "utils";
import MainLayout from 'layout/MainLayout';
import { MyContext } from 'context/CoinsContext';

const MONEY_CONVERSION = 1000000;

const useStyles = makeStyles(theme => ({
    mainContainer: {
        [theme.breakpoints.down('md')]: {
            padding: '0'
        },
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '2em'
      },
      padding: '1em 0em 3em 0em',
    },
    box: {
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      border: '1px solid #D3D3D3',
      padding: '1em',
      borderRadius: '16px',
      cursor: 'pointer',
    },

    coinsIcon: {
        width: 40,
        height: 40,
        filter: 'grayscale(100%)',
    },

    coinsContainer: {
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        padding: '2em 0em'
    },
    inputContainer: {
        borderRadius: '25px !important',
        textTransform: 'uppercase'
    },

    termsContainer: {
        justifyContent: 'center',
        display: 'flex',
        gap: '10px'
    },

    buyButton: {
        textAlign: 'center !important',
        textTransform: 'uppercase !important',
        padding: '1em 2em !important',
        borderRadius: '50px !important',
        fontWeight: 'bold !important'
    },
    defaultValueInput: {
        textTransform: 'uppercase'
    },

    myDialog: {
      position: 'inherit !important'
    },
    cancelButton: {
      textAlign: 'center !important',
      textTransform: 'uppercase !important',
      padding: '1em 3em !important',
      borderRadius: '50px !important',
      fontWeight: 'bold !important',
      backgroundColor: 'transparent',
      color: 'black !important',
      border: '0 !important',
    }
}));

const Products = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [status, setStatus] = React.useState(0);
    const [ong, setONG] = React.useState(0);
    const [ongs, setONGs] = React.useState([]);
    const [plan, setPlan] = React.useState(0);
    const [pricins, setPricings] = React.useState([]);
    const [loadingONG, setLoadingONG] = React.useState(true);
    const [loadingPricings, setLoadingPricings] = React.useState(true);
    const [messages, setMessages] = React.useState({});
    const user = useSelector((state) => state.auth.user);
    const { coins, updateCoins } = useContext(MyContext);

    useEffect(() => {
        fetchBeneficiaries();
        fetchPricings();
    }, [])

    const fetchBeneficiaries = async () => {
        try {
            setLoadingONG(true);
            const fetchBeneficiaries = await getBeneficiariesPurchase();
            setONGs(fetchBeneficiaries.data);
        } catch (e) {
            alert(e);
        } finally {
            setLoadingONG(false);
        }
    };

    const fetchPricings = async () => {
        try {
            setLoadingPricings(true);
            const pricings = await getPricings();
            setPricings(pricings.pricings);
        } catch (e) {
            alert(e);
        } finally {
            setLoadingPricings(false);
        }
    }
    console.log(plan, 'plan');

    const makePurchase = async () => {
        console.log(plan, 'plan');
        try {
            setLoading(true);
            const purchase = await postBeneficiariPurchase(plan, ong);
            setStatus(purchase.success ? 200 : status);
            updateCoins(user.username)
        } catch (e) {
            setStatus(e.response.status)
        } finally {
            setShow(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        switch(status) {
            case 200:
                setMessages({
                    header: "",
                    text: t("errorModal.purchasesucces")
                });
                break;
            case 400:
                setMessages({
                    header: t("errorModal.paymentMethodsNotAvailable"),
                    text: t("errorModal.addPaymentMethod")
                });
                break;
            case 404:
                setMessages({
                    header: t("errorModal.notfound"),
                    text: t("errorModal.selectanotherone")
                });
                break;
            case 500:
                setMessages({
                    header: t("errorModal.thereiserror"),
                    text: t("errorModal.tryagainlater")
                });
                break;
        }

    }, [status])

    const openStripe = () => {
        window.open(process.env.REACT_APP_STRIPE_URL, "_blank")
    }

  return (
    <MainLayout coins={coins}>
        <div className={classes.mainContainer}>
            { loadingONG || loadingPricings ?
                <Loading isLoading={true} /> :
                <Box>
                    <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: "2em"}}>
                        <Logo />
                    </Box>
                    <Typography
                        gutterBottom
                        variant={"h1"}
                        textAlign={"center"}
                    >
                        {t("purchases.enjoy")}
                    </Typography>

                        <Typography
                            variant={"h4"}
                            textAlign={"center"}
                            sx={{ my: '2em' }}
                        >
                            {t("purchases.allIdols")}
                        </Typography>

                    <Box className={classes.root}>
                        {
                            pricins.map(pricing =>
                                <Box key={pricing.id} className={classes.box} onClick={() => plan !== pricing.id ? setPlan(pricing.id): setPlan(0) } style={{ backgroundColor: plan === pricing.id ? "#f01d22" : "transparent" }}>
                                <Typography
                                    textAlign={"center"}
                                    fontSize="20px"
                                    style={{ color: plan === pricing.id ? "white" : "#616161" }}
                                >
                                    {pricing.productID}
                                </Typography>
                                <Typography
                                    textAlign={"center"}
                                    style={{ color: plan === pricing.id ? "white" : "#616161" }}
                                >
                                    (Bethey)
                                </Typography>
                                <div className={classes.coinsContainer}>
                                    <img className={classes.coinsIcon} src={Icon} alt="play"/>
                                </div>
                                <Typography
                                    variant={"h2"}
                                    textAlign={"center"}
                                    style={{ color: plan === pricing.id ? "white" : "#616161" }}
                                >
                                    {pricing.price / MONEY_CONVERSION} {pricing.currency === "EUR" ? "€" : "$"}
                                </Typography>
                            </Box>
                            )
                        }
                    </Box>

                    <Typography
                        variant={"h4"}
                        textAlign={"center"}
                        sx={{ my: '2em' }}
                    >
                        {t("purchases.donate")}
                    </Typography>

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <FormControl style={{ width: '50%' }}>
                        <InputLabel id="demo-simple-select-label">ONG</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={ong}
                                label="ONG"
                                className={classes.inputContainer}
                                onChange={(e) => setONG(e.target.value)}
                            >
                                <MenuItem className={classes.defaultValueInput} value={0}>{t("purchases.selectBeneficiary")}</MenuItem>
                                { ongs.map((ong) =>
                                    <MenuItem key={ong.id} value={ong.id}>{localStorage.getItem("lang") === 'es' ? ong.nameEs : ong.nameEn}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box className={classes.termsContainer}>
                        <Typography
                            variant={"h4"}
                            textAlign={"center"}
                            sx={{ my: '2em' }}
                        >
                            {t("purchases.apply")}
                        </Typography>

                        <a href="https://www.bethey.com/terms-and-conditions-consumers" target="_blank" rel="noopener noreferrer">
                            <Typography
                                variant={"h4"}
                                textAlign={"center"}
                                sx={{ my: '2em' }}
                                underline="always"
                            >
                                {t("purchases.terms")}
                            </Typography>
                        </a>
                    </Box>


                    <Typography
                        variant={"h6"}
                        textAlign={"center"}
                        sx={{ my: '2em' }}
                    >
                        {t("purchases.note")}
                    </Typography>

                    <Box className={classes.termsContainer}>
                        {!loading ?
                            <Button
                                className={classes.buyButton}
                                disabled={(plan !== 0) ? false : true}
                                style={{ backgroundColor: plan !== 0 ? "#f01d22" : "rgba(0, 0, 0, 0.06)", color: plan !== 0 ? "white" : "#616161" }}
                                onClick={() => {
                                    makePurchase()
                                }}
                            >
                                {t("purchases.buyVideos")}

                            </Button> :
                            <Button
                                className={classes.buyButton}
                                disabled={true}
                                style={{ backgroundColor: "rgba(0, 0, 0, 0.06)", color: "#616161" }}
                            >
                                {t("loading")}
                            </Button>
                        }
                    </Box>
                </Box>
            }

            <Dialog open={show} onClose={()=> setShow(false)} classes={{ paper: classes.myDialog }}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "#fff",
                        p: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: '60%',
                    }}
                >
                    { status !== 200 ? <Typography variant="h3" mb={3} align="center" color="black">
                        { messages.header }
                    </Typography> : null}
                    <Typography variant="h2" align="center" mt={2} fontWeight="bold">
                        { messages.text }
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={4} >
                        {status === 400 ? <Button className={classes.buyButton} variant="contained" color="primary" mb={1} onClick={() => openStripe()}>
                            {t("paymentMethodsConfigure")}
                        </Button> : null}
                        <Button className={classes.cancelButton} onClick={()=> setShow(false)} variant="outlined" color="primary">
                            {t("purchaseModal.back")}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    </MainLayout>
  )
}

export default Products