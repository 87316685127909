import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Typography,
  useMediaQuery,
  Modal,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Card from "@mui/material/Card";

import { Box, Button } from "@mui/material";
import CustomAvatar from "ui-component/Avatar";
import Loading from "ui-component/Loading/";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SwipeableViews from "react-swipeable-views";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Image from "Assets/images/backcover.jpeg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "api";
import Followings from "Pages/Profile/Followings";
import MyVideos from "Pages/Profile/MyVideos";

import { followUser, unfollowUser } from "redux/users/operations";
import UserLikesTab from "Pages/PublicProfiles/VideosFeed/view";

import TimeLineVideoView from "Pages/PublicProfiles/VideosFeed/TimeLineVideoView";
import UserInfo from "./UserInfo";
import ChangePassword from "./ChangePassword";
import { Edit } from "@material-ui/icons";
import "./index.scss";
import { postProfileImage } from "redux/auth/operations";
import VideoUploader from "Pages/Home/VideoUploader";

import { TextFormat } from "utils/textFormat";
import { useTranslation } from 'react-i18next';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
  },
};

const PublicProfile = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = useSelector((state) => state.auth.profile);
  const { id } = useParams();
  const [userProfile, setUserProfile] = useState(undefined);
  const [value, setValue] = React.useState(0);
  const [isLoading, setLoading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [openFollowers, setOpenFollowers] = useState(false);
  const [openFollowings, setOpenFollowings] = useState(false);

  const [editPasswordModalOpen, setEditPasswordModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    _loadUserProfile();
  }, [id]);

  console.log("userProfile", userProfile);
  const isMyProfile = !id || (currentUser && currentUser.username === id);

  const _loadUserProfile = async () => {
    if (isMyProfile) {
      const userProfile = await fetchUserProfile(currentUser.username);
      setUserProfile(userProfile);
      setLoading(false);
    } else {
      try {
        const userProfile = await fetchUserProfile(id);
        setUserProfile(userProfile);
        setLoading(false);
      } catch (error) {
        alert(TextFormat.uppercaseFirstLetter(t("unableuser")) + ", " + t("pleaselater"));
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  let inputRef = useRef().current;

  const handleImageSelection = async (event) => {
    const fileToUpload = event.target.files[0];
    const imageData = {
      imageUri: URL.createObjectURL(fileToUpload),
      fileName: fileToUpload.name,
      type: fileToUpload.type,
    };
    imageData.fileName = `${currentUser.username}-${Date.now()}.jpg`;
    console.log(
      "🚀 ~ file: index.js ~ line 319 ~ handleImageSelection ~ imageData",
      imageData
    );
    try {
      const success = await postProfileImage(imageData);
      console.log(
        "🚀 ~ file: index.js ~ line 325 ~ handleImageSelection ~ success",
        success
      );
    } catch (error) {
      console.log(
        "🚀 ~ file: index.js ~ line 331 ~ handleImageSelection ~ error",
        error
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Card
          sx={{
            backgroundColor: "#ffff",
          }}
        >
          <div
            style={{
              padding: "1rem",
              display: "flex",
              flexDirection: matchDownSM ? "column" : "row",
              alignItems: matchDownSM ? "center" : "unset",
              justifyContent: matchDownSM ? "center" : "unset",
            }}
          >
            <Grid container>
              <Grid>
                <input
                  id="profilePicture"
                  type="file"
                  ref={(ref) => (inputRef = ref)}
                  style={{ display: "none" }}
                  onChange={(event) => handleImageSelection(event)}
                />
                <div
                  className="edit-icon-profile"
                  style={{ marginLeft: matchDownSM ? "0px" : "8px" }}
                  onClick={() => {
                    inputRef.click();
                  }}
                >
                  <Edit sx={{ color: "#fff", height: 20, width: 20 }} />
                </div>
                <CustomAvatar
                  sx={{
                    width: matchDownSM ? 90 : 150,
                    height: matchDownSM ? 90 : 150,
                  }}
                  item={{
                    username: userProfile.nickname,
                    image: userProfile.image,
                    publisher: userProfile.publisher,
                    publisherType: userProfile.publisherType,
                  }}
                />
              </Grid>
              <Grid
                //  lg={7.5}
                // sx={11}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingLeft: matchDownSM ? 2 : 5,
                }}
              >
                <Typography variant="caption" fontSize="16px">
                  {matchDownSM
                    ? userProfile.nickname.length > 10
                      ? userProfile.nickname.substring(0, 15) + "..."
                      : userProfile.nickname
                    : userProfile.nickname}
                </Typography>
                <Typography fontSize="16px">{userProfile.name}</Typography>
                <Typography variant="caption" fontSize="16px">
                  {userProfile.description}
                </Typography>
                {!matchDownSM && (
                  <Grid>
                    {isMyProfile ? (
                      <Grid>
                        {/* <Button
                          variant="outlined"
                          sx={{
                            borderRadius: 10,
                            width: "150px",
                            marginTop: "5px",
                          }}
                          onClick={() => setEditModalOpen(!editModalOpen)}
                        >
                          Edit Profile
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            borderRadius: 10,
                            width: "200px",
                            marginTop: "5px",
                            marginLeft: "10px",
                          }}
                          onClick={() =>
                            setEditPasswordModalOpen(!editPasswordModalOpen)
                          }
                        >
                          Change Password
                        </Button> */}
                      </Grid>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: 10,
                          width: "150px",
                          marginTop: "5px",
                        }}
                        onClick={() => {
                          if (userProfile.currentlyFollowing) {
                            dispatch(unfollowUser(userProfile.username));
                          } else {
                            dispatch(followUser(userProfile.username));
                          }
                          // TODO: Add an async request and show a loader to the user
                          _loadUserProfile();
                        }}
                      >
                        {userProfile.currentlyFollowing ?
                         TextFormat.uppercaseFirstLetter(t("unfollow")) :
                         TextFormat.uppercaseFirstLetter(t("follow"))}
                      </Button>
                    )}
                  </Grid>
                )}
                {!matchDownSM && (
                  <Grid
                    lg={3}
                    sx={12}
                    style={{ display: "flex", marginTop: "10px" }}
                    justifyContent="start"
                  >
                    <Grid sx={6}>
                      <Typography
                        onClick={() => {
                          setOpenFollowers(!openFollowers);
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                        textAlign="right"
                        fontSize="15px"
                        fontWeight="bold"
                        color="#F01D22"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {userProfile.followersCount}&nbsp;
                        <Typography
                          textAlign="right"
                          fontSize="15px"
                          fontWeight="bold"
                        >
                          {TextFormat.uppercaseFirstLetter(t("followers"))}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid sx={6}>
                      <Typography
                        onClick={() => {
                          setOpenFollowings(!openFollowings);
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                        textAlign="right"
                        fontSize="15px"
                        fontWeight="bold"
                        color="#F01D22"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          paddingLeft: 20,
                        }}
                      >
                        {userProfile.followingCount}&nbsp;
                        <Typography
                          textAlign="right"
                          fontSize="15px"
                          fontWeight="bold"
                        >
                          {TextFormat.uppercaseFirstLetter(t("following"))}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Modal
              open={editModalOpen || editPasswordModalOpen}
              onClose={() => {
                setEditPasswordModalOpen(false);
                setEditModalOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: matchDownSM ? 320 : 500,
                  background: "#fff",
                  padding: 20,
                }}
              >
                {editPasswordModalOpen ? (
                  <ChangePassword />
                ) : (
                  <UserInfo
                    refresh={() => _loadUserProfile()}
                    toggleModal={() => setEditModalOpen(!editModalOpen)}
                  />
                )}
              </Box>
            </Modal>
            {/* {!matchDownSM ? (
              <Grid>
                <Typography
                  onClick={() => {
                    setOpenFollowers(!openFollowers);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userProfile.followersCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Followers
                  </Typography>
                </Typography>
                <Typography
                  onClick={() => {
                    setOpenFollowings(!openFollowings);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="25px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userProfile.followingCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Following
                  </Typography>
                </Typography>
              </Grid>
            ) : undefined} */}

            <Modal
              open={openFollowers || openFollowings}
              onClose={() => {
                setOpenFollowers(false);
                setOpenFollowings(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                style={{
                  position: "absolute",
                  top: "50%",

                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: matchDownSM ? 380 : 500,
                  background: "#fff",
                  padding: 20,

                  paddingBottom: 50,
                }}
              >
                {openFollowers ? (
                  <Followings
                    isfollowerList={true}
                    username={userProfile.username}
                  />
                ) : (
                  <Followings username={userProfile.username} />
                )}
              </Box>
            </Modal>
          </div>
          {matchDownSM ? (
            <Grid
              lg={3}
              sx={12}
              style={{ display: "flex" }}
              justifyContent="center"
            >
              <Grid sx={6}>
                <Typography
                  onClick={() => {
                    setOpenFollowers(!openFollowers);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="15px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userProfile.followersCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="15px"
                    fontWeight="bold"
                  >
                    {TextFormat.uppercaseFirstLetter(t("followers"))}
                  </Typography>
                </Typography>
              </Grid>
              <Grid sx={6}>
                <Typography
                  onClick={() => {
                    setOpenFollowings(!openFollowings);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  textAlign="right"
                  fontSize="15px"
                  fontWeight="bold"
                  color="#F01D22"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingLeft: 20,
                  }}
                >
                  {userProfile.followingCount}&nbsp;
                  <Typography
                    textAlign="right"
                    fontSize="15px"
                    fontWeight="bold"
                  >
                    {TextFormat.uppercaseFirstLetter(t("following"))}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          ) : undefined}

          <Box
            sx={{
              paddingY: matchDownSM ? "1rem" : "2rem",
              backgroundColor: "#ffff",
            }}
          >
            {userProfile.publisher ? (
              <AppBar
                sx={{
                  backgroundColor: "#ffff",
                }}
                position="static"
                elevation={0}
              >
                <Tabs
                  sx={{
                    backgroundColor: "#ffff",
                    border: 1,
                    borderTopColor: "#D3D3D3",
                  }}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    label={<span>{TextFormat.uppercaseFirstLetter(t("published"))}</span>}
                    icon={<YouTubeIcon />}
                    {...a11yProps(0)}
                  />

                  <Tab
                    icon={<PersonOutlineIcon />}
                    label={<span>{TextFormat.uppercaseFirstLetter(t("myvideos"))}</span>}
                    {...a11yProps(1)}
                  />

                  <Tab
                    label={<span>{TextFormat.uppercaseFirstLetter(t("purchased"))}</span>}
                    icon={<PlayCircleOutlineIcon/>}
                    {...a11yProps(2)}
                  />

                  <Tab
                    icon={<FavoriteIcon />}
                    label={<span>{TextFormat.uppercaseFirstLetter(t("favourites"))}</span>}
                    {...a11yProps(3)}
                  />

                  {/* <Tab
                    icon={<PersonPinIcon />}
                    label={<span>followers</span>}
                    {...a11yProps(2)}
                  />
                  <Tab
                    icon={<PersonPinIcon />}
                    label={<span>followings</span>}
                    {...a11yProps(3)}
                  /> */}
                </Tabs>
              </AppBar>
            ) : (
              <AppBar
                sx={{
                  backgroundColor: "#ffff",
                }}
                position="static"
                elevation={0}
              >
                <Tabs
                  sx={{
                    backgroundColor: "#ffff",
                    border: 1,
                    borderTopColor: "#D3D3D3",
                  }}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    label={<span>{TextFormat.uppercaseFirstLetter(t("purchased"))}</span>}
                    icon={<PlayCircleOutlineIcon />}
                    {...a11yProps(0)}
                  />

                  <Tab
                    icon={<FavoriteIcon />}
                    label={<span>{TextFormat.uppercaseFirstLetter(t("favourites"))}</span>}
                    {...a11yProps(1)}
                  />

                  {/* <Tab
                    icon={<PersonPinIcon />}
                    label={<span>followers</span>}
                    {...a11yProps(2)}
                  />
                  <Tab
                    icon={<PersonPinIcon />}
                    label={<span>followings</span>}
                    {...a11yProps(3)}
                  /> */}
                </Tabs>
              </AppBar>
            )}

            {userProfile.publisher ? (
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                {value === 0 && (
                  <TimeLineVideoView
                    fromFollowing={true}
                    options={{
                      limit: 20,
                    }}
                    feedGroup="user"
                    showComments={true}
                    username={userProfile.username}
                  />
                )}
                {value === 1 && <MyVideos />}
                {value === 2 && (
                  <UserLikesTab
                    options={{
                      limit: 20,
                      kind: "purchase",
                      user_id: userProfile.username,
                    }}
                    fromFollowing={true}
                    feedGroup="timeline"
                    username={userProfile.username}
                  />
                )}
                {value === 3 && (
                  <UserLikesTab
                    fromFollowing={true}
                    options={{
                      limit: 20,
                      kind: "like",
                      user_id: userProfile.username,
                    }}
                    feedGroup="timeline"
                    username={userProfile.username}
                  />
                )}

                {/* {value == 2 && (
                <Followings
                  isfollowerList={true}
                  username={userProfile.username}
                />
              )}
              {value == 3 && <Followings username={userProfile.username} />} */}
              </SwipeableViews>
            ) : (
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                {value === 0 && (
                  <UserLikesTab
                    options={{
                      limit: 20,
                      kind: "purchase",
                      user_id: userProfile.username,
                    }}
                    fromFollowing={true}
                    feedGroup="timeline"
                    username={userProfile.username}
                  />
                )}

                {value === 1 && (
                  <UserLikesTab
                    fromFollowing={true}
                    options={{
                      limit: 20,
                      kind: "like",
                      user_id: userProfile.username,
                    }}
                    feedGroup="timeline"
                    username={userProfile.username}
                  />
                )}
              </SwipeableViews>
            )}
          </Box>
        </Card>
      )}
      <VideoUploader />
    </>
  );
};

export default PublicProfile;
