import React from "react";

import bezier from "./../../Assets/svgs/users/bezier.svg";
const commonStyles = {
  mt: -10,
  bgcolor: "background.paper",
  //borderColor: 'text.primary',
  //m: 1,
  boxShadow: 4,
  //border: 1,
  width: "30rem",
  height: "30rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};
const ShowLegals = ({ props }) => {


  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          background: "#F01D22",
        }}
      >
        <img
          width={"100%"}
          style={{ position: "absolute", bottom: 0 }}
          src={bezier}
          alt="bezier"
        />
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          background: "#ffff",
        }}
      ></div>
    </>
  );
};

export default ShowLegals;
