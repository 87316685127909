import React from "react";
import { persistor, store } from "./store";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import theme from "./Theme";
import { ThemeProvider } from "@mui/material/styles";
import "react-activity-feed/dist/index.css";
import App from "./App";
import "./index.css";
import "./i18n/i18n";
import { SnackbarProvider } from "notistack";
import CoinsContext from "context/CoinsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme()}>
    <Provider store={store}>
      <CoinsContext>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={2}>
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </PersistGate>
      </CoinsContext>
    </Provider>
  </ThemeProvider>
);

reportWebVitals();
