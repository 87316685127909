import * as types from "./types";

export const initialState = {
  purchase: null,
  products: null,
  isFetching: false,
  purchaseInfo: null,
  errorPurchase: false,
  appStoreCountry: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_PURCHASE:
      return {
        ...state,
        purchase: action.payload,
      };
    case types.SET_PURCHASE_INFO:
      return {
        ...state,
        purchaseInfo: action.payload,
      };

    case types.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case types.SET_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case types.SET_ERROR_PURCHASE:
      return {
        ...state,
        errorPurchase: action.payload,
      };
    case types.SET_APP_STORE_COUNTRY:
      return {
        ...state,
        appStoreCountry: action.payload,
      };
    // case types.LOGOUT:
    //   return initialState
    default:
      return state;
  }
};

export default reducer;
