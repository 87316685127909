import * as types from "./types";

export const initialState = {
  list: [],
  selected: null,
  isFetching: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_BENEFICIARIES:
      return {
        ...state,
        list: action.payload,
      };
    case types.SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case types.SET_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    // case types.LOGOUT:
    //   return initialState;
    default:
      return state;
  }
};

export default reducer;
