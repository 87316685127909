import { ENV } from "./api";

const STREAM_CONSTANTS_PRO = {
  API_KEY: "ybnhdk788tp9",
  APP_ID: "88134",
  TEST_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZTY5YmYzOGUtZGU3My00MDkzLWJkNmMtNmVjNDQxODdkMDQ2In0.GLJcIpBvNwP1PhZ2OWE38oTzFdehfWI0moEtpx0jZsk",
};

const STREAM_CONSTANTS_STG = {
  API_KEY: "ghyxs87a8r86",
  APP_ID: "1129363",
  TEST_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZTY5YmYzOGUtZGU3My00MDkzLWJkNmMtNmVjNDQxODdkMDQ2In0.GLJcIpBvNwP1PhZ2OWE38oTzFdehfWI0moEtpx0jZsk",
  STREAMS_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNzAwZTdjNmMtODBmNC00M2I2LWE2NmYtZjA4MDI3M2ZjN2JkIn0.6n9Iyw1v7DyKfJITGAHGvigkvdTqBBaIQTjXB7DzL6s",
};

const STREAM_CONSTANTS_GRAL = {
  API_KEY: "rrkmcnekud5a",
  APP_ID: "1129351",
  TEST_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZTY5YmYzOGUtZGU3My00MDkzLWJkNmMtNmVjNDQxODdkMDQ2In0.GLJcIpBvNwP1PhZ2OWE38oTzFdehfWI0moEtpx0jZsk",
  STREAMS_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNzAwZTdjNmMtODBmNC00M2I2LWE2NmYtZjA4MDI3M2ZjN2JkIn0.6n9Iyw1v7DyKfJITGAHGvigkvdTqBBaIQTjXB7DzL6s",
};

const STREAM_CONSTANTS_AUDIT = {
  API_KEY: "7cczzxx4uhhq",
  APP_ID: "88135",
  TEST_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZTY5YmYzOGUtZGU3My00MDkzLWJkNmMtNmVjNDQxODdkMDQ2In0.GLJcIpBvNwP1PhZ2OWE38oTzFdehfWI0moEtpx0jZsk",
  STREAMS_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNzAwZTdjNmMtODBmNC00M2I2LWE2NmYtZjA4MDI3M2ZjN2JkIn0.6n9Iyw1v7DyKfJITGAHGvigkvdTqBBaIQTjXB7DzL6s",
};

export const STREAM_CONSTANTS = ENV === 'PRO' ? STREAM_CONSTANTS_PRO : ENV === 'STG' ? STREAM_CONSTANTS_STG : ENV === 'DEV' ? STREAM_CONSTANTS_GRAL : ENV === 'PRE' ? STREAM_CONSTANTS_GRAL : ENV === 'AUD' ? STREAM_CONSTANTS_AUDIT : null