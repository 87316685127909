import React, {useState, useContext, useEffect} from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Avatar, Box, Typography, Dialog, Button } from "@mui/material";
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { MyContext } from "context/CoinsContext";

const useStyles = makeStyles({
  myDialog: {
    position: 'inherit !important'
  },
  buyButton: {
    textAlign: 'center !important',
    textTransform: 'uppercase !important',
    padding: '1em 3em !important',
    borderRadius: '50px !important',
    fontWeight: 'bold !important',
    backgroundColor: '#f01d22',
    color: 'white',
  },
  cancelButton: {
    textAlign: 'center !important',
    textTransform: 'uppercase !important',
    padding: '1em 3em !important',
    borderRadius: '50px !important',
    fontWeight: 'bold !important',
    backgroundColor: 'transparent',
    color: 'black !important',
    border: '0 !important',
  },
});

const NotificationSection = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { coins, updateCoins } = useContext(MyContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    updateCoins(user.username)
  }, []);


  const handleClick = () => {
    setOpen(true)
  }

  return (
    <>
     <div
        onClick={handleClick}
        className="username-link"
      >
        <Box
          sx={{
            ml: 2,
            mr: 0,
            [theme.breakpoints.down("md")]: {
              mr: 2,
            },
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              alignItems: "center",
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumsAvatar,
              transition: "all .2s ease-in-out",
              background: "transparent",
              color: theme.palette.secondary.dark,
            }}
            aria-haspopup="true"
            color="inherit"
          >
            <img
              style={{ width: 20, height: 20, marginRight: 5 }}
              src={require("./../../../../Assets/images/ic_play_coins.png")}
            />
            <Typography
              sx={{
                color: theme.palette.secondary.dark,

                marginX: "2px",
                mt: "2px",
              }}
              variant="h4"
            >
              {coins}
            </Typography>
          </Avatar>
        </Box>
      </div>

      <Dialog open={open} onClose={()=> setOpen(false)} classes={{ paper: classes.myDialog }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: '60%',
            [theme.breakpoints.down("md")]: {
              width: '100%',
            },
          }}
        >
          <Typography variant="h3" mb={3} align="center" color="black">
          {t("purchaseModal.availableVideosLabel")}
          </Typography>
          <Avatar
            variant="rounded"

            sx={{
              alignItems: "center",
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumsAvatar,
              transition: "all .2s ease-in-out",
              background: "transparent",
              color: theme.palette.secondary.dark,
              marginTop: '1em',
              marginBottom: '1em'
            }}
            aria-haspopup="true"
            color="inherit"
          >
          <img
              style={{ width: 30, height: 30, marginRight: 5 }}
              src={require("./../../../../Assets/images/ic_play_coins.png")}
            />
            <Typography
              sx={{
                color: theme.palette.secondary.dark,

                marginX: "2px",
                mt: "2px",
              }}
              variant="h4"
            >
              {coins}
            </Typography>
          </Avatar>
          <Typography variant="h2" align="center" mt={2} fontWeight="bold">
            {t("purchaseModal.purchaseLabel")}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4} >
            <Button className={classes.buyButton} variant="contained" color="primary" mb={1} onClick={() => navigate('/products')}>
              {t("purchaseModal.purchaseInAppButtonLabel")}
            </Button>
            <Button className={classes.cancelButton} onClick={()=> setOpen(false)} variant="outlined" color="primary">
              {t("purchaseModal.back")}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default NotificationSection;
