export const SET_FETCHING = "@users/SET_FETCHING";
export const SET_ITEM = "@users/SET_ITEM";
export const SET_LIST = "@users/SET_LIST";
export const SET_PAGE = "@users/SET_PAGE";
export const SET_PER_PAGE = "@users/SET_PER_PAGE";
export const UPDATE_ITEM_FOLLOW = "@users/UPDATE_ITEM_FOLLOW";
export const SET_ITEM_SAVING = "@users/SET_ITEM_SAVING";
export const SET_CELEBRITIES_LIST = "@users/SET_CELEBRITIES_LIST";
export const SET_BLOCK_USER = "@users/SET_BLOCK_USER";
export const SET_BLOCK_USER_LIST = "@users/SET_BLOCK_USER_LIST";
export const REQUEST_BLOCK_USER_LIST = "@users/REQUEST_BLOCK_USER_LIST";

export const LOGOUT = "LOGOUT";
export const BLOCK_USER = "BLOCK_USER";
