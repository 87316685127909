import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../../ui-component/Logo";
import { URL } from "Constants/routes";
const LogoSection = () => {
  const navigate = useNavigate();


  return (
    <ButtonBase
      disableRipple

      onClick={() => navigate(`/${URL.APP.HOME}`)}
    >
      <Logo />
    </ButtonBase>
  );
};
export default LogoSection;
