import React from "react";
import PropTypes from "prop-types";
import { Auth, Hub } from "aws-amplify";
import { setUser, initAuthData } from "redux/auth/operations";
import { store } from "store";
import { logout } from "redux/auth/actions";

export const _checkInitialUser = () => {
  Auth.currentAuthenticatedUser({ bypassCache: true })
    .then(async (user) => {
      await setUser(user);
    })
    .catch(() => store.dispatch(logout()));
};

// class AuthChecker extends React.PureComponent {
//   constructor(props) {
//     super(props)
//     this.listener = null
//   }
//   componentDidMount() {
//     Hub.listen('auth', this._onAuthStateListener)
//     this._checkInitialUser()
//   }

//   componentWillUnmount() {
//     Hub.remove('auth', this._onAuthStateListener)
//   }

//   _checkInitialUser = () => {
//     Auth.currentAuthenticatedUser({ bypassCache: true })
//       .then(async user => {
//         await this.props.setUser(user)
//         await this.props.initAuthData()
//       })
//       .catch(() => this.props.logout())
//   }

//   _onAuthStateListener = async ({ payload: { event, data } }) => {
//     const { authData } = this.props

//     switch (event) {
//       case 'signIn':
//         this.props.onStateChange('signedIn', authData)
//         await this.props.setUser(data)
//         await this.props.initAuthData()
//         break
//       case 'signOut':
//         break
//     }
//   }

//   render() {
//     return null
//   }
// }

/*
AUTH STATES:
 - signIn
 - signUp
 - confirmSignIn
 - confirmSignUp
 - forgotPassword
 - requireNewPassword
 - verifyContact
 - signedIn
*/

// AuthChecker.propTypes = {
//   user: PropTypes.object,
//   setUser: PropTypes.func,
//   initAuthData: PropTypes.func,
// logout: PropTypes.func,
// authState: PropTypes.string,
// onStateChange: PropTypes.func,
// authData: PropTypes.object,
//}

// export default AuthChecker
