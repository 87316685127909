import * as feedsActions from './actions'

export const refreshFeeds = () => (dispatch, getState) => {
  const { timelineRef, profileRef, userRef, purchaseRef, likeRef, activityRef, commentsRef } = getState().feeds

  if (timelineRef && timelineRef.props) {
    timelineRef.props.onRefresh()
  }

  if (profileRef && profileRef.props) {
    profileRef.props.onRefresh()
  }

  if (userRef && userRef.props) {
    userRef.props.onRefresh()
  }

  if (purchaseRef && purchaseRef.props) {
    purchaseRef.props.onRefresh()
  }

  if (likeRef && likeRef.props) {
    likeRef.props.onRefresh()
  }

  if (activityRef && activityRef.props) {
    activityRef.props.onRefresh()
  }

  if (commentsRef && commentsRef.props) {
    commentsRef.props.onRefresh()
  }
}

export const refreshTimeline = () => (dispatch, getState) => {
  const { timelineRef } = getState().feeds
  if (timelineRef && timelineRef.props) {
    timelineRef.props.onRefresh()
  }
}

export const deleteComment = (videoId, reactionId) => async (dispatch, getState, { api, setError }) => {
  if (!videoId || !reactionId) {
    return false
  }

  try {
    dispatch(feedsActions.setFetching(true))
    await api.deleteComment(videoId, reactionId)
    dispatch(refreshFeeds())
    return true
  } catch (e) {
    dispatch(setError(e))
    return false
  } finally {
    dispatch(feedsActions.setFetching(false))
  }
}

export const refreshLikeFeeds = () => (dispatch, getState) => {
  const { likeRef } = getState().feeds

  if (likeRef && likeRef.props) {
    likeRef.props.onRefresh()
  }
}

export const refreshTimelineFeeds = () => (dispatch, getState) => {
  const { timelineRef } = getState().feeds
  if (timelineRef && timelineRef.props) {
    timelineRef.props.onRefresh()
  }
}
