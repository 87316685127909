import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Avatar, Box, Typography, Dialog, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { STREAM_CONSTANTS } from "config/getstream";

const useStyles = makeStyles({
  myDialog: {
    position: 'inherit !important'
  },
  buyButton: {
    textAlign: 'center !important',
    textTransform: 'uppercase !important',
    padding: '1em 3em !important',
    borderRadius: '50px !important',
    fontWeight: 'bold !important',
    backgroundColor: '#f01d22',
    color: 'white',
  },
  cancelButton: {
    textAlign: 'center !important',
    textTransform: 'uppercase !important',
    padding: '1em 3em !important',
    borderRadius: '50px !important',
    fontWeight: 'bold !important',
    backgroundColor: 'transparent',
    color: 'black !important',
    border: '0 !important',
  },
});

const NotificationsModal = (props) => {
  const stream = require("getstream");
  const streamToken = useSelector((state) => state.auth.streamToken);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [notifications, setNotifications] = useState([])
  const [notificationsId, setNotificationsId] = useState(null)
  let notif
  let notifSubscription

  useEffect(() => {
    defineStream();
    getNotifications()
    notifSubscription = notif.subscribe((data) => {
      getNotifications()
    });
  }, []);

  const getNotifications = () => {
    notif.get().then((response) => {
      setNotificationsCount(response.unread)
      setNotificationsId(response.results[0].id)
      setNotifications(response.results[0].activities.map((item) => {
        return {
          id: item.reaction.id,
          text: item.reaction.data.text || item.reaction.data.data?.text,
          time: formatNotificationTime(item.time),
          user: item.reaction?.user,
          videoId: item.object.id,
          videoUserId: item.object.actor.id
        }
      }))
    });
  }

  const defineStream = () => {
    const pepe = stream.connect(STREAM_CONSTANTS.API_KEY, streamToken, STREAM_CONSTANTS.APP_ID);

    notif = pepe.feed("notification", user.username, streamToken);
  }

  const getNotificationsFlow = () => {
    defineStream()
    if (notificationsId) {
      notif.get({ mark_read: [notificationsId], mark_seen: [notificationsId] })
    }
    getNotifications()
  }

  const formatNotificationTime = (timestamp) => {
    const notificationDate = new Date(timestamp);
    const currentDate = new Date();

    const isToday = notificationDate.toDateString() === currentDate.toDateString();
    const isYesterday =
      notificationDate.toDateString() === new Date(currentDate - 86400000).toDateString(); // 86400000 milliseconds = 1 day

    if (isToday) {
      const formattedTime = notificationDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      return `Today at ${formattedTime}`;
    } else if (isYesterday) {
      const formattedTime = notificationDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      return `Yesterday at ${formattedTime}`;
    } else {
      const formattedDate = notificationDate.toLocaleDateString();
      return formattedDate;
    }
  };

  const handleClick = () => {
    setOpen(true)
    getNotificationsFlow()
  }
  const handleNotificationClick = (videoUserId, videoId) => {
    setOpen(false);
    navigate(`/public-profile/${videoUserId}/${videoId}`);
  };

  const formatCommentText = (text) => {
    if (!text) {
      return;
    }
    const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const replacedText = text.replace(mentionRegex, (match, username, userId) => {
      return (
        `<a href="/public-profile/${userId}" style="text-decoration: none; color: #F01D22; cursor: pointer;">${username}</a>`
      );
    });
    return <span dangerouslySetInnerHTML={{ __html: replacedText }} />;
  };

  return (
    <>
      <div
        onClick={handleClick}
        className="username-link"
      >
        <Box
          sx={{
            ml: 0,
            mr: 3,
            [theme.breakpoints.down("md")]: {
              mr: 2,
            },
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              alignItems: "center",
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumsAvatar,
              transition: "all .2s ease-in-out",
              background: "transparent",
              color: theme.palette.secondary.dark,
            }}
            aria-haspopup="true"
            color="inherit"
          >
            {notificationsCount ? <img
              style={{ width: 20, height: 20, marginRight: 5 }}
              src={require("./../../../../Assets/images/notificacionMessage.png")}
            /> : <img
              style={{ width: 20, height: 20, marginRight: 5 }}
              src={require("./../../../../Assets/images/notificacion.png")}
            />}
          </Avatar>
        </Box>
      </div>

      <Dialog open={open} onClose={() => {
        setOpen(false);
        getNotificationsFlow()
      }} classes={{ paper: classes.myDialog }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: '60%',
            maxHeight: '80vh',
            overflowY: 'auto',
            [theme.breakpoints.down("md")]: {
              width: '100%',
            },
          }}
        >
          <Typography variant="h3" mb={3} align="center" color="black">
            Notifications de menciones / respuestas
          </Typography>

          {notifications.map((notification) => (
            <div style={{ cursor: "pointer", width: "100%" }}>
              <Box
                key={notification.id}
                mb={2}
                p={2}
                borderRadius={4}
                width="100%"
                bgcolor={!notification.read ? "#f0f0f0" : "#e6f7ff"}
                onClick={() => {
                  handleNotificationClick(notification.videoUserId, notification.videoId)
                }}
              >
                <Typography variant="body1" mb={1} align="left" color={!notification.read ? "textPrimary" : "primary"}>
                  {notification.user?.data?.nickname} ha comentado: {formatCommentText(notification?.text)}
                </Typography>
                <Typography variant="caption" align="center" color="textSecondary">
                  {`${notification.time}`}
                </Typography>
              </Box>
            </div>
          ))}
        </Box>
      </Dialog>
    </>
  );
};

export default NotificationsModal;