import { ERROR_MESSAGES } from '../../../config/constants'

export const formatRegisterErrorMessage = message => {
  let key = 'register' // generic error
  if (message.includes(ERROR_MESSAGES.ALIAS_IN_USE)) {
    key = 'nameexists'
  } else if (message.includes(ERROR_MESSAGES.EMAIL_IN_USE)) {
    key = 'emailexists'
  } else if (message.includes(ERROR_MESSAGES.REFERRAL_CODE)) {
    key = 'invalidcode'
  } else if (message.includes(ERROR_MESSAGES.NO_MORE_INVITATIONS)) {
    key = 'invalidcode'
  }
  return key
}
