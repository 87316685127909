import { ENV } from './api'

const AWS_CONFIG_PRO = {
  identityPoolId: 'eu-west-1:c77633d9-c1d0-4bc2-8924-ee6d59a8123b',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_cY1kBJA1G',
  userPoolWebClientId: '6ekv0ebpgeclnrinc9v4s294vs',
  Auth: {
    domain: 'betheypro.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'bethey://oauth',
    redirectSignOut: 'bethey://oauth',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  Storage: {
    bucket: 'bethey-vod-pro-source-sl5f99pekrpu',
    region: 'eu-west-1',
  },
}

const AWS_CONFIG_STG = {
  identityPoolId: 'eu-west-1:297c5622-bf36-4a8f-a215-2a35a7e0dd4f',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_p1gl6eYOz',
  userPoolWebClientId: '4b50pu89e032pqfsd96es7qqf1',
  Auth: {
    domain: 'betheystg.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'bethey://oauth',
    redirectSignOut: 'bethey://oauth',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  Storage: {
    bucket: 'bethey-vod-stg-source-18bqtbd0l1dzf',
    region: 'eu-west-1',
  },
}

const AWS_CONFIG_DEV = {
  identityPoolId: 'eu-west-1:fd12bfd5-1aac-48ec-be97-5f8257cf750f',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_ei40m5mI8',
  userPoolWebClientId: '4156mfps6d4r94605oqubsl44k',
  Auth: {
    domain: 'betheydevel.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'bethey://oauth',
    redirectSignOut: 'bethey://oauth',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  Storage: {
    bucket: 'bethey-devel-vod-source-nr86t7pbwt4z',
    region: 'us-east-1',
  },
}

const AWS_CONFIG_PRE = {
  identityPoolId: 'eu-west-1:b7fc334c-cfa3-46ad-933d-ca8e1325a452',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_NAvbVjdkY',
  userPoolWebClientId: '50s4mqch7uk4j7e61b4b11dkvm',
  Auth: {
    domain: 'betheydev.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'bethey://oauth',
    redirectSignOut: 'bethey://oauth',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  Storage: {
    bucket: 'bethey-vod-source-znbi41kmwr8s',
    region: 'eu-west-1',
  },
}

const AWS_CONFIG_PRE_AUDIT = {
  identityPoolId: 'eu-west-1:ef508063-ee64-42fc-ad15-8da2bc8aa799',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_ZA5pwGmMI',
  userPoolWebClientId: '45hb503chmc5vqco956n88qlh0',
  Auth: {
    domain: 'betheypre.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'bethey://oauth',
    redirectSignOut: 'bethey://oauth',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  Storage: {
    bucket: 'bethey-vod-source-i3z3i65bllpg',
    region: 'eu-west-1',
  },
}

export const PUBLISHER_GROUP = 'publisher'

export const BANNED_GROUP = 'banned'

export const AWS_CONFIG =
  ENV === 'PRO'
    ? AWS_CONFIG_PRO
    : ENV === 'STG'
    ? AWS_CONFIG_STG
    : ENV === 'DEV'
    ? AWS_CONFIG_DEV
    : ENV === 'PRE'
    ? AWS_CONFIG_PRE
    : ENV === 'AUD'
    ? AWS_CONFIG_PRE_AUDIT
    : null
